import React, { FC, ReactElement } from 'react';
import { KeyboardDatePicker, KeyboardDatePickerProps as MuiKeyboardDatePickerProps } from '@material-ui/pickers';
import { CalendarToday } from '@material-ui/icons';
import TextField, { TextFieldProps as TextFieldPropsType } from '../TextField/TextField';
import { ValidationType } from '../../types';

export interface DateInputProps extends Omit<MuiKeyboardDatePickerProps, 'label'> {
  TextFieldProps?: TextFieldPropsType;
  label?: string;
  hintText?: string;
  helperText?: string;
  validationType?: ValidationType;
  dataTestId?: string;
}

const DateInput: FC<DateInputProps> = ({
  TextFieldProps,
  label,
  hintText,
  helperText,
  required,
  validationType,
  dataTestId,
  ...rest
}) => (
  <KeyboardDatePicker
    disableToolbar
    autoOk
    keyboardIcon={<CalendarToday />}
    KeyboardButtonProps={{
      'aria-label': 'date-picker',
    }}
    format="dd/MM/yyyy"
    variant="inline"
    TextFieldComponent={({ InputProps, inputProps, value, onChange, inputRef, className, disabled }): ReactElement => (
      <TextField
        dataTestId={dataTestId}
        InputProps={InputProps}
        inputProps={inputProps}
        value={value}
        inputRef={inputRef}
        helperText={helperText}
        required={required}
        className={className}
        label={label}
        hintText={hintText}
        validationType={validationType}
        onChange={onChange}
        disabled={disabled}
        {...TextFieldProps}
      />
    )}
    {...rest}
  />
);

export default DateInput;
