import React from 'react';
import PropTypes from 'prop-types';

import { InferPropTypes, NonNullFields } from '../types';
import NavigationHorizontal from './Horizontal/NavigationHorizontal';
import NavigationVertical from './Vertical/NavigationVertical';

const navigationPropTypes = {
  vertical: PropTypes.bool,
  drawer: PropTypes.bool,
  drawerText: PropTypes.string,
  onClose: PropTypes.func,
  value: PropTypes.shape({
    currentTab: PropTypes.number.isRequired,
    currentDropdownItem: PropTypes.number,
  }).isRequired,
  onChange: PropTypes.func,
  children: PropTypes.node,
  dataTestId: PropTypes.string,
};

const defaultProps = {
  value: {
    currentTab: 0,
  },
};

export type NavigationProps = NonNullFields<InferPropTypes<typeof navigationPropTypes, typeof defaultProps>>;

const Navigation: React.FC<NavigationProps> = ({ vertical, drawer, drawerText, onClose, ...rest }) =>
  vertical ? (
    <NavigationVertical drawer={drawer} drawerText={drawerText} onClose={onClose} {...rest} />
  ) : (
    <NavigationHorizontal {...rest} />
  );

Navigation.propTypes = navigationPropTypes;
Navigation.defaultProps = defaultProps;

export default Navigation;
