import React from 'react';
import { CardHeader as MuiCardHeader, CardHeaderProps } from '@material-ui/core';

const CardHeader: React.FC<CardHeaderProps> = (props) => (
  <MuiCardHeader
    titleTypographyProps={{ variant: 'subtitle1', gutterBottom: true }}
    subheaderTypographyProps={{ variant: 'body2', gutterBottom: true, color: 'inherit' }}
    {...props}
  />
);

export default CardHeader;
