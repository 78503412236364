import React from 'react';
import clsx from 'clsx';
import MuiButton, { ButtonProps as MuiButtonProps } from '@material-ui/core/Button';
import { oneOf } from 'prop-types';

import { Color, InferPropTypes, NonNullFields } from '../types';

export type ButtonColors = Color | 'destructive';

const buttonPropTypes = {
  color: oneOf(['primary', 'secondary', 'default', 'destructive']),
  variant: oneOf(['contained', 'outlined', 'text']),
};

const buttonDefaultPropTypes = {
  color: 'primary',
  variant: 'contained',
};

export type ButtonProps = NonNullFields<InferPropTypes<typeof buttonPropTypes, typeof buttonDefaultPropTypes>> &
  Omit<MuiButtonProps, 'color' | 'variant'>;

const Button: React.FC<ButtonProps> = ({
  onClick,
  variant,
  color,
  disabled,
  size,
  type,
  startIcon,
  children,
  className,
  ...rest
}) => {
  const colorProp =
    ['default', 'inherit', 'primary', 'secondary'].indexOf(color as ButtonColors) > -1
      ? (color as 'default' | 'inherit' | 'primary' | 'secondary')
      : 'default';

  return (
    <MuiButton
      {...rest}
      onClick={onClick}
      variant={variant as 'contained' | 'outlined' | 'text'}
      color={colorProp}
      disabled={disabled}
      size={size}
      type={type}
      className={clsx(className, color === 'destructive' && 'destructive')}
      startIcon={startIcon}
    >
      {children}
    </MuiButton>
  );
};

Button.propTypes = buttonPropTypes;
Button.defaultProps = buttonDefaultPropTypes;

export default Button;
