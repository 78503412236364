import React, { useMemo } from 'react';
import { MenuItem, MenuItemProps as MuiMenuItemProps } from '@material-ui/core';
import PropTypes from 'prop-types';

import { InferPropTypes, NonNullFields } from '../../types';

const navigationDropdownItemPropTypes = {
  label: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  tabIndex: PropTypes.number.isRequired,
  currentNavItem: PropTypes.shape({
    currentTab: PropTypes.number.isRequired,
    currentDropdownItem: PropTypes.number,
  }).isRequired,
  handleChange: PropTypes.func,
  handleMouseLeave: PropTypes.func,
  dataTestId: PropTypes.string,
};

const defaultProps = {
  label: '',
  index: 0,
  tabIndex: 0,
  currentNavItem: {
    currentTab: 0,
  },
};

export type NavigationSubItemHorizontalProps = NonNullFields<InferPropTypes<typeof navigationDropdownItemPropTypes>> &
  MuiMenuItemProps;

const NavigationSubItemHorizontal: React.FC<NavigationSubItemHorizontalProps> = ({
  label,
  index,
  tabIndex,
  currentNavItem,
  handleChange,
  handleMouseLeave,
  dataTestId,
}) => {
  const isSelected: boolean = useMemo(
    () => currentNavItem.currentTab === tabIndex && currentNavItem.currentDropdownItem === index,
    [currentNavItem, tabIndex, index],
  );
  const handleClick = (): void => {
    if (handleChange && handleMouseLeave) {
      handleChange({ currentDropdownItem: index, currentTab: tabIndex });
      handleMouseLeave();
    }
  };

  return (
    <MenuItem
      data-testid={`${dataTestId}-dropdown-item-${index}`}
      className="navigation-dropdown-item"
      selected={isSelected}
      onClick={handleClick}
    >
      {label}
    </MenuItem>
  );
};

NavigationSubItemHorizontal.propTypes = navigationDropdownItemPropTypes;
NavigationSubItemHorizontal.defaultProps = defaultProps;

export default React.memo(NavigationSubItemHorizontal);
