import { AccordionClassKey, AccordionSummaryClassKey } from '@material-ui/core';
import { Theme } from '../Theme/theme';
import { CSSProperties } from '@material-ui/core/styles/withStyles';

export const accordionTheme = (theme: Theme): Partial<Record<AccordionClassKey, CSSProperties>> => ({
  root: {
    boxSizing: 'border-box',
    marginTop: 0,
    '&$expanded': {
      margin: 0,
    },
    '&.square': {
      borderRadius: '0px !important',
      boxShadow: 'none',
      border: 'none',
    },
    boxShadow: `${theme.palette.elevation}0A 0px 4px 6px 0px`,
    '&:before': {
      backgroundColor: 'transparent',
    },
    border: `1px solid ${theme.palette.divider}`,
    '&:last-child': {
      borderRadius: '0px 0px 6px 6px',
    },
    '&:nth-child(2)': {
      borderRadius: '6px 6px 0px 0px',
    },
    '&:not(:last-child)': {
      borderBottom: 'none',
    },
    '&.footerAccordion': {
      boxShadow: 'none',
      borderLeft: 'none',
      borderRight: 'none',
      '&:nth-last-child(2)': {
        borderBottom: `1px solid ${theme.palette.divider}`,
      },
      '&:last-child': {
        borderRadius: 0,
      },
      '&:nth-child(2)': {
        borderRadius: 0,
      },
    },
  },
});

export const accordionSummaryTheme = (theme: Theme): Partial<Record<AccordionSummaryClassKey, CSSProperties>> => ({
  root: {
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
    },
    '&:active': {
      backgroundColor: theme.palette.action.active,
    },
    minHeight: 56,
  },
  expandIcon: {
    '& .MuiSvgIcon-root': {
      fill: theme.palette.text.primary,
    },
    '&.MuiIconButton-root': {
      color: theme.palette.text.primary,
    },
  },
  content: {
    display: 'block',
    margin: '18px 0',
    '&$expanded': {
      margin: '18px 0',
    },
    '& .MuiTypography-subtitle2': {
      color: theme.palette.text.secondary,
    },
    '& > .summary-action': {
      marginLeft: 'auto',
    },
  },
});
