import { FormControlLabelClassKey } from '@material-ui/core/FormControlLabel';
import { CSSProperties } from '@material-ui/core/styles/withStyles';
import { Theme } from './theme';

export const formControlLabelTheme = (theme: Theme): Partial<Record<FormControlLabelClassKey, CSSProperties>> => ({
  root: {
    '&.validation-text': {
      marginBottom: 17,
    },
    '&.radio-hint-text': {
      marginBottom: 17,
    },
    '&.validation-text.radio-hint-text': {
      marginBottom: 36,
    },
    '&.conditional-text-field': {
      marginBottom: 51,
    },
    '&.conditional-text-field.radio-hint-text': {
      marginBottom: 68,
    },
  },
  label: {
    marginBottom: 0,
    fontFamily: 'Source Sans Pro',
    lineHeight: '20px',
    height: 20,
    '& > .bold': {
      fontWeight: 600,
    },
    '& > .form-control-hint-text': {
      position: 'relative',
      top: 0,
      left: 0,
      fontFamily: 'Source Sans Pro',
      fontWeight: 400,
      color: theme.palette.text.secondary,
    },
  },
  labelPlacementStart: {
    marginLeft: 0,
    '& > .MuiFormControlLabel-label': {
      marginRight: 16,
    },
  },
});

export default {
  formControlLabelTheme,
};
