import React from 'react';
import { Dialog as MuiDialog, DialogProps as MuiDialogProps } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

export interface ModalProps extends MuiDialogProps {
  dataTestId?: string;
  open: boolean;
  onClose?: () => void;
  size?: 'small' | 'medium' | 'large';
}

const useStyles = makeStyles({
  modal: {
    '& > .MuiDialog-container': {
      '& > .MuiDialog-paper': {
        margin: 16,
        maxHeight: 'calc(100% - 32px)',
      },
    },
  },
  small: {
    '& > .MuiDialog-container': {
      '& > .MuiDialog-paper': {
        maxWidth: 444,
      },
    },
  },
  medium: {
    '& > .MuiDialog-container': {
      '& > .MuiDialog-paper': {
        maxWidth: 600,
      },
    },
  },
  large: {
    '& > .MuiDialog-container': {
      '& > .MuiDialog-paper': {
        maxWidth: 960,
      },
    },
  },
});

const Modal: React.FC<ModalProps> = ({ children, open, size = 'small', onClose, dataTestId }) => {
  const classes = useStyles();
  return (
    <MuiDialog
      data-testid={dataTestId}
      onClose={onClose}
      open={open}
      className={clsx(classes[size], classes.modal)}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      {children}
    </MuiDialog>
  );
};

export default Modal;
