import React from 'react';
import { TableHead as MuiTableHead, TableHeadProps as MuiTableHeadProps } from '@material-ui/core';
import PropTypes from 'prop-types';

import { InferPropTypes, NonNullFields } from '../types';

const tableHeadPropTypes = {
  dataTestId: PropTypes.string,
};

export type TableHeadProps = NonNullFields<InferPropTypes<typeof tableHeadPropTypes>> & MuiTableHeadProps;

const TableHead: React.FC<TableHeadProps> = ({ children, dataTestId, ...rest }) => (
  <MuiTableHead id="table-head" data-testid={dataTestId} {...rest}>
    {children}
  </MuiTableHead>
);

TableHead.propTypes = tableHeadPropTypes;

export default TableHead;
