import React, { useState } from 'react';
import { makeStyles, useTheme, createStyles } from '@material-ui/core/styles';
import { SearchRounded, CloseRounded, AccessTimeRounded } from '@material-ui/icons';
import {
  FormControl,
  ClickAwayListener,
  Input,
  InputAdornment,
  Popper,
  Typography,
  List,
  ListItem,
  ListItemText,
  useMediaQuery,
} from '@material-ui/core';
import clsx from 'clsx';

import { IconButton, Button, Avatar } from '../../';
import { Theme } from '../Theme/theme';
import { CONTENT_TYPES } from '../../abstracts/constants';
import { ContentIcon } from '../../utils/ContentIcon';

const HEADER_TITLES = {
  RECENT_SEARCHES: 'Recent searches',
  SUGGESTED_SEARCHES: 'Suggested searches',
  PEOPLE: 'People',
  DOCUMENTS: 'Documents',
};

export interface SearchFieldProps {
  value?: string;
  onChange: (value?: string) => void;
  onSubmit: (value?: string) => void;
  onClear: () => void;
  recentSearchResults?: {
    text: string;
    type?: string;
    avatar?: string;
  }[];
  suggestedSearchResults?: {
    text: string;
    type?: string;
    avatar?: string;
  }[];
  className?: string;
  dataTestId?: string;
  placeholder?: string;
}

interface SearchDropdownProps {
  variant: 'recent' | 'suggested';
  open: boolean;
  isMobile: boolean;
  searchResults?: {
    text: string;
    type?: string;
    avatar?: string;
  }[];
  onClear?: () => void;
  onChange: (value: string) => void;
  anchorEl?: HTMLElement;
  dataTestId?: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      width: 376,
    },
    containerMobile: {
      width: 292,
    },
    input: {
      '& > input': {
        '&::placeholder': {
          opacity: 1,
          color: theme.palette.text.secondary,
        },
      },
    },
    closeIcon: {
      marginRight: '0px !important',
    },
    endAdornment: {
      cursor: 'pointer',
    },
    searchIcon: {
      width: 56,
      height: 42,
    },
    popper: {
      width: 376,
      marginTop: 6,
      left: '-1px !important',
      padding: '7px 0px 8px',
      backgroundColor: theme.palette.background.paper,
      borderRadius: 4,
      border: `1px solid ${theme.palette.neutral.mid}`,
      boxShadow: `0 4px 6px 0 ${theme.palette.elevation}0A`,
    },
    popperMobile: {
      left: 5,
      width: '100%',
      height: 'calc(100% - 68px)',
      marginTop: 6,
      padding: '7px 0px 8px',
      backgroundColor: theme.palette.background.paper,
    },
    popperHeader: {
      display: 'flex',
      justifyContent: 'space-between',
      marginLeft: 15,
      height: 44,
    },
    popperTitle: {
      fontWeight: 600,
      margin: 'auto 0',
    },
    clearButton: {
      '&:hover, &:focus-visible': {
        backgroundColor: 'transparent',
        textDecoration: 'underline',
      },
      '&:active': {
        backgroundColor: 'transparent',
        textDecoration: 'underline',
      },
    },
    list: {
      padding: 0,
    },
    listItem: {
      paddingLeft: 15,
    },
    listItemIcon: {
      marginRight: 6,
    },
    listItemIconDefault: {
      fill: theme.palette.neutral.mid,
    },
  }),
);

const SearchDropdown: React.FC<SearchDropdownProps> = ({
  anchorEl,
  variant,
  searchResults,
  open,
  isMobile,
  onClear,
  onChange,
  dataTestId,
}) => {
  const classes = useStyles();
  switch (variant) {
    case 'recent':
      return (
        <Popper
          className={isMobile ? classes.popperMobile : classes.popper}
          open={!!searchResults?.length && open}
          anchorEl={anchorEl}
          placement="bottom-start"
          modifiers={{
            flip: {
              enabled: false,
            },
          }}
          data-testid={`${dataTestId}-popper-recent`}
        >
          <div className={classes.popperHeader}>
            <Typography className={classes.popperTitle}>{HEADER_TITLES.RECENT_SEARCHES}</Typography>
            <Button
              className={classes.clearButton}
              onMouseDown={(): void => onClear?.()}
              color="primary"
              variant="text"
              data-testid={`${dataTestId}-clear-button`}
            >
              Clear
            </Button>
          </div>
          <List className={classes.list}>
            {searchResults?.map((item, idx) => (
              <ListItem button key={idx} onMouseUp={(): void => onChange(item.text)} className={classes.listItem}>
                {item.avatar && <Avatar size="xs" src={item.avatar} className={classes.listItemIcon} />}
                {item.type && item.type !== 'user' && <ContentIcon type={item.type} className={classes.listItemIcon} />}
                {!item.type && (
                  <AccessTimeRounded className={clsx(classes.listItemIcon, classes.listItemIconDefault)} />
                )}
                <ListItemText>{item.text}</ListItemText>
              </ListItem>
            ))}
          </List>
        </Popper>
      );
    case 'suggested': {
      const userResults = searchResults?.filter((item) => item.type === 'user');
      const contentResults = searchResults?.filter((item) => Object.values(CONTENT_TYPES).includes(item.type || ''));
      const otherResults = searchResults?.filter((item) => !item.type);
      return (
        <Popper
          className={isMobile ? classes.popperMobile : classes.popper}
          open={!!searchResults?.length && open}
          anchorEl={anchorEl}
          placement="bottom-start"
          modifiers={{
            flip: {
              enabled: false,
            },
          }}
          data-testid={`${dataTestId}-popper-suggested`}
        >
          {!!userResults?.length && (
            <>
              <div className={classes.popperHeader}>
                <Typography className={classes.popperTitle}>{HEADER_TITLES.PEOPLE}</Typography>
              </div>
              <List className={classes.list}>
                {userResults?.map((item, idx) => (
                  <ListItem button key={idx} onMouseDown={(): void => onChange(item.text)} className={classes.listItem}>
                    <Avatar size="xs" src={item.avatar} className={classes.listItemIcon} />
                    <ListItemText>{item.text}</ListItemText>
                  </ListItem>
                ))}
              </List>
            </>
          )}
          {!!contentResults?.length && (
            <>
              <div className={classes.popperHeader}>
                <Typography className={classes.popperTitle}>{HEADER_TITLES.DOCUMENTS}</Typography>
              </div>
              <List className={classes.list}>
                {contentResults?.map((item, idx) => (
                  <ListItem button key={idx} onClick={(): void => onChange(item.text)} className={classes.listItem}>
                    <ContentIcon type={item.type} className={classes.listItemIcon} />
                    <ListItemText>{item.text}</ListItemText>
                  </ListItem>
                ))}
              </List>
            </>
          )}
          {!!otherResults?.length && (
            <>
              <div className={classes.popperHeader}>
                <Typography className={classes.popperTitle}>{HEADER_TITLES.SUGGESTED_SEARCHES}</Typography>
              </div>
              <List className={classes.list}>
                {otherResults?.map((item, idx) => (
                  <ListItem button key={idx} onMouseDown={(): void => onChange(item.text)} className={classes.listItem}>
                    <SearchRounded className={clsx(classes.listItemIcon, classes.listItemIconDefault)} />
                    <ListItemText>{item.text}</ListItemText>
                  </ListItem>
                ))}
              </List>
            </>
          )}
        </Popper>
      );
    }
  }
};

const SearchField: React.FC<SearchFieldProps> = ({
  value,
  recentSearchResults,
  suggestedSearchResults,
  onChange,
  onClear,
  onSubmit,
  className,
  dataTestId,
  placeholder,
  ...rest
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));

  const [anchorEl, setAnchorEl] = useState<HTMLElement | undefined>(undefined);
  const open = Boolean(anchorEl);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    if (event.target.value) {
      setAnchorEl(event.currentTarget);
    } else {
      setAnchorEl(undefined);
    }
    onChange(event.target.value);
  };

  const handleSubmit = (): void => {
    onSubmit(value);
    setAnchorEl(undefined);
    document?.getElementById('search-field-input')?.blur();
  };

  return (
    <ClickAwayListener onClickAway={(): void => setAnchorEl(undefined)}>
      <FormControl className={isMobile ? classes.containerMobile : classes.container} data-testid={dataTestId}>
        <Input
          autoComplete="off"
          className={clsx(classes.input, className)}
          placeholder={placeholder}
          value={value}
          onChange={handleChange}
          onKeyDown={(e): void => {
            if (e.key === 'Enter') {
              handleSubmit();
            }
          }}
          onFocus={(event: React.FocusEvent<HTMLInputElement>): void => setAnchorEl(event.currentTarget)}
          inputProps={{
            'data-testid': `${dataTestId}-input`,
            id: 'search-field-input',
          }}
          endAdornment={
            <>
              {value && (
                <IconButton onClick={(): void => onChange('')} size="small" data-testid={`${dataTestId}-close-button`}>
                  <CloseRounded className={classes.closeIcon} />
                </IconButton>
              )}
              <InputAdornment position="end" className={classes.endAdornment}>
                <IconButton
                  onClick={(): void => {
                    handleSubmit();
                  }}
                  size="small"
                  className={classes.searchIcon}
                  data-testid={`${dataTestId}-submit-button`}
                >
                  <SearchRounded />
                </IconButton>
              </InputAdornment>
            </>
          }
          disableUnderline
          {...rest}
        />
        <SearchDropdown
          anchorEl={anchorEl}
          searchResults={recentSearchResults}
          open={!value && open}
          isMobile={isMobile}
          onClear={onClear}
          onChange={onChange}
          variant="recent"
          dataTestId={dataTestId}
        />
        <SearchDropdown
          anchorEl={anchorEl}
          searchResults={suggestedSearchResults}
          open={!!value && open}
          isMobile={isMobile}
          onChange={onChange}
          variant="suggested"
          dataTestId={dataTestId}
        />
      </FormControl>
    </ClickAwayListener>
  );
};

export default SearchField;
