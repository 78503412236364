import React from 'react';
import { TableFooter as MuiTableFooter, TableFooterProps as MuiTableFooterProps } from '@material-ui/core';
import PropTypes from 'prop-types';

import { InferPropTypes, NonNullFields } from '../types';

const tableFooterPropTypes = {
  dataTestId: PropTypes.string,
};

export type TableFooterProps = NonNullFields<InferPropTypes<typeof tableFooterPropTypes>> & MuiTableFooterProps;

const TableFooter: React.FC<TableFooterProps> = ({ children, dataTestId, ...rest }) => (
  <MuiTableFooter data-testid={dataTestId} {...rest}>
    {children}
  </MuiTableFooter>
);

TableFooter.propTypes = tableFooterPropTypes;

export default TableFooter;
