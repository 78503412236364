import { Theme, OverridesType } from './theme';
import {
  formHelperText,
  formLabelTheme,
  inputAdornmentTheme,
  inputBaseTheme,
  inputLabelTheme,
  inputTheme,
} from './inputTheme';
import { formControlTheme } from './formControlTheme';
import { formControlLabelTheme } from './formControlLabelTheme';
import { accordionTheme, accordionSummaryTheme } from '../Accordion/accordionTheme';
import { avatarTheme } from '../Avatar/avatarTheme';
import { breadcrumbsTheme } from '../Breadcrumbs/breadcrumbsTheme';
import { iconButtonTheme, buttonTheme } from '../Button/buttonTheme';
import { cardContentTheme, cardActionAreaTheme, cardTheme, cardActionsTheme, cardHeaderTheme } from '../Card/cardTheme';
import { chipTheme } from '../Chip/chipTheme';
import { drawerTheme } from '../Drawer/drawerTheme';
import { checkboxTheme } from '../Form/Checkbox/checkboxTheme';
import { pickersCalendarHeaderTheme, popoverTheme, pickersDayTheme } from '../Form/DateInput/dateInputTheme';
import {
  paperTheme,
  listTheme,
  selectTheme,
  menuTheme,
  dividerTheme,
  listItemTheme,
} from '../Form/Dropdown/dropdownTheme';
import { radioTheme } from '../Form/Radio/radioTheme';
import { switchTheme } from '../Form/Switch/switchTheme';
import { appBarTheme, toolbarTheme } from '../Header/headerTheme';
import { linkTheme } from '../Link/linkTheme';
import { modalContentTheme, modalActionsTheme } from '../Modal/modalTheme';
import { navigationTheme, navigationTabTheme } from '../Navigation/navigationTheme';
import { linearProgressTheme } from '../Progress/LinearProgress/linearProgressTheme';
import { sliderTheme } from '../Slider/sliderTheme';
import { snackbarTheme } from '../Snackbar/snackbarTheme';
import { stepTheme, stepLabelTheme, stepConnectorTheme } from '../Stepper/stepperTheme';
import {
  tableTheme,
  tableHeadTheme,
  tableRowTheme,
  tableCellTheme,
  tableFooterTheme,
  tablePaginationTheme,
  tableSortLabelTheme,
  tableContainerTheme,
} from '../Table/tableTheme';
import { tooltipTheme } from '../Tooltip/tooltipTheme';
import { typoOverrideTheme } from '../Typography/typographyTheme';

const themeOverrides = (theme: Theme): OverridesType => ({
  MuiAvatar: avatarTheme(theme),
  MuiButton: buttonTheme(theme),
  MuiIconButton: iconButtonTheme(theme),
  MuiInputBase: inputBaseTheme(theme),
  MuiInput: inputTheme(theme),
  MuiFormHelperText: formHelperText(theme),
  MuiInputLabel: inputLabelTheme(theme),
  MuiFormLabel: formLabelTheme(theme),
  MuiInputAdornment: inputAdornmentTheme(theme),
  MuiFormControl: formControlTheme(theme),
  MuiCheckbox: checkboxTheme(theme),
  MuiSwitch: switchTheme(theme),
  MuiFormControlLabel: formControlLabelTheme(theme),
  MuiGrid: {
    root: {
      padding: theme.spacing(2),
    },
  },
  MuiStep: stepTheme,
  MuiStepLabel: stepLabelTheme,
  MuiStepConnector: stepConnectorTheme(theme),
  MuiChip: chipTheme(theme),
  MuiAppBar: appBarTheme(theme),
  MuiToolbar: toolbarTheme(theme),
  MuiPickersCalendarHeader: pickersCalendarHeaderTheme(theme),
  MuiPopover: popoverTheme(theme),
  MuiPickersDay: pickersDayTheme(theme),
  MuiCardContent: cardContentTheme,
  MuiCardActionArea: cardActionAreaTheme(theme),
  MuiCard: cardTheme(theme),
  MuiCardActions: cardActionsTheme(theme),
  MuiCardHeader: cardHeaderTheme,
  MuiLinearProgress: linearProgressTheme(theme),
  MuiSelect: selectTheme(theme),
  MuiPaper: paperTheme,
  MuiList: listTheme,
  MuiTypography: typoOverrideTheme,
  MuiMenu: menuTheme(theme),
  MuiDivider: dividerTheme,
  MuiListItem: listItemTheme(theme),
  MuiRadio: radioTheme(theme),
  MuiAccordion: accordionTheme(theme),
  MuiAccordionSummary: accordionSummaryTheme(theme),
  MuiBreadcrumbs: breadcrumbsTheme(theme),
  MuiTabs: navigationTheme(theme),
  MuiTab: navigationTabTheme(theme),
  MuiLink: linkTheme(theme),
  MuiSlider: sliderTheme(theme),
  MuiTooltip: tooltipTheme(theme),
  MuiDialogContent: modalContentTheme,
  MuiDialogActions: modalActionsTheme,
  MuiSnackbar: snackbarTheme(theme),
  MuiDrawer: drawerTheme,
  MuiTableContainer: tableContainerTheme(theme),
  MuiTable: tableTheme(theme),
  MuiTableHead: tableHeadTheme(theme),
  MuiTableRow: tableRowTheme(theme),
  MuiTableCell: tableCellTheme,
  MuiTableFooter: tableFooterTheme(theme),
  MuiTablePagination: tablePaginationTheme(theme),
  MuiTableSortLabel: tableSortLabelTheme,
});

export default themeOverrides;
