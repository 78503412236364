import React, { ReactNode } from 'react';
import { IconButton, Divider, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Close } from '@material-ui/icons';

export interface DrawerHeaderProps {
  children?: ReactNode;
  text?: string;
  divider?: boolean;
  onClose?: () => void;
  className?: string;
  dataTestId?: string;
}

const useStyles = makeStyles({
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: 16,
    paddingRight: 16,
    padding: '24px 16px',
    justifyContent: 'space-between',
    '& > p': {
      fontWeight: 600,
      fontSize: 18,
    },
  },

  divider: {
    marginTop: 0,
    marginBottom: 0,
  },
});

const DrawerHeader: React.FC<DrawerHeaderProps> = ({ text, onClose, divider, dataTestId, className }) => {
  const classes = useStyles();
  return (
    <div className={className}>
      <div data-testid={dataTestId} className={classes.drawerHeader}>
        <Typography>{text}</Typography>
        <IconButton size="small" data-testid={`${dataTestId}-close-button`} onClick={(): void => onClose?.()}>
          <Close />
        </IconButton>
      </div>
      {divider && <Divider className={classes.divider} />}
    </div>
  );
};

export default DrawerHeader;
