import React, { FC } from 'react';

const OCRLogo: FC<{ disabled?: boolean }> = () => (
  <svg
    width="80px"
    height="32px"
    viewBox="0 0 80 32"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <title>Logo/OCR/Colour</title>
    <defs>
      <polygon id="path-1" points="0 31.358 79.37 31.358 79.37 0 0 0"></polygon>
    </defs>
    <g id="Logo/OCR/Colour" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Group-37">
        <path
          d="M2.627,27.3958 C2.627,28.3744667 3.15766667,29.2504667 4.08833333,29.2504667 C5.02633333,29.2504667 5.55633333,28.3878 5.55633333,27.3484667 C5.55633333,26.4378 5.08033333,25.4864667 4.095,25.4864667 C3.11633333,25.4864667 2.627,26.3898 2.627,27.3958 M6.181,27.3211333 C6.181,28.8971333 5.22366667,29.7331333 4.05433333,29.7331333 C2.84433333,29.7331333 1.99566667,28.7958 1.99566667,27.4091333 C1.99566667,25.9551333 2.899,25.0038 4.12233333,25.0038 C5.37233333,25.0038 6.181,25.9618 6.181,27.3211333"
          id="Fill-1"
          fill="#132A5E"
        ></path>
        <path
          d="M7.19473333,26.3697333 L7.6634,27.0764 C7.78606667,27.2597333 7.88806667,27.4297333 7.99606667,27.6130667 L8.01673333,27.6130667 C8.1254,27.4157333 8.23473333,27.2457333 8.3434,27.0697333 L8.8054,26.3697333 L9.45073333,26.3697333 L8.3294,27.9597333 L9.48473333,29.6584 L8.8054,29.6584 L8.32273333,28.9177333 C8.1934,28.7277333 8.08473333,28.5444 7.9694,28.3470667 L7.95606667,28.3470667 C7.8474,28.5444 7.7314,28.7204 7.6094,28.9177333 L7.1334,29.6584 L6.47406667,29.6584 L7.6434,27.9804 L6.52873333,26.3697333 L7.19473333,26.3697333 Z"
          id="Fill-3"
          fill="#132A5E"
        ></path>
        <path
          d="M10.1172667,29.6584667 L10.1172667,26.8251333 L9.65526667,26.8251333 L9.65526667,26.3698 L10.1172667,26.3698 L10.1172667,26.2131333 C10.1172667,25.7511333 10.2186,25.3298 10.4972667,25.0651333 C10.7219333,24.8478 11.0206,24.7591333 11.2992667,24.7591333 C11.5106,24.7591333 11.6932667,24.8064667 11.8092667,24.8538 L11.7279333,25.3164667 C11.6392667,25.2758 11.5172667,25.2418 11.3472667,25.2418 C10.8372667,25.2418 10.7086,25.6898 10.7086,26.1931333 L10.7086,26.3698 L11.5032667,26.3698 L11.5032667,26.8251333 L10.7086,26.8251333 L10.7086,29.6584667 L10.1172667,29.6584667 Z"
          id="Fill-5"
          fill="#132A5E"
        ></path>
        <path
          d="M12.4011333,28.0208 C12.4011333,28.7414667 12.8151333,29.2848 13.3998,29.2848 C13.9704667,29.2848 14.3984667,28.7481333 14.3984667,28.0068 C14.3984667,27.4501333 14.1198,26.7434667 13.4138,26.7434667 C12.7064667,26.7434667 12.4011333,27.3961333 12.4011333,28.0208 M15.0104667,27.9868 C15.0104667,29.2028 14.1671333,29.7334667 13.3724667,29.7334667 C12.4824667,29.7334667 11.7964667,29.0808 11.7964667,28.0414667 C11.7964667,26.9401333 12.5164667,26.2948 13.4271333,26.2948 C14.3711333,26.2948 15.0104667,26.9808 15.0104667,27.9868"
          id="Fill-7"
          fill="#132A5E"
        ></path>
        <path
          d="M15.7585333,27.3958 C15.7585333,27.0084667 15.7512,26.6751333 15.7312,26.3698 L16.2545333,26.3698 L16.2745333,27.0151333 L16.3018667,27.0151333 C16.4518667,26.5738 16.8118667,26.2951333 17.2132,26.2951333 C17.2805333,26.2951333 17.3278667,26.3018 17.3832,26.3151333 L17.3832,26.8791333 C17.3218667,26.8658 17.2605333,26.8584667 17.1785333,26.8584667 C16.7572,26.8584667 16.4585333,27.1784667 16.3772,27.6264667 C16.3632,27.7084667 16.3498667,27.8031333 16.3498667,27.9051333 L16.3498667,29.6584667 L15.7585333,29.6584667 L15.7585333,27.3958 Z"
          id="Fill-9"
          fill="#132A5E"
        ></path>
        <path
          d="M20.2096667,27.7083333 C20.2096667,27.6336667 20.203,27.5316667 20.183,27.457 C20.0943333,27.0763333 19.7683333,26.7636667 19.3196667,26.7636667 C18.701,26.7636667 18.3343333,27.307 18.3343333,28.0343333 C18.3343333,28.7003333 18.661,29.2503333 19.3063333,29.2503333 C19.707,29.2503333 20.0743333,28.9856667 20.183,28.5376667 C20.203,28.4556667 20.2096667,28.3743333 20.2096667,28.279 L20.2096667,27.7083333 Z M20.8076667,24.8336667 L20.8076667,28.809 C20.8076667,29.101 20.8143333,29.4343333 20.835,29.6583333 L20.2983333,29.6583333 L20.271,29.0876667 L20.257,29.0876667 C20.0743333,29.455 19.673,29.7336667 19.1363333,29.7336667 C18.341,29.7336667 17.7296667,29.0603333 17.7296667,28.0616667 C17.723,26.9676667 18.4023333,26.295 19.2043333,26.295 C19.707,26.295 20.047,26.5323333 20.1956667,26.7976667 L20.2096667,26.7976667 L20.2096667,24.8336667 L20.8076667,24.8336667 Z"
          id="Fill-11"
          fill="#132A5E"
        ></path>
        <path
          d="M26.4486,29.5090667 C26.2312667,29.6177333 25.7959333,29.7264 25.2392667,29.7264 C23.9479333,29.7264 22.9766,28.9110667 22.9766,27.4090667 C22.9766,25.9757333 23.9479333,25.0037333 25.3686,25.0037333 C25.9386,25.0037333 26.2992667,25.1257333 26.4559333,25.2077333 L26.3132667,25.6897333 C26.0886,25.5810667 25.7692667,25.4997333 25.3886,25.4997333 C24.3152667,25.4997333 23.6012667,26.1864 23.6012667,27.3890667 C23.6012667,28.5097333 24.2472667,29.2304 25.3612667,29.2304 C25.7212667,29.2304 26.0886,29.1557333 26.3259333,29.0397333 L26.4486,29.5090667 Z"
          id="Fill-13"
          fill="#132A5E"
        ></path>
        <path
          d="M28.8958667,27.9801333 C28.2432,27.9661333 27.5025333,28.0821333 27.5025333,28.7208 C27.5025333,29.1081333 27.7605333,29.2914667 28.0665333,29.2914667 C28.4945333,29.2914667 28.7665333,29.0201333 28.8612,28.7414667 C28.8818667,28.6801333 28.8958667,28.6121333 28.8958667,28.5508 L28.8958667,27.9801333 Z M28.9838667,29.6588 L28.9365333,29.2441333 L28.9158667,29.2441333 C28.7325333,29.5021333 28.3792,29.7334667 27.9098667,29.7334667 C27.2438667,29.7334667 26.9045333,29.2641333 26.9045333,28.7888 C26.9045333,27.9941333 27.6112,27.5588 28.8818667,27.5654667 L28.8818667,27.4974667 C28.8818667,27.2261333 28.8072,26.7368 28.1338667,26.7368 C27.8285333,26.7368 27.5092,26.8314667 27.2778667,26.9808 L27.1425333,26.5874667 C27.4138667,26.4108 27.8085333,26.2948 28.2225333,26.2948 C29.2285333,26.2948 29.4732,26.9808 29.4732,27.6401333 L29.4732,28.8701333 C29.4732,29.1554667 29.4872,29.4341333 29.5272,29.6588 L28.9838667,29.6588 Z"
          id="Fill-15"
          fill="#132A5E"
        ></path>
        <path
          d="M30.4252,27.2597333 C30.4252,26.9197333 30.4178667,26.6410667 30.3978667,26.3697333 L30.9212,26.3697333 L30.9485333,26.8997333 L30.9685333,26.8997333 C31.1525333,26.5870667 31.4578667,26.2950667 32.0012,26.2950667 C32.4498667,26.2950667 32.7898667,26.5664 32.9325333,26.9544 L32.9458667,26.9544 C33.0485333,26.7704 33.1772,26.6277333 33.3132,26.5257333 C33.5105333,26.3764 33.7278667,26.2950667 34.0405333,26.2950667 C34.4752,26.2950667 35.1205333,26.5804 35.1205333,27.7217333 L35.1205333,29.6584 L34.5365333,29.6584 L34.5365333,27.7964 C34.5365333,27.1644 34.3052,26.7844 33.8232,26.7844 C33.4832,26.7844 33.2178667,27.0357333 33.1158667,27.3277333 C33.0885333,27.4090667 33.0685333,27.5177333 33.0685333,27.6264 L33.0685333,29.6584 L32.4845333,29.6584 L32.4845333,27.6877333 C32.4845333,27.1644 32.2532,26.7844 31.7978667,26.7844 C31.4238667,26.7844 31.1525333,27.0830667 31.0572,27.3824 C31.0225333,27.4704 31.0098667,27.5724 31.0098667,27.6744 L31.0098667,29.6584 L30.4252,29.6584 L30.4252,27.2597333 Z"
          id="Fill-17"
          fill="#132A5E"
        ></path>
        <path
          d="M36.6784667,28.3401333 C36.6784667,28.4148 36.6924667,28.4894667 36.7058,28.5574667 C36.8211333,28.9721333 37.1678,29.2574667 37.6031333,29.2574667 C38.2278,29.2574667 38.6018,28.7481333 38.6018,27.9934667 C38.6018,27.3348 38.2618,26.7708 37.6231333,26.7708 C37.2158,26.7708 36.8344667,27.0488 36.7124667,27.5048 C36.6984667,27.5721333 36.6784667,27.6541333 36.6784667,27.7488 L36.6784667,28.3401333 Z M36.0598,29.6588 C36.0738,29.4341333 36.0871333,29.1014667 36.0871333,28.8094667 L36.0871333,24.8341333 L36.6784667,24.8341333 L36.6784667,26.8994667 L36.6924667,26.8994667 C36.9024667,26.5328 37.2831333,26.2948 37.8131333,26.2948 C38.6291333,26.2948 39.2064667,26.9741333 39.1991333,27.9734667 C39.1991333,29.1488 38.4591333,29.7334667 37.7244667,29.7334667 C37.2491333,29.7334667 36.8684667,29.5494667 36.6238,29.1148 L36.6038,29.1148 L36.5764667,29.6588 L36.0598,29.6588 Z"
          id="Fill-19"
          fill="#132A5E"
        ></path>
        <path
          d="M39.9473333,27.3958 C39.9473333,27.0084667 39.94,26.6751333 39.92,26.3698 L40.4433333,26.3698 L40.4633333,27.0151333 L40.4906667,27.0151333 C40.6406667,26.5738 41.0006667,26.2951333 41.402,26.2951333 C41.4693333,26.2951333 41.5166667,26.3018 41.572,26.3151333 L41.572,26.8791333 C41.5106667,26.8658 41.4493333,26.8584667 41.3673333,26.8584667 C40.946,26.8584667 40.6473333,27.1784667 40.566,27.6264667 C40.552,27.7084667 40.5386667,27.8031333 40.5386667,27.9051333 L40.5386667,29.6584667 L39.9473333,29.6584667 L39.9473333,27.3958 Z"
          id="Fill-21"
          fill="#132A5E"
        ></path>
        <mask id="mask-2" fill="white">
          <use xlinkHref="#path-1"></use>
        </mask>
        <g id="Clip-24"></g>
        <path
          d="M42.1887809,29.6586667 L42.9950283,29.6586667 L42.9950283,26.37 L42.1887809,26.37 L42.1887809,29.6586667 Z M43.0877107,25.4453333 C43.096709,25.6493333 42.8951472,25.8126667 42.573908,25.8126667 C42.2904617,25.8126667 42.088,25.6493333 42.088,25.4453333 C42.088,25.2346667 42.2985601,25.0713333 42.5919046,25.0713333 C42.8951472,25.0713333 43.0877107,25.2346667 43.0877107,25.4453333 L43.0877107,25.4453333 Z"
          id="Fill-23"
          fill="#132A5E"
          mask="url(#mask-2)"
        ></path>
        <path
          d="M45.9818,27.7083333 C45.9818,27.6336667 45.9751333,27.5316667 45.9551333,27.457 C45.8664667,27.0763333 45.5404667,26.7636667 45.0918,26.7636667 C44.4731333,26.7636667 44.1064667,27.307 44.1064667,28.0343333 C44.1064667,28.7003333 44.4331333,29.2503333 45.0784667,29.2503333 C45.4791333,29.2503333 45.8464667,28.9856667 45.9551333,28.5376667 C45.9751333,28.4556667 45.9818,28.3743333 45.9818,28.279 L45.9818,27.7083333 Z M46.5798,24.8336667 L46.5798,28.809 C46.5798,29.101 46.5864667,29.4343333 46.6071333,29.6583333 L46.0704667,29.6583333 L46.0431333,29.0876667 L46.0291333,29.0876667 C45.8464667,29.455 45.4451333,29.7336667 44.9084667,29.7336667 C44.1131333,29.7336667 43.5018,29.0603333 43.5018,28.0616667 C43.4951333,26.9676667 44.1744667,26.295 44.9764667,26.295 C45.4791333,26.295 45.8191333,26.5323333 45.9678,26.7976667 L45.9818,26.7976667 L45.9818,24.8336667 L46.5798,24.8336667 Z"
          id="Fill-25"
          fill="#132A5E"
          mask="url(#mask-2)"
        ></path>
        <path
          d="M49.7819333,27.6676 C49.7819333,27.5656 49.7746,27.4769333 49.7472667,27.3956 C49.6386,27.0489333 49.3472667,26.7636 48.9119333,26.7636 C48.3412667,26.7636 47.9339333,27.2462667 47.9339333,28.0069333 C47.9339333,28.6529333 48.2599333,29.1896 48.9052667,29.1896 C49.2719333,29.1896 49.6046,28.9589333 49.7346,28.5782667 C49.7679333,28.4762667 49.7819333,28.3609333 49.7819333,28.2589333 L49.7819333,27.6676 Z M50.3999333,26.3696 C50.3866,26.6076 50.3732667,26.8722667 50.3732667,27.2736 L50.3732667,29.1829333 C50.3732667,29.9369333 50.2232667,30.3989333 49.9039333,30.6842667 C49.5846,30.9836 49.1226,31.0789333 48.7079333,31.0789333 C48.3139333,31.0789333 47.8792667,30.9836 47.6139333,30.8069333 L47.7639333,30.3516 C47.9812667,30.4876 48.3206,30.6102667 48.7286,30.6102667 C49.3399333,30.6102667 49.7886,30.2902667 49.7886,29.4616 L49.7886,29.0942667 L49.7746,29.0942667 C49.5912667,29.4002667 49.2386,29.6449333 48.7286,29.6449333 C47.9126,29.6449333 47.3286,28.9516 47.3286,28.0416 C47.3286,26.9269333 48.0552667,26.2949333 48.8099333,26.2949333 C49.3806,26.2949333 49.6932667,26.5936 49.8359333,26.8656 L49.8499333,26.8656 L49.8772667,26.3696 L50.3999333,26.3696 Z"
          id="Fill-26"
          fill="#132A5E"
          mask="url(#mask-2)"
        ></path>
        <path
          d="M53.4447333,27.6946 C53.4520667,27.3139333 53.2887333,26.7226 52.6160667,26.7226 C52.0114,26.7226 51.7460667,27.2799333 51.6987333,27.6946 L53.4447333,27.6946 Z M51.6914,28.1226 C51.7054,28.9312667 52.2214,29.2639333 52.8200667,29.2639333 C53.2480667,29.2639333 53.5060667,29.1899333 53.7307333,29.0946 L53.8320667,29.5226 C53.6220667,29.6179333 53.2614,29.7266 52.7380667,29.7266 C51.7260667,29.7266 51.1214,29.0606 51.1214,28.0686 C51.1214,27.0759333 51.7054,26.2946 52.6634,26.2946 C53.7367333,26.2946 54.0220667,27.2392667 54.0220667,27.8439333 C54.0220667,27.9666 54.0094,28.0612667 54.0020667,28.1226 L51.6914,28.1226 Z"
          id="Fill-27"
          fill="#132A5E"
          mask="url(#mask-2)"
        ></path>
        <path
          d="M57.9232667,27.9801333 C57.2706,27.9661333 56.5306,28.0821333 56.5306,28.7208 C56.5306,29.1081333 56.7886,29.2914667 57.0946,29.2914667 C57.5226,29.2914667 57.7946,29.0201333 57.8892667,28.7414667 C57.9099333,28.6801333 57.9232667,28.6121333 57.9232667,28.5508 L57.9232667,27.9801333 Z M58.0119333,29.6588 L57.9646,29.2441333 L57.9439333,29.2441333 C57.7606,29.5021333 57.4072667,29.7334667 56.9379333,29.7334667 C56.2719333,29.7334667 55.9326,29.2641333 55.9326,28.7888 C55.9326,27.9941333 56.6392667,27.5588 57.9099333,27.5654667 L57.9099333,27.4974667 C57.9099333,27.2261333 57.8352667,26.7368 57.1626,26.7368 C56.8566,26.7368 56.5372667,26.8314667 56.3059333,26.9808 L56.1706,26.5874667 C56.4419333,26.4108 56.8359333,26.2948 57.2506,26.2948 C58.2566,26.2948 58.5012667,26.9808 58.5012667,27.6401333 L58.5012667,28.8701333 C58.5012667,29.1554667 58.5152667,29.4341333 58.5552667,29.6588 L58.0119333,29.6588 Z"
          id="Fill-28"
          fill="#132A5E"
          mask="url(#mask-2)"
        ></path>
        <path
          d="M59.4603333,27.2597333 C59.4603333,26.9197333 59.453,26.6410667 59.433,26.3697333 L59.963,26.3697333 L59.9976667,26.9130667 L60.0103333,26.9130667 C60.1736667,26.6004 60.5543333,26.2950667 61.0976667,26.2950667 C61.5536667,26.2950667 62.2596667,26.5664 62.2596667,27.6944 L62.2596667,29.6584 L61.6623333,29.6584 L61.6623333,27.7624 C61.6623333,27.2324 61.465,26.7910667 60.901,26.7910667 C60.5063333,26.7910667 60.201,27.0697333 60.099,27.4024 C60.0716667,27.4770667 60.0576667,27.5790667 60.0576667,27.6810667 L60.0576667,29.6584 L59.4603333,29.6584 L59.4603333,27.2597333 Z"
          id="Fill-29"
          fill="#132A5E"
          mask="url(#mask-2)"
        ></path>
        <path
          d="M65.461,27.7083333 C65.461,27.6336667 65.4543333,27.5316667 65.4343333,27.457 C65.3456667,27.0763333 65.0196667,26.7636667 64.571,26.7636667 C63.9523333,26.7636667 63.5856667,27.307 63.5856667,28.0343333 C63.5856667,28.7003333 63.9123333,29.2503333 64.5576667,29.2503333 C64.9583333,29.2503333 65.3256667,28.9856667 65.4343333,28.5376667 C65.4543333,28.4556667 65.461,28.3743333 65.461,28.279 L65.461,27.7083333 Z M66.059,24.8336667 L66.059,28.809 C66.059,29.101 66.0656667,29.4343333 66.0863333,29.6583333 L65.5496667,29.6583333 L65.5223333,29.0876667 L65.5083333,29.0876667 C65.3256667,29.455 64.9243333,29.7336667 64.3876667,29.7336667 C63.5923333,29.7336667 62.981,29.0603333 62.981,28.0616667 C62.9743333,26.9676667 63.6536667,26.295 64.4556667,26.295 C64.9583333,26.295 65.2983333,26.5323333 65.447,26.7976667 L65.461,26.7976667 L65.461,24.8336667 L66.059,24.8336667 Z"
          id="Fill-30"
          fill="#132A5E"
          mask="url(#mask-2)"
        ></path>
        <path
          d="M69.0905333,27.2258667 L69.6952,27.2258667 C70.3272,27.2258667 70.7285333,26.8792 70.7285333,26.3558667 C70.7285333,25.7652 70.2998667,25.5065333 69.6752,25.4998667 C69.3898667,25.4998667 69.1858667,25.5272 69.0905333,25.5538667 L69.0905333,27.2258667 Z M68.4992,25.1398667 C68.7985333,25.0785333 69.2265333,25.0445333 69.6338667,25.0445333 C70.2665333,25.0445333 70.6738667,25.1598667 70.9598667,25.4185333 C71.1898667,25.6218667 71.3198667,25.9345333 71.3198667,26.2878667 C71.3198667,26.8925333 70.9385333,27.2938667 70.4565333,27.4572 L70.4565333,27.4772 C70.8098667,27.5998667 71.0212,27.9258667 71.1298667,28.4012 C71.2785333,29.0398667 71.3872,29.4818667 71.4825333,29.6585333 L70.8712,29.6585333 C70.7965333,29.5292 70.6945333,29.1352 70.5652,28.5645333 C70.4298667,27.9325333 70.1852,27.6945333 69.6478667,27.6745333 L69.0905333,27.6745333 L69.0905333,29.6585333 L68.4992,29.6585333 L68.4992,25.1398667 Z"
          id="Fill-31"
          fill="#132A5E"
          mask="url(#mask-2)"
        ></path>
        <path
          d="M72.0678,28.9381333 C72.3324667,29.1014667 72.7204667,29.2374667 73.1278,29.2374667 C73.7324667,29.2374667 74.0858,28.9181333 74.0858,28.4554667 C74.0858,28.0274667 73.8411333,27.7834667 73.2224667,27.5454667 C72.4751333,27.2801333 72.0131333,26.8928 72.0131333,26.2474667 C72.0131333,25.5341333 72.6051333,25.0041333 73.4951333,25.0041333 C73.9638,25.0041333 74.3038,25.1128 74.5071333,25.2281333 L74.3444667,25.7108 C74.1944667,25.6288 73.8884667,25.4934667 73.4744667,25.4934667 C72.8491333,25.4934667 72.6111333,25.8668 72.6111333,26.1794667 C72.6111333,26.6074667 72.8898,26.8181333 73.5224667,27.0628 C74.2971333,27.3614667 74.6911333,27.7354667 74.6911333,28.4081333 C74.6911333,29.1148 74.1671333,29.7268 73.0871333,29.7268 C72.6451333,29.7268 72.1631333,29.5974667 71.9178,29.4341333 L72.0678,28.9381333 Z"
          id="Fill-32"
          fill="#132A5E"
          mask="url(#mask-2)"
        ></path>
        <path
          d="M77.7357333,27.7558 L77.2870667,26.4371333 C77.1850667,26.1384667 77.1170667,25.8671333 77.0490667,25.6018 L77.0357333,25.6018 C76.9677333,25.8738 76.8924,26.1524667 76.8050667,26.4304667 L76.3564,27.7558 L77.7357333,27.7558 Z M76.2337333,28.2178 L75.7577333,29.6584667 L75.1464,29.6584667 L76.7024,25.0784667 L77.4164,25.0784667 L78.9790667,29.6584667 L78.3470667,29.6584667 L77.8584,28.2178 L76.2337333,28.2178 Z"
          id="Fill-33"
          fill="#132A5E"
          mask="url(#mask-2)"
        ></path>
        <path
          d="M13.9577333,2.54553333 C19.1277333,2.54553333 22.6210667,5.6342 22.6210667,10.8415333 C22.6210667,16.0495333 19.1277333,19.1388667 13.9577333,19.1388667 C8.7884,19.1388667 5.29573333,16.0495333 5.29573333,10.8415333 C5.29573333,5.6342 8.7884,2.54553333 13.9577333,2.54553333 M13.9577333,21.6822 C22.1084,21.6822 27.9164,17.4842 27.9164,10.8415333 C27.9164,4.1982 22.1084,0.0002 13.9577333,0.0002 C5.80773333,0.0002 -0.000266666667,4.1982 -0.000266666667,10.8415333 C-0.000266666667,17.4842 5.80773333,21.6822 13.9577333,21.6822"
          id="Fill-34"
          fill="#132A5E"
          mask="url(#mask-2)"
        ></path>
        <path
          d="M66.9401333,10.1546 L61.4861333,10.1546 L61.4861333,2.73926667 L66.9401333,2.73926667 C71.0934667,2.73926667 72.7221333,4.25326667 72.7221333,6.44726667 C72.7221333,8.6406 71.0934667,10.1546 66.9401333,10.1546 M77.3008,6.44726667 C77.3008,4.33593333 76.0068,2.4966 73.9074667,1.6206 C71.9314667,0.795933333 70.0768,0.499266667 66.6628,0.499266667 L57.1141333,0.499266667 L57.1141333,21.1786 L61.4861333,21.1786 L61.4861333,12.3732667 L63.7288,12.3732667 L73.6094667,21.1786 L79.3708,21.1786 L69.0128,12.3106 C71.0374667,12.1859333 72.4401333,11.8866 73.9074667,11.2746 C76.0068,10.3972667 77.3008,8.55926667 77.3008,6.44726667"
          id="Fill-35"
          fill="#132A5E"
          mask="url(#mask-2)"
        ></path>
        <path
          d="M44.7207333,19.0208 C39.7107333,18.8674667 35.8447333,15.8348 35.8447333,10.8401333 C35.8447333,5.8428 39.7107333,2.81013333 44.7207333,2.6588 C47.5400667,2.57413333 49.6860667,3.22013333 51.7887333,4.44813333 L54.0607333,2.11946667 C51.6134,0.787466667 48.5654,0.000133333333 44.6514,0.000133333333 C36.4227333,0.000133333333 30.5354,4.19613333 30.5354,10.8401333 C30.5354,17.4828 36.4227333,21.6781333 44.6514,21.6781333 C48.5580667,21.6781333 51.6027333,20.8941333 54.0480667,19.5661333 L51.7754,17.2381333 C49.6767333,18.4621333 47.5347333,19.1041333 44.7207333,19.0208"
          id="Fill-36"
          fill="#132A5E"
          mask="url(#mask-2)"
        ></path>
      </g>
    </g>
  </svg>
);

export default OCRLogo;
