import React from 'react';

import Icon, { IconProps } from '../components/Iconography/IconButtons/Icon';
import { PdfFile, PowerPoint, TxtFile, Word, Excel, Keynote, ZipFile } from '../icons';

export const ContentIcon: React.FC<{ type?: string; dataTestId?: string } & IconProps> = ({
  type,
  dataTestId,
  ...rest
}) => {
  switch (type) {
    case 'doc':
    case 'docx':
      return (
        <Icon size="small" color="#2B579A" data-testid={`${dataTestId}-word`} {...rest}>
          <Word />
        </Icon>
      );
    case 'xls':
    case 'xlsx':
      return (
        <Icon size="small" color="#217346" data-testid={`${dataTestId}-excel`} {...rest}>
          <Excel />
        </Icon>
      );
    case 'ppt':
    case 'pptx':
      return (
        <Icon size="small" color="#C03A1B" data-testid={`${dataTestId}-powerpoint`} {...rest}>
          <PowerPoint />
        </Icon>
      );
    case 'pdf':
      return (
        <Icon size="small" color="#DD1D01" data-testid={`${dataTestId}-pdf`} {...rest}>
          <PdfFile />
        </Icon>
      );
    case 'key':
      return (
        <Icon size="small" color="#039DFA" data-testid={`${dataTestId}-keynote`} {...rest}>
          <Keynote />
        </Icon>
      );
    case 'txt':
      return (
        <Icon size="small" data-testid={`${dataTestId}-txt`} {...rest}>
          <TxtFile />
        </Icon>
      );
    case 'zip':
      return (
        <Icon size="small" data-testid={`${dataTestId}-zip`} {...rest}>
          <ZipFile />
        </Icon>
      );
    default:
      return <Icon />;
  }
};
