import React, { ReactElement } from 'react';
import { IconButton as MuiIconButton, IconButtonProps as MuiIconButtonProps } from '@material-ui/core';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { useIconButtonStyles } from './styles';
import { InferPropTypes, NonNullFields, Size } from '../../types';

const iconButtonPropTypes = {
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  color: PropTypes.string,
  container: PropTypes.bool,
  pressed: PropTypes.bool,
  dataTestId: PropTypes.string,
};

const defaultProps = {
  size: 'medium',
};

export type IconButtonProps = NonNullFields<InferPropTypes<typeof iconButtonPropTypes, typeof defaultProps>> &
  Omit<MuiIconButtonProps, 'size' | 'color'>;

const IconButton: React.FC<IconButtonProps> = ({
  size,
  children,
  color,
  className,
  container,
  pressed,
  dataTestId,
  ...rest
}): ReactElement => {
  const classes = useIconButtonStyles({ color });
  return (
    <MuiIconButton
      className={clsx(
        classes[size as Size],
        classes.withColor,
        container && classes.buttonContainer,
        pressed && classes.pressed,
        className,
      )}
      data-testid={dataTestId}
      {...rest}
    >
      {children}
    </MuiIconButton>
  );
};

IconButton.propTypes = iconButtonPropTypes;
IconButton.defaultProps = defaultProps;

export default IconButton;
