import React from 'react';

const Warning: React.FC<{ className?: string }> = ({ className }) => (
  <svg
    viewBox="0 0 24 24"
    version="1.1"
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.00137 20.0099C2.46137 20.0099 1.50137 18.3399 2.27137 17.0099L9.80137 3.99994C10.5714 2.66994 12.4914 2.66994 13.2614 3.99994L20.7914 17.0099C21.5614 18.3399 20.6014 20.0099 19.0614 20.0099H4.00137ZM19.0614 18.0099L11.5314 4.99994L4.00137 18.0099H19.0614Z"
    />
    <path d="M10.5312 10.01V13C10.5312 13.55 10.9812 14 11.5312 14C12.0812 14 12.5312 13.55 12.5312 13V10.01C12.5312 9.46001 12.0812 9.01001 11.5312 9.01001C10.9812 9.01001 10.5312 9.46001 10.5312 10.01Z" />
    <circle cx="11.5312" cy="16.01" r="1" />
  </svg>
);

export default Warning;
