import React from 'react';
import { Breadcrumbs as MuiBreadcrumbs, BreadcrumbsProps as MuiBreadCrumbsProps } from '@material-ui/core';
import clsx from 'clsx';
import PropTypes from 'prop-types';

import ChevronIcon from '../../icons/ChevronIcon';
import { InferPropTypes, NonNullFields } from '../types';

const breadCrumbPropTypes = {
  gutter: PropTypes.oneOf(['sm', 'md', 'lg', false]),
  dataTestId: PropTypes.string,
};

const defaultProps = {
  gutter: false,
};

export type BreadcrumbsProps = NonNullFields<InferPropTypes<typeof breadCrumbPropTypes, typeof defaultProps>> &
  MuiBreadCrumbsProps;

const Breadcrumbs: React.FC<BreadcrumbsProps> = ({ dataTestId, children, gutter, separator, className, ...rest }) => (
  <MuiBreadcrumbs
    separator={separator || <ChevronIcon />}
    {...rest}
    className={clsx(gutter && `gutter-${gutter}`, className)}
    data-testid={dataTestId}
  >
    {children}
  </MuiBreadcrumbs>
);

Breadcrumbs.propTypes = breadCrumbPropTypes;
Breadcrumbs.defaultProps = defaultProps;

export default Breadcrumbs;
