import { ThemeOptions, createTheme } from '@material-ui/core/styles';
import { Overrides } from '@material-ui/core/styles/overrides';
import { MuiPickersOverrides } from '@material-ui/pickers/typings/overrides';
import { Theme as MuiTheme } from '@material-ui/core';
import { Palette as MuiPalette } from '@material-ui/core/styles/createPalette';
import { buttonDefaultProps, iconButtonDefaultProps } from '../Button/buttonTheme';
import { buttonBaseDefaultProps, inputLabelDefaultProps, textFieldDefaultProps } from './inputTheme';
import { BreakpointsOptions } from '@material-ui/core/styles/createBreakpoints';
import { getTypographyOptions } from '../Typography/typographyTheme';
import { basePalette, CustomPaletteOptions } from './basePalette';
import fontfaces from '../../abstracts/fonts';
import getOverrides from './themeOverrides';

export type OverridesType = MuiPickersOverrides & Overrides;

interface OverridenThemeOptions extends Omit<ThemeOptions, 'overrides'> {
  overrides?: OverridesType;
}

interface Palette extends Omit<MuiPalette, 'text'>, CustomPaletteOptions {}

export interface Theme extends Omit<MuiTheme, 'palette'> {
  palette: Palette;
}

export const breakpointOptions: BreakpointsOptions = {
  values: {
    xs: 0,
    sm: 600,
    md: 1024,
    lg: 1600,
    xl: 1920,
  },
};

const baseThemeOptions: ThemeOptions = {
  palette: basePalette,
  breakpoints: breakpointOptions,
};

export const baseTheme = createTheme(baseThemeOptions) as Theme;
export const themeOptionsNoFonts: OverridenThemeOptions = {
  palette: basePalette,
  breakpoints: breakpointOptions,
  props: {
    MuiButton: buttonDefaultProps,
    MuiIconButton: iconButtonDefaultProps,
    MuiTextField: textFieldDefaultProps,
    MuiInputLabel: inputLabelDefaultProps,
    MuiButtonBase: buttonBaseDefaultProps,
  },
  typography: getTypographyOptions(baseTheme),
  overrides: getOverrides(baseTheme),
};
export const themeOptions: OverridenThemeOptions = {
  ...themeOptionsNoFonts,
  overrides: {
    ...themeOptionsNoFonts.overrides,
    MuiCssBaseline: {
      '@global': {
        '@font-face': fontfaces,
      },
    },
  },
};
export const mainTheme = createTheme(themeOptions) as Theme;
export const mainThemeNoFonts = createTheme(themeOptionsNoFonts) as Theme;

export default mainTheme;
