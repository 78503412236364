export const SMALL = '12px';
export const MEDIUM = '15px';
export const LARGE = '20px';
export const XLARGE = '24px';
export const XXLARGE = '35px';
export const sourceSansPro = 'Source Sans Pro';
export const lato = 'Lato';

export const fonts = {
  sourceSansPro,
  lato,
};

export const CONTENT_TYPES = {
  PDF: 'pdf',
  POWERPOINT: 'ppt',
  WORD: 'doc',
  EXCEL: 'xls',
  KEYNOTE: 'key',
  TXT: 'txt',
  ZIP: 'zip',
};

export default {
  SMALL,
  MEDIUM,
  LARGE,
  XLARGE,
  XXLARGE,
  sourceSansPro,
  lato,
  fonts,
  CONTENT_TYPES,
};
