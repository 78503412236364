import React from 'react';

const Powerpoint: React.FC<{ className?: string }> = ({ className }) => (
  <svg
    width="36px"
    height="36px"
    viewBox="0 0 36 36"
    version="1.1"
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.1716 3C20.702 3 21.2107 3.21071 21.5858 3.58579L29.4142 11.4142C29.7893 11.7893 30 12.298 30 12.8284V30C30 31.65 28.65 33 27 33H8.985C7.335 33 6 31.65 6 30V6C6 4.35 7.35 3 9 3H20.1716ZM21 4.75V10.5C21 11.325 21.675 12 22.5 12H28.25L21 4.75Z"
    />
    <path
      d="M14.4664 26V15.568H18.0504C18.6264 15.568 19.165 15.6267 19.6664 15.744C20.1677 15.8507 20.605 16.0373 20.9784 16.304C21.3517 16.56 21.645 16.9067 21.8584 17.344C22.0824 17.7707 22.1944 18.2987 22.1944 18.928C22.1944 19.536 22.0824 20.064 21.8584 20.512C21.645 20.96 21.3517 21.328 20.9784 21.616C20.605 21.904 20.173 22.1173 19.6824 22.256C19.1917 22.3947 18.669 22.464 18.1144 22.464H16.8184V26H14.4664ZM16.8184 20.592H17.9704C19.2504 20.592 19.8904 20.0373 19.8904 18.928C19.8904 18.384 19.7197 18 19.3784 17.776C19.037 17.552 18.5464 17.44 17.9064 17.44H16.8184V20.592Z"
      fill="white"
    />
  </svg>
);

export default Powerpoint;
