import CambridgeAssessmentLogo from './CambridgeAssessmentLogo';
import CambridgeAssessmentEnglishLogo from './CambridgeAssessmentEnglishLogo';
import CambridgeInternationalLogo from './CambridgeInternationalLogo';
import OCRLogo from './OCRLogo';
import CambridgeUniversityPressLogo from './CambridgeUniversityPressLogo';

export default {
  CambridgeAssessmentLogo,
  CambridgeAssessmentEnglishLogo,
  CambridgeInternationalLogo,
  OCRLogo,
  CambridgeUniversityPressLogo,
};
