import React, { FC, ReactElement, useMemo, ReactNode, useRef, useCallback, useState, useEffect } from 'react';
import { FormControl, Select, MenuProps, FormHelperText, SelectProps, useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import PropTypes from 'prop-types';

import { InputLabel } from '../InputLabel';
import { InferPropTypes, NonNullFields, ValidationPropType } from '../../types';
import DropdownItem from './DropdownItem';
import DropdownGroup from './DropdownGroup';

interface DropdownData {
  text: string;
  value: React.ReactText;
}

export type DataArray = DropdownData[];
export type MultiDimensionalDataArray = DropdownData[][];
export type DataType = DataArray | MultiDimensionalDataArray;

const dropdownPropTypes = {
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.arrayOf(PropTypes.number),
  ]),
  menuProps: PropTypes.object,
  open: PropTypes.bool,
  label: PropTypes.string,
  renderValue: PropTypes.func,
  placeholder: PropTypes.string,
  displayEmpty: PropTypes.bool,
  optional: PropTypes.bool,
  hintText: PropTypes.string,
  dataTestId: PropTypes.string,
  helperText: PropTypes.string,
  validationType: PropTypes.oneOf(ValidationPropType),
  children: PropTypes.node,
  data: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.shape({
        text: PropTypes.string,
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      }),
    ),
    PropTypes.arrayOf(
      PropTypes.arrayOf(
        PropTypes.shape({
          text: PropTypes.string,
          value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        }),
      ),
    ),
  ]),
  multiple: PropTypes.bool,
};

const defaultDropdownPropTypes = {
  data: [],
  menuProps: {
    anchorOrigin: {
      vertical: 'bottom',
      horizontal: 'left',
    },
    transformOrigin: {
      vertical: 0,
      horizontal: 'left',
    },
    getContentAnchorEl: null,
  },
};

export type DropdownProps = NonNullFields<InferPropTypes<typeof dropdownPropTypes, typeof defaultDropdownPropTypes>> &
  Omit<SelectProps, 'label'>;

const Dropdown: FC<DropdownProps> = ({
  className,
  label,
  id,
  optional,
  hintText,
  disabled,
  required,
  helperText,
  validationType,
  children,
  value,
  menuProps,
  open,
  renderValue,
  onChange,
  dataTestId,
  inputProps,
  onClose,
  onOpen,
  placeholder = 'Please select...',
  displayEmpty = true,
  style,
  multiple,
  ...rest
}) => {
  const selectRef = useRef<HTMLDivElement>(null);
  const defaultValue = multiple ? [] : '';
  const [selectValue, setSelectValue] = useState<unknown>(value || defaultValue);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));

  useEffect(() => {
    if (multiple) {
      setSelectValue((value as React.ReactText[]) || []);
    }
  }, [value, multiple]);

  const formedData: ReactElement[] = useMemo(
    () =>
      React.Children.map<ReactNode, ReactNode>(children, (child) => {
        if (React.isValidElement(child)) {
          if (child?.type === DropdownItem) {
            return React.cloneElement(child, {
              ...child.props,
              isMobile,
              multiple,
              checked: multiple && (selectValue as React.ReactText[]).indexOf(child.props.value) > -1,
            });
          }
          if (child?.type === DropdownGroup) {
            return React.cloneElement(child, {
              ...child.props,
              isMobile,
            });
          }
          return child;
        }
      }) as ReactElement[],
    [isMobile, multiple, selectValue, children],
  );

  const onCloseAction = useCallback(
    (e) => {
      if (e.type === 'click') {
        selectRef.current?.classList.remove('Mui-focused');
      }
      onClose?.(e);
    },
    [selectRef, onClose],
  );

  const changeSelectValue = useCallback(
    (e, item) => {
      if (multiple) {
        if (isMobile) {
          const { options } = e.target;
          const selectedOptions: string[] = [];
          Array.from(options).forEach((option) => {
            if ((option as { selected: boolean }).selected) {
              selectedOptions.push((option as { value: string }).value);
            }
          });

          setSelectValue(selectedOptions);
          onChange?.(e, selectedOptions);
        } else {
          setSelectValue(e.target.value);
          onChange?.(e, e.target.value);
        }
      } else {
        if (isMobile) {
          setSelectValue(e.target.value);
          onChange?.(e, e.target.value);
        } else {
          setSelectValue(item.props.value);
          onChange?.(e, item);
        }
      }
    },
    [onChange, isMobile, multiple],
  );

  const handleRenderValue = useCallback(
    (optionValue): ReactNode => {
      if (renderValue) {
        if (typeof renderValue === 'function') return renderValue(optionValue);
        else {
          return renderValue;
        }
      }
      return placeholder;
    },
    [placeholder, renderValue],
  );

  return (
    <FormControl
      style={style}
      fullWidth
      required={required}
      className={className}
      disabled={disabled}
      data-testid={dataTestId}
    >
      {label && <InputLabel htmlFor={id} label={label} optional={optional} hintText={hintText} disabled={disabled} />}
      <Select
        id={id}
        renderValue={handleRenderValue}
        open={open}
        MenuProps={menuProps as Partial<MenuProps>}
        disableUnderline={true}
        value={selectValue}
        IconComponent={ExpandMoreIcon}
        className={'select-input ' + validationType}
        onChange={changeSelectValue}
        native={isMobile}
        data-testid={dataTestId && `${dataTestId}-select`}
        displayEmpty={displayEmpty}
        onClose={onCloseAction}
        onOpen={onOpen}
        inputProps={{
          ...(inputProps || { 'data-testid': `${dataTestId}-input` }),
          'aria-label': 'select',
        }}
        ref={selectRef}
        multiple={multiple}
        {...rest}
      >
        {formedData}
      </Select>
      {validationType && ['error', 'warning'].includes(validationType) && helperText && (
        <FormHelperText data-testid={`${dataTestId}-helper-text`} className={`helper-${validationType}`}>
          {helperText}
        </FormHelperText>
      )}
    </FormControl>
  );
};

Dropdown.propTypes = dropdownPropTypes;
Dropdown.defaultProps = defaultDropdownPropTypes;

export default Dropdown;
