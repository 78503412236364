import React from 'react';
import { Link as MuiLink, LinkProps as MuiLinkProps } from '@material-ui/core';
import PropTypes from 'prop-types';

import { InferPropTypes, NonNullFields } from '../types';

const linkPropTypes = {
  dataTestId: PropTypes.string,
};

export type LinkProps = NonNullFields<InferPropTypes<typeof linkPropTypes>> & MuiLinkProps;

const Link: React.FC<LinkProps> = ({ children, dataTestId, ...rest }) => (
  <MuiLink data-testid={dataTestId} underline="always" {...rest}>
    {children}
  </MuiLink>
);

Link.propTypes = linkPropTypes;

export default Link;
