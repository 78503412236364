import { makeStyles, Theme } from '@material-ui/core/styles';

const withColor = (props: { color: string | undefined }, theme: Theme): string =>
  props.color || theme.palette.text.primary;

export const useIconStyles = makeStyles((theme) => ({
  withColor: {
    '& > span': {
      color: (props: { color: string | undefined }): string => withColor(props, theme),
    },
    '& > svg': {
      fill: (props: { color: string | undefined }): string => withColor(props, theme),
    },
  },
  small: {
    width: 24,
    height: 24,
    '& > svg': {
      width: 24,
      height: 24,
    },
  },
  medium: {
    width: 36,
    height: 36,
    '& > svg': {
      width: 36,
      height: 36,
    },
  },
  large: {
    width: 48,
    height: 48,
    '& > svg': {
      width: 48,
      height: 48,
    },
  },
}));

export const useIconButtonStyles = makeStyles((theme) => ({
  buttonContainer: {
    backgroundColor: theme.palette.background.paper,
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: '50%',
  },
  pressed: {
    backgroundColor: theme.palette.action.active,
  },
  withColor: {
    '& > span': {
      '& > span': {
        color: (props: { color: string | undefined }): string => withColor(props, theme),
      },
      '& > svg': {
        fill: (props: { color: string | undefined }): string => withColor(props, theme),
      },
    },
  },
  small: {
    width: 36,
    height: 36,
    '& > span': {
      width: 24,
      height: 24,
      '& > svg': {
        width: 24,
        height: 24,
      },
    },
  },
  medium: {
    width: 56,
    height: 56,
    '& > span': {
      width: 36,
      height: 36,
      '& > svg': {
        width: 36,
        height: 36,
      },
    },
  },
  large: {
    width: 72,
    height: 72,
    '& > span': {
      width: 48,
      height: 48,
      '& > svg': {
        width: 48,
        height: 48,
      },
    },
  },
}));
