import React from 'react';
import { TableSortLabel as MuiTableSortLabel, TableSortLabelProps as MuiTableSortLabelProps } from '@material-ui/core';
import { ArrowDownwardRounded } from '@material-ui/icons';
import PropTypes from 'prop-types';

import { InferPropTypes, NonNullFields } from '../types';

const tableSortLabelPropTypes = {
  dataTestId: PropTypes.string,
};

export type TableSortLabelProps = NonNullFields<InferPropTypes<typeof tableSortLabelPropTypes>> &
  MuiTableSortLabelProps;

const TableSortLabel: React.FC<TableSortLabelProps> = ({ children, dataTestId, ...rest }) => (
  <MuiTableSortLabel data-testid={dataTestId} IconComponent={ArrowDownwardRounded} {...rest}>
    {children}
  </MuiTableSortLabel>
);

TableSortLabel.propTypes = tableSortLabelPropTypes;

export default TableSortLabel;
