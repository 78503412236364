import { CheckboxClassKey } from '@material-ui/core';
import { CSSProperties } from '@material-ui/core/styles/withStyles';
import { Theme } from '../../Theme/theme';
import { getDarkest } from '../../../abstracts/colours';

export const iconActionStyle = (theme: Theme) => ({
  '&:active': {
    backgroundColor: 'transparent',
    '& span': {
      '& svg': {
        '& path': {
          fill: theme.palette.action.active,
          stroke: theme.palette.text.primary,
        },
      },
    },
  },
  '&:hover, &.Mui-focusVisible': {
    backgroundColor: 'transparent',
    '& span': {
      '& svg': {
        '& path': {
          fill: theme.palette.action.hover,
          stroke: theme.palette.text.primary,
        },
      },
    },
  },
  '&$disabled': {
    '& span': {
      '& svg': {
        '& > g': {
          opacity: 0.3,
        },
      },
    },
  },
  '&.required:not($checked)': {
    '& span': {
      '& svg': {
        '& path': {
          stroke: theme.palette.error.main,
        },
      },
    },
    '&:active': {
      '& span': {
        '& svg': {
          '& path': {
            stroke: getDarkest(theme.palette.error),
          },
        },
      },
    },
    '&:hover, &.Mui-focusVisible': {
      '& span': {
        '& svg': {
          '& path': {
            stroke: theme.palette.error.dark,
          },
        },
      },
    },
  },
  '&$checked': {
    '&:active': {
      backgroundColor: 'transparent',
      '& span': {
        '& svg': {
          '& path': {
            stroke: 'none',
            '&:first-child': {
              fill: getDarkest(theme.palette.primary),
            },
            '&:last-child:': {
              fill: theme.palette.background.paper,
            },
          },
        },
      },
    },
    '&:hover, &.Mui-focusVisible': {
      backgroundColor: 'transparent',
      '& span': {
        '& svg': {
          '& path': {
            stroke: 'none',
            '&:first-child': {
              fill: theme.palette.primary.dark,
            },
            '&:last-child:': {
              fill: theme.palette.background.paper,
            },
          },
        },
      },
    },
    '&$disabled': {
      '& span': {
        '& svg': {
          '& path:first-child': {
            opacity: 0.3,
          },
        },
      },
    },
  },
});

export const checkboxTheme = (theme: Theme): Partial<Record<CheckboxClassKey, CSSProperties>> => ({
  root: {
    '&.MuiIconButton-root': {
      ...iconActionStyle(theme),
      '&$indeterminate': {
        '&:active': {
          backgroundColor: 'transparent',
          '& span': {
            '& svg': {
              '& path': {
                stroke: theme.palette.background.paper,
                '&:first-child': {
                  stroke: getDarkest(theme.palette.primary),
                  fill: getDarkest(theme.palette.primary),
                },
              },
            },
          },
        },
        '&:hover, &.Mui-focusVisible': {
          backgroundColor: 'transparent',
          '& span': {
            '& svg': {
              '& path': {
                stroke: theme.palette.background.paper,
                '&:first-child': {
                  stroke: theme.palette.primary.dark,
                  fill: theme.palette.primary.dark,
                },
              },
            },
          },
        },
      },
    },
  },
});
