import React, { FC, useRef, KeyboardEvent } from 'react';
import { FormControlLabel, Radio as MuiRadio, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { NonNullFields, InferPropTypes, Color } from '../../types';
import { RadioIcon, RadioCheckedIcon } from '../../../icons';
import { TextField } from '../TextField';

export const radioPropTypes = {
  checked: PropTypes.bool.isRequired,
  label: PropTypes.string,
  name: PropTypes.string,
  color: PropTypes.oneOf(['primary', 'secondary', 'default']),
  className: PropTypes.string,
  dataTestId: PropTypes.string,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  hintText: PropTypes.string,
  validationText: PropTypes.string,
  conditional: PropTypes.bool,
  textFieldValue: PropTypes.string,
  onTextFieldChange: PropTypes.func,
  required: PropTypes.bool,
  bold: PropTypes.bool,
};

export const defaultProps = {
  color: 'primary',
};

export type RadioProps = NonNullFields<InferPropTypes<typeof radioPropTypes, typeof defaultProps>>;

const conditionalIndicator = {
  content: "''",
  width: 7,
  borderStyle: 'solid',
  borderWidth: 0,
  borderLeftWidth: '2px',
  borderBottomWidth: '2px',
  position: 'absolute',
  marginLeft: -22,
  zIndex: 1,
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    textField: {
      marginTop: 9,
      display: 'block',
      '&::before': {
        ...conditionalIndicator,
        borderColor: theme.palette.primary.main,
        height: 26,
      },
    },
    textFieldWithHint: {
      marginTop: 9,
      '&::before': {
        ...conditionalIndicator,
        borderColor: theme.palette.primary.main,
        height: 45,
        marginTop: -20,
      },
    },
    validationText: {
      color: theme.palette.error.main,
      fontSize: 14,
      marginLeft: -33,
      marginTop: 5,
    },
    validationTextWithHint: {
      marginTop: 3,
    },
  }),
);

const Radio: FC<RadioProps> = ({
  label,
  checked,
  name,
  color,
  className,
  dataTestId,
  onChange,
  disabled,
  hintText,
  validationText,
  conditional,
  textFieldValue,
  onTextFieldChange,
  required,
  bold,
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const classes = useStyles();

  return (
    <FormControlLabel
      control={
        <MuiRadio
          name={name}
          checked={checked}
          icon={<RadioIcon id={name} />}
          checkedIcon={<RadioCheckedIcon id={name} />}
          data-testid={`${dataTestId}-box`}
          color={color as Color}
          className={clsx(className + '-radio', required ? 'required' : '')}
          disableRipple
          onChange={onChange}
          disabled={disabled}
          required={required}
          onKeyDown={(e: KeyboardEvent): void | undefined =>
            e.key === 'Enter' ? inputRef?.current?.click() : undefined
          }
          inputRef={inputRef}
        />
      }
      label={
        <>
          <span className={bold ? 'bold' : ''}>{label}</span>
          {hintText && <div className="form-control-hint-text">{hintText}</div>}
          {conditional && checked && (
            <TextField
              dataTestId={dataTestId}
              disabled={disabled}
              value={textFieldValue}
              onChange={onTextFieldChange}
              className={hintText ? classes.textFieldWithHint : classes.textField}
            />
          )}
          {!checked && validationText && (
            <Typography className={clsx(classes.validationText, hintText && classes.validationTextWithHint)}>
              {validationText}
            </Typography>
          )}
        </>
      }
      className={clsx(
        className,
        hintText && 'radio-hint-text',
        validationText && !checked && 'validation-text',
        conditional && checked && 'conditional-text-field',
      )}
      data-testid={dataTestId}
      disabled={disabled}
    />
  );
};

Radio.propTypes = radioPropTypes;
Radio.defaultProps = defaultProps;

export default Radio;
