import React from 'react';
import { TableCell as MuiTableCell, TableCellProps as MuiTableCellProps } from '@material-ui/core';
import PropTypes from 'prop-types';

import { InferPropTypes, NonNullFields } from '../types';

const tableCellPropTypes = {
  dataTestId: PropTypes.string,
};

export type TableCellProps = NonNullFields<InferPropTypes<typeof tableCellPropTypes>> & MuiTableCellProps;

const TableCell: React.FC<TableCellProps> = ({ children, dataTestId, ...rest }) => (
  <MuiTableCell data-testid={dataTestId} {...rest}>
    {children}
  </MuiTableCell>
);

TableCell.propTypes = tableCellPropTypes;

export default TableCell;
