import React from 'react';
import { Container, ContainerProps } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme, makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

import { monochromeLight, white } from '../../abstracts/colours';

export interface FooterProps extends ContainerProps {
  maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false;
  /**
   * Breakpoint when to switch to vertical footer
   */
  breakpoint?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
}

const styles = makeStyles({
  footerContainer: {
    borderTop: `1px solid ${monochromeLight}`,
    padding: '24px 24px 40px 24px',
    backgroundColor: white,
    display: 'flex',
    flexDirection: 'row',
    overflow: 'hidden',
    '& .branding-container': {
      display: 'flex',
      flexWrap: 'wrap',
      width: '100%',
      margin: -12,
      height: 0,
    },
  },
  vertical: {
    borderTop: 'none',
    padding: 0,
    flexDirection: 'column',
    '& .branding-container': {
      padding: '24px 24px 40px 24px',
      height: '100% !important',
    },
  },
});

export const Footer: React.FC<FooterProps> = ({ children, maxWidth, className, breakpoint = 'sm', ...rest }) => {
  const theme = useTheme();
  const { footerContainer, vertical } = styles();
  const returnDropdown = useMediaQuery(theme.breakpoints.down(breakpoint));

  return (
    <Container
      className={clsx(footerContainer, returnDropdown && vertical, className)}
      disableGutters
      maxWidth={maxWidth}
      {...rest}
    >
      {children}
    </Container>
  );
};

export default Footer;
