import React from 'react';
import { makeStyles, useTheme, Theme, createStyles } from '@material-ui/core/styles';
import {
  LastPageRounded,
  FirstPageRounded,
  KeyboardArrowRightRounded,
  KeyboardArrowLeftRounded,
} from '@material-ui/icons';

import { IconButton } from '../../';

interface TablePaginationActionsProps {
  count: number;
  page: number;
  rowsPerPage: number;
  onPageChange: (event: React.MouseEvent<HTMLButtonElement>, newPage: number) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexShrink: 0,
      marginLeft: 16,
    },
    firstPageButton: {
      borderRadius: 0,
      borderTopLeftRadius: 4,
      borderBottomLeftRadius: 4,
      border: `1px solid ${theme.palette.divider}`,
      borderRight: 0,
    },
    previousPageButton: {
      borderRadius: 0,
      border: `1px solid ${theme.palette.divider}`,
      borderRight: 0,
    },
    nextPageButton: {
      borderRadius: 0,
      border: `1px solid ${theme.palette.divider}`,
      borderRight: 0,
    },
    lastPageButton: {
      borderRadius: 0,
      borderTopRightRadius: 4,
      borderBottomRightRadius: 4,
      border: `1px solid ${theme.palette.divider}`,
    },
  }),
);

const TablePaginationActions = (props: TablePaginationActionsProps) => {
  const classes = useStyles();
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div className={classes.root}>
      <IconButton
        className={classes.firstPageButton}
        size="small"
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <LastPageRounded /> : <FirstPageRounded />}
      </IconButton>
      <IconButton
        className={classes.previousPageButton}
        size="small"
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowRightRounded /> : <KeyboardArrowLeftRounded />}
      </IconButton>
      <IconButton
        className={classes.nextPageButton}
        size="small"
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeftRounded /> : <KeyboardArrowRightRounded />}
      </IconButton>
      <IconButton
        className={classes.lastPageButton}
        size="small"
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPageRounded /> : <LastPageRounded />}
      </IconButton>
    </div>
  );
};

export default TablePaginationActions;
