import React, { ReactNode, useState, useMemo } from 'react';
import { Tabs } from '@material-ui/core';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { createStyles, makeStyles } from '@material-ui/core/styles';

import { InferPropTypes, NonNullFields } from '../../types';
import { white } from '../../../abstracts/colours';

const navigationPropTypes = {
  value: PropTypes.shape({
    currentTab: PropTypes.number.isRequired,
    currentDropdownItem: PropTypes.number,
  }).isRequired,
  onChange: PropTypes.func,
  children: PropTypes.node,
  dataTestId: PropTypes.string,
};

const defaultProps = {
  value: {
    currentTab: 0,
  },
};

export type NavigationHorizontalProps = NonNullFields<InferPropTypes<typeof navigationPropTypes, typeof defaultProps>>;

const useStyles = makeStyles(() =>
  createStyles({
    indicator: {
      backgroundColor: white,
    },
    flexContainer: {
      height: 56,
    },
  }),
);

const NavigationHorizontal: React.FC<NavigationHorizontalProps> = ({
  value: currentNavItem,
  onChange,
  children,
  dataTestId,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const classes = useStyles();

  const isSelectedHovered: boolean = useMemo(
    () => anchorEl?.tabIndex === currentNavItem.currentTab,

    [anchorEl, currentNavItem],
  );
  const handleChange = (newValue: { currentTab: number; currentDropdownItem?: number }): void => onChange?.(newValue);
  const handleMouseEnter = (e: React.MouseEvent<HTMLElement>): void => setAnchorEl?.(e.currentTarget);
  const handleMouseLeave = (): void => setAnchorEl?.(null);

  return (
    <Tabs
      data-testid={dataTestId}
      classes={{ flexContainer: classes.flexContainer }}
      value={currentNavItem.currentTab}
      onChange={(e, index): void => handleChange({ currentTab: index })}
      onMouseLeave={handleMouseLeave}
      indicatorColor="primary"
      TabIndicatorProps={{
        className: clsx(isSelectedHovered && classes.indicator, 'MuiTabs-indicator'),
      }}
      className={'navigation-tabs'}
    >
      {React.Children.map<ReactNode, ReactNode>(children, (child, index) => {
        if (React.isValidElement(child)) {
          return React.cloneElement(child, {
            tabIndex: index,
            currentNavItem,
            anchorEl,
            handleChange,
            handleMouseEnter,
            handleMouseLeave,
            dataTestId,
          });
        }
      })}
    </Tabs>
  );
};

NavigationHorizontal.propTypes = navigationPropTypes;
NavigationHorizontal.defaultProps = defaultProps;

export default React.memo(NavigationHorizontal);
