import React, { FC } from 'react';

const CheckCircleOutline: FC<{ className?: string }> = ({ className }) => (
  <svg className={className} width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="18" cy="18" r="17" stroke="#0074BD" strokeWidth="2" />
    <path
      d="M15.0006 22.1699L11.5306 18.6999C11.1406 18.3099 10.5106 18.3099 10.1206 18.6999C9.73063 19.0899 9.73063 19.7199 10.1206 20.1099L14.3006 24.2899C14.6906 24.6799 15.3206 24.6799 15.7106 24.2899L26.2906 13.7099C26.6806 13.3199 26.6806 12.6899 26.2906 12.2999C25.9006 11.9099 25.2706 11.9099 24.8806 12.2999L15.0006 22.1699Z"
      fill="#0074BD"
    />
  </svg>
);

export default CheckCircleOutline;
