import React, { ReactElement } from 'react';
import { Icon as MuiIcon, IconProps as MuiIconProps } from '@material-ui/core';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { InferPropTypes, NonNullFields, Size } from '../../types';
import { useIconStyles } from './styles';

const iconPropTypes = {
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  color: PropTypes.string,
  name: PropTypes.string,
  className: PropTypes.string,
};

const defaultProps = {
  size: 'medium',
};

export type IconProps = NonNullFields<InferPropTypes<typeof iconPropTypes, typeof defaultProps>> &
  Omit<MuiIconProps, 'size' | 'color'>;

const Icon: React.FC<IconProps> = ({ size, color, children, className, ...rest }): ReactElement => {
  const classes = useIconStyles({ color });
  return (
    <MuiIcon className={clsx(classes[size as Size], classes.withColor, className)} {...rest}>
      {children}
    </MuiIcon>
  );
};

Icon.propTypes = iconPropTypes;
Icon.defaultProps = defaultProps;

export default Icon;
