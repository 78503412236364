import React from 'react';

const MoveTo: React.FC<{ className?: string }> = ({ className }) => (
  <svg
    width="24px"
    height="24px"
    viewBox="0 0 24 24"
    fill="none"
    version="1.1"
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 6H20C21.11 6 22 6.89 22 8V18C22 19.11 21.11 20 20 20H4C2.89 20 2 19.11 2 18L2.01 6C2.01 4.89 2.89 4 4 4H10L12 6ZM4 6V18H20V8H11.17L9.17 6H4Z"
    />
    <path fillRule="evenodd" clipRule="evenodd" d="M19 13L13 8.5V11H9V15H13V17.5L19 13Z" fill="#212121" />
  </svg>
);

export default MoveTo;
