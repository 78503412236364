import React, { FC } from 'react';

const ErrorOutline: FC<{ className?: string }> = ({ className }) => (
  <svg
    fill="transparent"
    className={className}
    width="36"
    height="36"
    viewBox="0 0 36 36"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1 18C1 8.61498 8.59959 1 17.982 1C27.3844 1 35 8.61694 35 18C35 27.3831 27.3844 35 17.982 35C8.59959 35 1 27.385 1 18Z"
      stroke="#E22B24"
      strokeWidth="2"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18 8C19.1 8 20 8.9 20 10V18C20 19.1 19.1 20 18 20C16.9 20 16 19.1 16 18V10C16 8.9 16.9 8 18 8Z"
      fill="#E22B24"
    />
    <path fillRule="evenodd" clipRule="evenodd" fill="#E22B24" d="M20 28H16V24H20V28Z" />
  </svg>
);

export default ErrorOutline;
