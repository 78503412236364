import React from 'react';
import PropTypes from 'prop-types';
import { TabProps as MuiTabProps } from '@material-ui/core';

import { InferPropTypes, NonNullFields } from '../types';
import NavigationItemHorizontal from './Horizontal/NavigationItemHorizontal';
import NavigationItemVertical from './Vertical/NavigationItemVertical';

const navigationTabPropTypes = {
  vertical: PropTypes.bool,
  label: PropTypes.string.isRequired,
  children: PropTypes.node,
  tabIndex: PropTypes.number,
  currentNavItem: PropTypes.shape({
    currentTab: PropTypes.number.isRequired,
    currentDropdownItem: PropTypes.number,
  }).isRequired,
  anchorEl: PropTypes.instanceOf(HTMLElement),
  handleChange: PropTypes.func,
  handleMouseEnter: PropTypes.func,
  handleMouseLeave: PropTypes.func,
  dataTestId: PropTypes.string,
};

const defaultProps = {
  label: '',
  currentNavItem: {
    currentTab: 0,
  },
};

export type NavigationItemProps = NonNullFields<InferPropTypes<typeof navigationTabPropTypes>> & MuiTabProps;

const NavigationItem: React.FC<NavigationItemProps> = ({ vertical, icon, ...rest }) =>
  vertical ? <NavigationItemVertical icon={icon} {...rest} /> : <NavigationItemHorizontal {...rest} />;

NavigationItem.propTypes = navigationTabPropTypes;
NavigationItem.defaultProps = defaultProps;

export default NavigationItem;
