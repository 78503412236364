import React from 'react';

const Document: React.FC<{ className?: string }> = ({ className }) => (
  <svg
    width="36px"
    height="36px"
    viewBox="0 0 36 36"
    version="1.1"
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.1716 3C20.702 3 21.2107 3.21071 21.5858 3.58579L29.4142 11.4142C29.7893 11.7893 30 12.298 30 12.8284V30C30 31.65 28.65 33 27 33H8.985C7.335 33 6 31.65 6 30V6C6 4.35 7.35 3 9 3H20.1716ZM13.5 18C12.6716 18 12 18.6716 12 19.5C12 20.3284 12.6716 21 13.5 21H22.5C23.3284 21 24 20.3284 24 19.5C24 18.6716 23.3284 18 22.5 18H13.5ZM12 25.5C12 24.6716 12.6716 24 13.5 24H22.5C23.3284 24 24 24.6716 24 25.5C24 26.3284 23.3284 27 22.5 27H13.5C12.6716 27 12 26.3284 12 25.5ZM21 4.75V10.5C21 11.325 21.675 12 22.5 12H28.25L21 4.75Z"
    />
  </svg>
);

export default Document;
