import React, { FC, ReactNode } from 'react';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

interface TabLabelProps {
  label: string;
  subtitle?: string;
  icon?: ReactNode;
  data?: string;
}

const styles = makeStyles({
  labelContainer: { display: 'flex', flexDirection: 'row' },
  typographyContainer: { alignItems: 'flex-start', textAlign: 'left' },
  typographyLeft: { float: 'left' },
  typographyRight: { float: 'right' },
  subtitleTypography: { fontWeight: 400 },
});

const TabLabel: FC<TabLabelProps> = ({ label, subtitle, icon, data }) => {
  const { labelContainer, typographyContainer, typographyLeft, typographyRight, subtitleTypography } = styles();
  return (
    <div className={labelContainer}>
      {icon}
      <div className={typographyContainer}>
        <Typography className={typographyLeft} color="inherit" variant="subtitle2">
          {label}
        </Typography>
        <label className={typographyRight}>{data}</label>
        {subtitle && (
          <Typography className={subtitleTypography} color="inherit" variant="subtitle2">
            {subtitle}
          </Typography>
        )}
      </div>
    </div>
  );
};

export default TabLabel;
