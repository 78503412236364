import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

import { Theme } from '../Theme/theme';

export interface DrawerActionsProps {
  dataTestId?: string;
  className?: string;
}

const useStyles = makeStyles<Theme>((theme) =>
  createStyles({
    drawerActions: {
      position: 'fixed',
      bottom: 0,
      width: 'inherit',
      display: 'grid',
      gridTemplateColumns: '1fr 1fr',
      gridGap: 16,
      backgroundColor: theme.palette.background.paper,
      borderTop: `1px solid ${theme.palette.divider}`,
      boxShadow: `0px -4px 6px ${theme.palette.elevation}0A`,
      padding: 16,
    },
  }),
);

const DrawerActions: React.FC<DrawerActionsProps> = ({ children, className, dataTestId }) => {
  const classes = useStyles();
  return (
    <div id="drawer-actions" className={clsx(className, classes.drawerActions)} data-testid={dataTestId}>
      {children}
    </div>
  );
};

export default DrawerActions;
