import React from 'react';
import { Accordion as MuiAccordion, AccordionProps } from '@material-ui/core';
import clsx from 'clsx';

const Accordion: React.FC<AccordionProps> = ({ children, className, square, ...props }) => (
  <MuiAccordion {...props} className={clsx(className, square && 'square')}>
    {children}
  </MuiAccordion>
);

export default Accordion;
