import React, { FC } from 'react';
import Typography from '@material-ui/core/Typography';
import Box, { BoxProps } from '@material-ui/core/Box';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme, createStyles, makeStyles } from '@material-ui/core/styles';
import { ExpandMore } from '@material-ui/icons';
import { AccordionSummary, Accordion, AccordionDetails } from '../Accordion';
import { Theme } from '../Theme/theme';

export interface FooterItemProps extends BoxProps {
  title: string;
  /**
   * Breakpoint when to switch to vertical footer
   */
  breakpoint?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
}

const styles = makeStyles<Theme>((theme) =>
  createStyles({
    footerItem: {
      display: 'flex',
      flexDirection: 'column',
      borderRight: `1px solid ${theme.palette.divider}`,
      marginRight: 24,
    },
    wrapper: {
      marginRight: 24,
    },
    accordionDetails: {
      flexDirection: 'column',
    },
  }),
);

const FooterItem: FC<FooterItemProps> = ({
  children,
  title,
  width = '20%',
  minWidth = 160,
  maxWidth = 180,
  breakpoint = 'sm',
  ...rest
}) => {
  const theme = useTheme();
  const { footerItem, wrapper, accordionDetails } = styles();
  const returnDropdown = useMediaQuery(theme.breakpoints.down(breakpoint));

  const titleTypography = (
    <Typography gutterBottom={!returnDropdown} variant="subtitle1">
      {title}
    </Typography>
  );
  return !returnDropdown ? (
    <Box width={width} maxWidth={maxWidth} minWidth={minWidth} className={footerItem} {...rest}>
      <div className={wrapper}>
        {titleTypography}
        {children}
      </div>
    </Box>
  ) : (
    <Accordion className="footerAccordion">
      <AccordionSummary expandIcon={<ExpandMore />}>{titleTypography}</AccordionSummary>
      <AccordionDetails className={accordionDetails}>{children}</AccordionDetails>
    </Accordion>
  );
};

export default FooterItem;
