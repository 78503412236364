import React from 'react';

const Inventory: React.FC<{ className?: string }> = ({ className }) => (
  <svg
    width="36px"
    height="36px"
    viewBox="0 0 36 36"
    version="1.1"
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M30 3H6C4.5 3 3 4.35 3 6V10.515C3 11.595 3.645 12.525 4.5 13.05V30C4.5 31.65 6.15 33 7.5 33H28.5C29.85 33 31.5 31.65 31.5 30V13.05C32.355 12.525 33 11.595 33 10.515V6C33 4.35 31.5 3 30 3ZM21 21H15C14.175 21 13.5 20.325 13.5 19.5C13.5 18.675 14.175 18 15 18H21C21.825 18 22.5 18.675 22.5 19.5C22.5 20.325 21.825 21 21 21ZM6 10.5H30V6H6V10.5Z"
    />
  </svg>
);

export default Inventory;
