import React, { useState } from 'react';
import { AccordionSummary as MuiAccordionSummary, AccordionSummaryProps } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import clsx from 'clsx';

import { Button } from '../../';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    actionLabel: {
      height: 56,
      '& > .MuiAccordionSummary-content': {
        margin: '7px 0',
        display: 'flex',
        '& > h6': {
          display: 'inherit',
          alignItems: 'center',
        },
      },
    },
    actionHovered: {
      '&:hover': {
        backgroundColor: theme.palette.background.paper,
      },
    },
  }),
);

const AccordionSummary: React.FC<AccordionSummaryProps & { onActionClick?: () => void; actionLabel?: string }> = ({
  children,
  onActionClick,
  actionLabel,
  className,
  ...props
}) => {
  const [isActionHovered, setIsActionHovered] = useState(false);
  const classes = useStyles();

  return (
    <MuiAccordionSummary
      {...props}
      className={clsx(className, actionLabel && classes.actionLabel, isActionHovered && classes.actionHovered)}
    >
      {children}
      {actionLabel && (
        <div className="summary-action">
          <Button
            variant="text"
            size="large"
            color="primary"
            onClick={(event): void => {
              event.stopPropagation();
              onActionClick?.();
            }}
            onFocus={(event): void => event.stopPropagation()}
            onMouseEnter={(): void => setIsActionHovered(true)}
            onMouseLeave={(): void => setIsActionHovered(false)}
          >
            {actionLabel}
          </Button>
        </div>
      )}
    </MuiAccordionSummary>
  );
};

export default AccordionSummary;
