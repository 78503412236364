import { LinearProgressClassKey } from '@material-ui/core/';
import { CSSProperties } from '@material-ui/core/styles/withStyles';
import { Theme } from '../../Theme/theme';

export const linearProgressTheme = (theme: Theme): Partial<Record<LinearProgressClassKey, CSSProperties>> => ({
  colorSecondary: {
    backgroundColor: theme.palette.neutral.light,
  },
  colorPrimary: {
    backgroundColor: theme.palette.neutral.light,
  },
});
