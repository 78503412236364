import React from 'react';

const Keynote: React.FC<{ className?: string }> = ({ className }) => (
  <svg
    width="36px"
    height="36px"
    viewBox="0 0 36 36"
    version="1.1"
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.1716 3C20.702 3 21.2107 3.21071 21.5858 3.58579L29.4142 11.4142C29.7893 11.7893 30 12.298 30 12.8284V30C30 31.65 28.65 33 27 33H8.985C7.335 33 6 31.65 6 30V6C6 4.35 7.35 3 9 3H20.1716ZM21 4.75V10.5C21 11.325 21.675 12 22.5 12H28.25L21 4.75Z"
    />
    <path
      d="M8.799 24V16.176H10.563V19.38H10.611L12.939 16.176H14.883L12.519 19.296L15.315 24H13.383L11.475 20.7L10.563 21.9V24H8.799ZM15.6701 24V16.176H20.5661V17.664H17.4341V19.236H20.0981V20.712H17.4341V22.512H20.6861V24H15.6701ZM23.0883 24V21.216L20.7243 16.176H22.6203L23.3163 17.976C23.4283 18.272 23.5323 18.56 23.6283 18.84C23.7323 19.112 23.8403 19.4 23.9523 19.704H24.0003C24.1123 19.4 24.2203 19.112 24.3243 18.84C24.4363 18.56 24.5483 18.272 24.6603 17.976L25.3683 16.176H27.2163L24.8523 21.216V24H23.0883Z"
      fill="white"
    />
  </svg>
);

export default Keynote;
