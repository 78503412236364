import React from 'react';
import { Toolbar as MuiToolbar, ToolbarProps as MuiToolbarProps } from '@material-ui/core';
import PropTypes from 'prop-types';

import { InferPropTypes, NonNullFields } from '../types';

const toolbarPropTypes = {
  dataTestId: PropTypes.string,
};

export type ToolbarProps = NonNullFields<InferPropTypes<typeof toolbarPropTypes>> & MuiToolbarProps;

const Toolbar: React.FC<ToolbarProps> = ({ children, dataTestId, ...rest }) => (
  <MuiToolbar data-testid={dataTestId} {...rest}>
    {children}
  </MuiToolbar>
);

Toolbar.propTypes = toolbarPropTypes;

export default Toolbar;
