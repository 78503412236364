import React from 'react';

const Video: React.FC<{ className?: string }> = ({ className }) => (
  <svg
    width="36px"
    height="36px"
    viewBox="0 0 36 36"
    version="1.1"
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6 6H30C31.65 6 33 7.35 33 9V27C33 28.65 31.65 30 30 30H6C4.35 30 3 28.65 3 27V9C3 7.35 4.35 6 6 6ZM14 23.18V12.82C14 12.03 14.87 11.55 15.54 11.98L23.68 17.15C24.3 17.55 24.3 18.45 23.68 18.84L15.54 24.02C14.87 24.45 14 23.97 14 23.18Z"
    />
  </svg>
);

export default Video;
