import React, { FC, Children } from 'react';
import { makeStyles } from '@material-ui/core/styles';

const styles = makeStyles({
  logoWrapper: {
    margin: 12,
  },
});

const BrandingContainer: FC = ({ children }) => {
  const { logoWrapper } = styles();
  return (
    <div className="branding-container">
      {Children.map(children, (child) => (
        <div className={logoWrapper}>{child}</div>
      ))}
    </div>
  );
};
export default BrandingContainer;
