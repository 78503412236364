import React from 'react';
import { MenuItem as MuiMenuItem, MenuItemProps as MuiMenuItemProps } from '@material-ui/core';

export interface MenuItemProps extends Omit<MuiMenuItemProps, 'button'> {
  button?: true;
}

const MenuItem: React.FC<MenuItemProps> = React.forwardRef((props, ref) => <MuiMenuItem {...props} ref={ref} />);

export default MenuItem;
