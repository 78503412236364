import React, { ChangeEvent, FC, useState, useMemo, useCallback } from 'react';
import { Box, FormControl, Divider, FormHelperText, useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import clsx from 'clsx';
import PropTypes from 'prop-types';

import { allCountries, Dropdown, Country } from '../../..';
import { ValidationType, ValidationPropType, NonNullFields, InferPropTypes } from '../../types';
import { Theme } from '../../Theme/theme';
import TextField from '../TextField/TextField';
import { InputLabel } from '../InputLabel';
import { DropdownItem } from '../Dropdown';

function countryToFlag(isoCode: string): React.ReactElement {
  if (typeof String.fromCodePoint === 'undefined') {
    return <span>{isoCode}</span>;
  }
  return <>{isoCode.toUpperCase().replace(/./g, (char) => String.fromCodePoint(char.charCodeAt(0) + 127397))}</>;
}

const phoneInputPropTypes = {
  label: PropTypes.string,
  id: PropTypes.string,
  optional: PropTypes.bool,
  hintText: PropTypes.string,
  helperText: PropTypes.string,
  validationType: PropTypes.oneOf(ValidationPropType),
  onChange: PropTypes.func,
  value: PropTypes.string,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  dataTestId: PropTypes.string,
  required: PropTypes.bool,
  countries: PropTypes.array,
  onDropdownChange: PropTypes.func,
};

export type PhoneInputProps = NonNullFields<InferPropTypes<typeof phoneInputPropTypes>> & {
  style?: React.CSSProperties;
};

const PhoneInput: FC<PhoneInputProps> = ({
  label,
  optional,
  hintText,
  disabled,
  helperText,
  validationType,
  onChange,
  value,
  className,
  dataTestId,
  required,
  countries,
  onDropdownChange,
  id,
  style,
}) => {
  const theme: Theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));

  const renderFlag = (optionValue: unknown): React.ReactNode =>
    optionValue ? (
      <Box data-testid={dataTestId && `${dataTestId}-flag`} fontSize="30px">
        {countryToFlag(optionValue as string)}
      </Box>
    ) : (
      <Box border={`1px solid ${theme.palette.neutral.mid}`} width="30px" height="18px"></Box>
    );

  const [selectedCountry, setSelectedCountry] = useState<{ code: string; enteredNumber?: string }>({
    code: '',
    enteredNumber: '',
  });

  const groupedCountries = useMemo(
    () => ({
      suggested: ((countries as Country[]) || allCountries).filter((c) => c.suggested),
      otherCountries: (countries as Country[]) || allCountries,
    }),
    [countries],
  );

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>): void => {
      if (onChange) {
        return onChange(e);
      }
      setSelectedCountry({
        ...selectedCountry,
        enteredNumber: (e.target.value || '').slice(selectedCountry.code.length),
      });
    },
    [onChange, selectedCountry],
  );

  const handleDropdownChange = useCallback(
    (e: ChangeEvent<{ value: unknown }>): void => {
      if (onDropdownChange) {
        return onDropdownChange(e);
      }
      const countryCode = allCountries.find((x) => x.abbr === e.target.value)?.code || '';
      setSelectedCountry({ code: countryCode, enteredNumber: '' });
    },
    [onDropdownChange],
  );

  const renderCountryCodeDropdown = (): React.ReactNode =>
    isMobile ? (
      <Dropdown
        dataTestId={dataTestId && `${dataTestId}-dropdown`}
        className={clsx('phone-input-dropdown', className && `${className}-dropdown`)}
        onChange={handleDropdownChange}
        validationType={validationType as ValidationType}
        disabled={disabled}
        defaultValue={undefined}
      >
        <option selected value=" " label=" ">
          Select an option
        </option>
        {allCountries.map((country) => (
          <option value={country.abbr} label={country.abbr} key={country.abbr}>
            {country.name}
          </option>
        ))}
      </Dropdown>
    ) : (
      <Dropdown
        dataTestId={dataTestId && `${dataTestId}-dropdown`}
        className={clsx('phone-input-dropdown', className && `${className}-dropdown`)}
        onChange={handleDropdownChange}
        renderValue={renderFlag}
        validationType={validationType as ValidationType}
        disabled={disabled}
      >
        {(groupedCountries.suggested || []).map((option) => (
          <DropdownItem
            key={option.abbr}
            data-testid={dataTestId && `${dataTestId}-suggested-option-${option.abbr}`}
            value={option.abbr}
            className="phone-input-menu-item"
          >
            <span>
              {option.name} ({option.code})
            </span>
            <Box marginRight="8px" fontSize="30px">
              {countryToFlag(option.abbr)}
            </Box>
          </DropdownItem>
        ))}
        {groupedCountries.suggested && groupedCountries.suggested.length && <Divider />}
        {(groupedCountries.otherCountries || []).map((option) => (
          <DropdownItem
            key={option.abbr}
            value={option.abbr}
            data-testid={dataTestId && `${dataTestId}-other-option-${option.abbr}`}
            className="phone-input-menu-item"
          >
            <span>
              {option.name} ({option.code})
            </span>
            <Box marginRight="8px" fontSize="30px">
              {countryToFlag(option.abbr)}
            </Box>
          </DropdownItem>
        ))}
      </Dropdown>
    );

  return (
    <FormControl
      style={style}
      required={required}
      data-testid={dataTestId}
      className={clsx('phone-input', className)}
      fullWidth
      error={!!(validationType as ValidationType)}
    >
      {label && (
        <InputLabel
          htmlFor={id}
          label={label}
          optional={optional}
          hintText={hintText}
          disabled={disabled}
          required={required}
        />
      )}
      <Box display="inline-flex">
        {renderCountryCodeDropdown()}
        <TextField
          dataTestId={dataTestId && `${dataTestId}-textfield`}
          className={clsx('phone-input-textfield', className && `${className}-textfield`)}
          disabled={disabled}
          value={value || selectedCountry.code + selectedCountry.enteredNumber}
          onChange={handleChange}
          validationType={validationType as ValidationType}
          type="text"
        />
      </Box>
      {helperText && validationType && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
};

PhoneInput.propTypes = phoneInputPropTypes;

export default PhoneInput;
