import React from 'react';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import moment from 'moment';

import { Size } from '../../types';

const defaultProps = {
  size: 'medium',
};

const useStyles = makeStyles({
  small: {
    marginTop: -2,
    '& > .month': {
      fontWeight: 600,
      fontSize: 8,
      lineHeight: '8px',
    },
    ' & > .day': {
      fontWeight: 700,
      fontSize: 16,
      lineHeight: '12px',
    },
  },
  medium: {
    '& > .month': {
      fontWeight: 600,
      fontSize: 12,
      lineHeight: '8px',
    },
    ' & > .day': {
      fontWeight: 700,
      fontSize: 24,
    },
  },
  large: {
    marginTop: -2,
    '& > .month': {
      fontWeight: 600,
      fontSize: 16,
      lineHeight: '14px',
    },
    ' & > .day': {
      fontWeight: 700,
      fontSize: 32,
      lineHeight: '24px',
    },
  },
});

const DynamicEventIcon: React.FC<{ size?: string }> = ({ size }) => {
  const classes = useStyles();
  return (
    <Typography component={'span'} className={classes[size as Size]}>
      <div className="month">{moment().format('MMM').toUpperCase()}</div>
      <div className="day">{moment().format('DD')}</div>
    </Typography>
  );
};

DynamicEventIcon.defaultProps = defaultProps;

export default DynamicEventIcon;
