import React from 'react';
import { Typography, Switch, useMediaQuery } from '@material-ui/core';
import { createStyles, makeStyles, useTheme } from '@material-ui/core/styles';
import { ExpandMoreRounded, CheckCircleOutlineRounded } from '@material-ui/icons';

import { Accordion, AccordionSummary, AccordionDetails, Icon } from '../..';
import { Theme } from '../Theme/theme';

export interface CookieSwitchProps {
  dataTestId?: string;
  alwaysActive?: boolean;
  title?: string;
  explanation?: string;
  onChange?: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    accordion: {
      borderRadius: '0px 0px 6px 6px !important',
      boxShadow: 'none',
    },
    accordionHeader: {
      display: 'flex',
      justifyContent: 'space-between',
      padding: '18px 16px',
      minHeight: 56,
      borderTopLeftRadius: 4,
      borderTopRightRadius: 4,
      border: `1px solid ${theme.palette.divider}`,
      borderBottom: 0,
      backgroundColor: theme.palette.background.paper,
    },
    activeStatus: {
      display: 'flex',
      alignItems: 'center',
      '& > .MuiIcon-root': {
        '& > svg': {
          fill: '#0076BD',
        },
      },
      '& > *': {
        color: '#0076BD',
      },
    },
    activeText: {
      marginLeft: 6,
    },
  }),
);

const AccordionHeader: React.FC = ({ children }) => {
  const classes = useStyles();
  return <div className={classes.accordionHeader}>{children}</div>;
};

const ActiveStatus: React.FC = () => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  return (
    <div className={classes.activeStatus}>
      <Icon size="small">
        <CheckCircleOutlineRounded />
      </Icon>
      {!isMobile && (
        <Typography variant="subtitle2" className={classes.activeText}>
          Always active
        </Typography>
      )}
    </div>
  );
};

const CookieSwitch: React.FC<CookieSwitchProps> = ({ dataTestId, title, explanation, alwaysActive, onChange }) => {
  const classes = useStyles();
  return (
    <div data-testid={dataTestId}>
      <AccordionHeader>
        <Typography variant="subtitle1">{title}</Typography>
        {alwaysActive ? <ActiveStatus /> : <Switch onChange={onChange} color="primary" />}
      </AccordionHeader>
      <Accordion className={classes.accordion}>
        <AccordionSummary expandIcon={<ExpandMoreRounded />}>
          <Typography variant="subtitle2">What are these?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography variant="body1" color="textSecondary">
            {explanation}
          </Typography>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default CookieSwitch;
