import { DialogContentClassKey, DialogActionsClassKey } from '@material-ui/core';
import { CSSProperties } from '@material-ui/core/styles/withStyles';

export const modalContentTheme: Partial<Record<DialogContentClassKey, CSSProperties>> = {
  root: {
    borderTop: 'none',
    padding: '0px 16px 24px 16px',
    fontSize: 16,
    overflowY: 'auto',
  },
};

export const modalActionsTheme: Partial<Record<DialogActionsClassKey, CSSProperties>> = {
  root: {
    padding: 16,
  },
  spacing: {
    '& > *:not(:first-child)': {
      marginLeft: 16,
    },
  },
};
