import React, { FC } from 'react';

const CheckboxIcon: FC<{ id?: string }> = ({ id }) => (
  <svg
    width="24px"
    height="24px"
    viewBox="0 0 24 24"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g
      id={`SelectionControls/Checkbox/Deselected/Enabled-${id}`}
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g id={`TextField/Box/Default-${id}`} fill="#FFFFFF" stroke="#9E9E9E">
        <path
          d="M20,0.5 C20.9664983,0.5 21.8414983,0.891750844 22.4748737,1.52512627 C23.1082492,2.15850169 23.5,3.03350169 23.5,4 L23.5,4 L23.5,20 C23.5,20.9664983 23.1082492,21.8414983 22.4748737,22.4748737 C21.8414983,23.1082492 20.9664983,23.5 20,23.5 L20,23.5 L4,23.5 C3.03350169,23.5 2.15850169,23.1082492 1.52512627,22.4748737 C0.891750844,21.8414983 0.5,20.9664983 0.5,20 L0.5,20 L0.5,4 C0.5,3.03350169 0.891750844,2.15850169 1.52512627,1.52512627 C2.15850169,0.891750844 3.03350169,0.5 4,0.5 L4,0.5 Z"
          id={`Rectangle-${id}`}
        ></path>
      </g>
    </g>
  </svg>
);

export default CheckboxIcon;
