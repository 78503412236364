import React, { useState, ReactNode } from 'react';
import { List, ListItem, ListItemText, ListItemIcon, Collapse } from '@material-ui/core';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import PropTypes from 'prop-types';

import { InferPropTypes, NonNullFields } from '../../types';
import { monochromeLight, monochromeLightest, white } from '../../../abstracts/colours';

const navigationItemVerticalProps = {
  label: PropTypes.string.isRequired,
  icon: PropTypes.node,
  children: PropTypes.node,
  tabIndex: PropTypes.number,
  currentNavItem: PropTypes.shape({
    currentTab: PropTypes.number.isRequired,
    currentDropdownItem: PropTypes.number,
  }).isRequired,
  handleChange: PropTypes.func,
  dataTestId: PropTypes.string,
};

const defaultProps = {
  label: '',
  currentNavItem: {
    currentTab: 0,
  },
};

export type NavigationVerticalProps = NonNullFields<
  InferPropTypes<typeof navigationItemVerticalProps, typeof defaultProps>
>;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    listItem: {
      backgroundColor: white,
      height: 56,
      width: 256,
      '&:hover': {
        backgroundColor: monochromeLightest,
      },
      '&:active': {
        backgroundColor: monochromeLight,
      },
      '& > div': {
        '& > span': {
          fontWeight: '600',
        },
        '&.MuiListItemIcon-root': {
          minWidth: 40,
        },
      },
      '&.Mui-selected': {
        backgroundColor: white,
        borderLeftWidth: 5,
        borderColor: theme.palette.primary.main,
        borderStyle: 'solid',
        paddingLeft: 11,
        '& > div': {
          '& > span': {
            color: theme.palette.primary.main,
          },
          '& > svg': {
            color: theme.palette.primary.main,
          },
        },
        '& > svg': {
          color: theme.palette.primary.main,
        },
      },
    },
  }),
);

const NavigationItemVerticalDefault: React.FC<NavigationVerticalProps> = ({
  currentNavItem,
  tabIndex,
  handleChange,
  label,
  icon,
  dataTestId,
}) => {
  const classes = useStyles();
  return (
    <ListItem
      data-testid={`${dataTestId}-menu-item-${tabIndex}`}
      className={classes.listItem}
      button
      key={tabIndex}
      selected={currentNavItem.currentTab === tabIndex}
      onClick={(): void => handleChange?.({ currentTab: tabIndex })}
    >
      {icon && <ListItemIcon>{icon}</ListItemIcon>}
      <ListItemText primary={label} />
    </ListItem>
  );
};

const NavigationItemVerticalWithSubItems: React.FC<NavigationVerticalProps> = ({
  currentNavItem,
  tabIndex,
  handleChange,
  label,
  children,
  icon,
  dataTestId,
}) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  return (
    <>
      <ListItem
        data-testid={`${dataTestId}-menu-item-${tabIndex}`}
        className={classes.listItem}
        button
        key={tabIndex}
        selected={currentNavItem.currentTab === tabIndex}
        onClick={(): void => {
          if (handleChange) {
            handleChange({ currentTab: tabIndex });
            setOpen((prevState) => !prevState);
          }
        }}
      >
        {icon && <ListItemIcon>{icon}</ListItemIcon>}
        <ListItemText primary={label} />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {React.Children.map<ReactNode, ReactNode>(children, (child, index) => {
            if (React.isValidElement(child)) {
              return React.cloneElement(child, {
                index,
                currentNavItem,
                tabIndex,
                handleChange,
                vertical: true,
                dataTestId,
              });
            }
          })}
        </List>
      </Collapse>
    </>
  );
};

const NavigationItemVertical: React.FC<NavigationVerticalProps> = ({ children, ...rest }) =>
  children ? (
    <NavigationItemVerticalWithSubItems {...rest}>{children}</NavigationItemVerticalWithSubItems>
  ) : (
    <NavigationItemVerticalDefault {...rest} />
  );

NavigationItemVertical.propTypes = navigationItemVerticalProps;
NavigationItemVertical.defaultProps = defaultProps;

export default React.memo(NavigationItemVertical);
