import React, { FC } from 'react';
import { Tab as MuiTab, TabProps as MuiTabProps } from '@material-ui/core';
import clsx from 'clsx';

export interface TabProps extends MuiTabProps {
  card?: boolean;
}

const Tab: FC<TabProps> = ({ card, className, ...rest }) => (
  <MuiTab disableRipple className={clsx(card && 'card-tab', className)} {...rest} />
);

export default Tab;
