import { sourceSansPro } from '../../../abstracts/constants';
import { Theme } from '../../Theme/theme';

export const pickersCalendarHeaderTheme = (theme: Theme) => ({
  switchHeader: {
    borderRadius: 4,
    border: `1px solid ${theme.palette.divider}`,
    height: 36,
    margin: 16,
    overflow: 'hidden',
  },
  iconButton: {
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: 0,
    height: 36,
    borderRightWidth: 0,
    '&:first-child': {
      borderLeftWidth: 0,
      borderRightWidth: 1,
    },
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
    },
    '&:active': {
      backgroundColor: theme.palette.action.active,
    },
  },
  dayLabel: {
    color: theme.palette.text.secondary,
    fontSize: 14,
    fontWeight: 600,
    fontFamily: sourceSansPro,
  },
});

export const popoverTheme = (theme: Theme) => ({
  paper: {
    border: `1px solid ${theme.palette.neutral.mid}`,
    borderRadius: 4,
    boxShadow: `0 4px 6px 0 ${theme.palette.elevation}1F`,
  },
});

export const pickersDayTheme = (theme: Theme) => ({
  current: {
    border: `1px solid ${theme.palette.text.secondary}`,
    color: theme.palette.text.secondary,
  },
  day: {
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
    },
    '&:active': {
      backgroundColor: theme.palette.action.active,
    },
  },
  daySelected: {
    borderWidth: 0,
  },
});
