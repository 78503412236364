import { PaletteOptions as MuiPaletteOptions, TypeText } from '@material-ui/core/styles/createPalette';

import {
  monochromeDark,
  black,
  corporateBlue,
  backgroundGrey,
  corporateBlueDark,
  negative,
  positive,
  positiveDark,
  negativeDark,
  warning,
  warningDark,
  informative,
  informativeDark,
  corporateBlueLight,
  corporateBlueDarkest,
  positiveLight,
  negativeLight,
  warningLight,
  informativeLight,
  white,
  corporateBlueLightest,
  positiveLightest,
  positiveDarkest,
  negativeLightest,
  negativeDarkest,
  warningLightest,
  warningDarkest,
  negativeDarkTransparent8,
  informativeLightest,
  informativeDarkest,
  corporateBlueMid,
  informativeMid,
  warningMid,
  negativeMid,
  positiveMid,
  corporateBlueDarkTransparent8,
  monochromeLightest,
  monochromeLight,
  corporateBlueTransparent30,
  monochromeDarkest,
  monochromeDarkestTransparent30,
  monochromeMid,
} from '../../abstracts/colours';

export interface CustomPaletteOptions {
  elevation: string;
  neutral: {
    darkest: string;
    dark: string;
    mid: string;
    light: string;
    lightest: string;
  };
  text: TypeText & {
    contrast: string;
  };
}
export interface PaletteOptions extends Omit<MuiPaletteOptions, 'text'>, CustomPaletteOptions {}

export const basePalette: PaletteOptions = {
  action: {
    hover: monochromeLightest,
    active: monochromeLight,
    focus: corporateBlueTransparent30,
  },
  divider: monochromeLight,
  elevation: black,
  text: {
    primary: monochromeDarkest,
    hint: monochromeDarkest,
    secondary: monochromeDark,
    disabled: monochromeDarkestTransparent30,
    contrast: white,
  },
  primary: {
    main: corporateBlue,
    dark: corporateBlueDark,
    light: corporateBlueLight,
    50: corporateBlueDarkTransparent8,
    100: corporateBlueLightest,
    400: corporateBlueMid,
    900: corporateBlueDarkest,
  },
  neutral: {
    lightest: monochromeLightest,
    light: monochromeLight,
    mid: monochromeMid,
    dark: monochromeDark,
    darkest: monochromeDarkest,
  },
  success: {
    main: positive,
    dark: positiveDark,
    light: positiveLight,
    100: positiveLightest,
    400: positiveMid,
    900: positiveDarkest,
  },
  error: {
    main: negative,
    dark: negativeDark,
    light: negativeLight,
    50: negativeDarkTransparent8,
    100: negativeLightest,
    400: negativeMid,
    900: negativeDarkest,
  },
  warning: {
    main: warning,
    dark: warningDark,
    light: warningLight,
    100: warningLightest,
    400: warningMid,
    900: warningDarkest,
  },
  info: {
    main: informative,
    dark: informativeDark,
    light: informativeLight,
    100: informativeLightest,
    400: informativeMid,
    900: informativeDarkest,
  },
  background: {
    default: backgroundGrey,
    paper: white,
  },
};
