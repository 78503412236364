import React, { FC } from 'react';

const Error: FC<{ className?: string }> = ({ className }) => (
  <svg className={className} width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.982 0C8.046 0 0 8.064 0 18C0 27.936 8.046 36 17.982 36C27.936 36 36 27.936 36 18C36 8.064 27.936 0 17.982 0ZM18 8C19.1 8 20 8.9 20 10V18C20 19.1 19.1 20 18 20C16.9 20 16 19.1 16 18V10C16 8.9 16.9 8 18 8ZM16 24V28H20V24H16Z"
      fill="#E22B24"
    />
  </svg>
);

export default Error;
