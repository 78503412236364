import { darken, lighten } from '@material-ui/core/styles';
import { ColorPartial, PaletteColor } from '@material-ui/core/styles/createPalette';

export const corporateBlue = '#0074BD';
export const corporateBlueTransparent30 = `${corporateBlue}4D`;
export const corporateBlueDarkest = '#004B7B';
export const corporateBlueDark = '#0064A4';
export const corporateBlueMid = '#338FCA';
export const corporateBlueLight = '#66ABD7';
export const corporateBlueLightest = '#99C7E4';
export const corporateBlueDarkTransparent8 = `${corporateBlueDark}14`;

export const monochromeDarkest = '#212121';
export const monochromeDarkestTransparent30 = `${monochromeDarkest}4D`;
export const monochromeDark = '#424242';
export const monochromeMid = '#9E9E9E';
export const monochromeLight = '#E0E0E0';
export const monochromeLightest = '#F3F3F3';

export const white = '#FFFFFF';
export const black = '#000000';
export const backgroundGrey = '#F7F7F7';

export const negative = '#D9221C';
export const negativeDarkest = '#821410';
export const negativeDark = '#AD1B16';
export const negativeMid = '#E25954';
export const negativeLight = '#F7D2D1';
export const negativeLightest = '#FFF0ED';
export const negativeDarkTransparent8 = '#AD1B1614';

export const informative = '#0274B6';
export const informativeDarkest = '#01456D';
export const informativeDark = '#015C91';
export const informativeMid = '#4196C8';
export const informativeLight = '#CCE3F0';
export const informativeLightest = '#E8F5FC';

export const warning = '#AD5F00';
export const warningDarkest = '#673900';
export const warningDark = '#8A4C00';
export const warningMid = '#C18740';
export const warningLight = '#EEDFCC';
export const warningLightest = '#FFFAF0';

export const positive = '#2B8000';
export const positiveDarkest = '#194C00';
export const positiveDark = '#226600';
export const positiveMid = '#609F40';
export const positiveLight = '#D4E5CC';
export const positiveLightest = '#F4F8F1';

export const pdfRed = '#DD1D01';
export const wordBlue = '#2B579A';
export const excelGreen = '#217346';
export const powerPointRed = '#C03A1B';

export const orange = '#FF5427';
export const orangeDarkest = '#993217';
export const orangeDark = '#CC431F';
export const orangeMid = '#FF7E5D';
export const orangeLight = '#FFC3B3';
export const orangeLightest = '#FFE5DE';

export const purple = '#AE20AE';
export const purpleDarkest = '#681368';
export const purpleDark = '#8B198B';
export const purpleMid = '#C257C2';
export const purpleLight = '#E2B1E2';
export const purpleLightest = '#F2DDF2';

export const blue = '#37C5F7';
export const blueDarkest = '#217694';
export const blueDark = '#2C9DC5';
export const blueMid = '#69D3F9';
export const blueLight = '#B9EAFC';
export const blueLightest = '#E1F6FD';

export const green = '#1CD164';
export const greenDarkest = '#107D3C';
export const greenDark = '#16A750';
export const greenMid = '#54DC8A';
export const greenLight = '#AFEEC8';
export const greenLightest = '#DDF8E7';

export const getDarkest = (colorPallete: PaletteColor): string =>
  (colorPallete as ColorPartial)[900] || darken(colorPallete.main, 0.4);
export const getMedium = (colorPallete: PaletteColor): string =>
  (colorPallete as ColorPartial)[400] || colorPallete.main;
export const getLightest = (colorPallete: PaletteColor): string =>
  (colorPallete as ColorPartial)[100] || lighten(colorPallete.main, 0.4);
export const getSuperLightest = (colorPallete: PaletteColor): string =>
  (colorPallete as ColorPartial)[50] || lighten(colorPallete.main, 0.92);

export default {
  corporateBlue,
  corporateBlueDarkest,
  corporateBlueDark,
  corporateBlueDarkTransparent8,
  corporateBlueMid,
  corporateBlueLight,
  corporateBlueLightest,
  monochromeDarkest,
  monochromeDark,
  monochromeMid,
  monochromeLight,
  monochromeLightest,
  white,
  backgroundGrey,
  negative,
  negativeDarkest,
  negativeDark,
  negativeMid,
  negativeLight,
  negativeLightest,
  informative,
  informativeDarkest,
  informativeDark,
  informativeMid,
  informativeLight,
  informativeLightest,
  warning,
  warningDarkest,
  warningDark,
  warningMid,
  warningLight,
  warningLightest,
  positive,
  positiveDarkest,
  positiveDark,
  positiveMid,
  positiveLight,
  positiveLightest,
  pdfRed,
  wordBlue,
  excelGreen,
  powerPointRed,
  orange,
  orangeDarkest,
  orangeDark,
  orangeMid,
  orangeLight,
  orangeLightest,
  purple,
  purpleDarkest,
  purpleDark,
  purpleMid,
  purpleLight,
  purpleLightest,
  blue,
  blueDarkest,
  blueDark,
  blueMid,
  blueLight,
  blueLightest,
  green,
  greenDarkest,
  greenDark,
  greenMid,
  greenLight,
  greenLightest,
};
