import React from 'react';
import { Grid, Slider as MuiSlider, SliderProps as MuiSliderProps, InputBase, Typography } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';

export interface SliderProps extends Omit<MuiSliderProps, 'onChange'> {
  value: number | number[];
  onChange: (value: number | number[]) => void;
  iconLeft?: React.ReactNode;
  iconRight?: React.ReactNode;
  numeric?: boolean;
  title?: string;
  dataTestId?: string;
}

const useStyles = makeStyles(() =>
  createStyles({
    title: {
      fontWeight: 600,
    },
    grid: {
      padding: 0,
      '& > .MuiGrid-item': {
        '& > svg': {
          paddingTop: 4,
        },
      },
    },
    inputContainer: {
      width: 32,
      height: 32,
      marginTop: -2,
      '& > input': {
        padding: 0,
        textAlign: 'center',
        '-moz-appearance': 'textfield',
        '&::-webkit-outer-spin-button': {
          '-webkit-appearance': 'none',
          margin: 0,
        },
        '&::-webkit-inner-spin-button': {
          '-webkit-appearance': 'none',
          margin: 0,
        },
      },
    },
  }),
);

const handleBlur = (value: number | number[], onChange: (number: number | number[]) => void): void => {
  if (value < 0) {
    onChange(0);
  } else if (value > 100) {
    onChange(100);
  }
};

const SliderInput: React.FC<{
  dataTestId?: string;
  value: number | number[];
  onChange: (value: number | number[]) => void;
}> = ({ value, onChange, dataTestId }) => {
  const classes = useStyles();
  return (
    <InputBase
      className={classes.inputContainer}
      value={Number(value).toString()}
      onChange={(e): void => onChange(Number(e.target.value))}
      onBlur={(): void => handleBlur(value, onChange)}
      data-testid={`${dataTestId}-input`}
      inputProps={{
        type: 'number',
        min: 0,
        max: 100,
        'aria-label': 'input-slider',
      }}
    />
  );
};

const RightContent: React.FC<SliderProps & { icon: React.ReactNode }> = ({
  icon,
  numeric,
  value,
  onChange,
  dataTestId,
}) => {
  if (numeric) {
    return <SliderInput value={value} onChange={onChange} dataTestId={`${dataTestId}-right-content`} />;
  }
  return <>{icon}</>;
};

const Slider: React.FC<SliderProps> = ({
  value,
  onChange,
  iconLeft,
  iconRight,
  numeric,
  title,
  dataTestId,
  ...rest
}) => {
  const classes = useStyles();
  return (
    <div>
      <Typography className={classes.title} gutterBottom>
        {title}
      </Typography>
      <Grid className={classes.grid} container spacing={2}>
        <Grid item>{iconLeft}</Grid>
        <Grid item>
          <MuiSlider
            data-testid={dataTestId}
            value={value}
            onChange={(e, newValue: number | number[]): void => onChange(newValue)}
            getAriaLabel={(index): string => `slider-${index}`}
            {...rest}
          />
        </Grid>
        <Grid item>
          <RightContent icon={iconRight} numeric={numeric} value={value} onChange={onChange} dataTestId={dataTestId} />
        </Grid>
      </Grid>
    </div>
  );
};

export default Slider;
