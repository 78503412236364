import React from 'react';
import { AppBar, Toolbar, AppBarProps, ToolbarProps, Container } from '@material-ui/core';

export interface ProductHeaderProps extends AppBarProps {
  toolbarProps?: ToolbarProps;
  maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false;
}

export const ProductHeader: React.FC<ProductHeaderProps> = ({ children, maxWidth, toolbarProps, ...rest }) => (
  <AppBar position="static" {...rest}>
    <Container disableGutters maxWidth={maxWidth}>
      <Toolbar disableGutters {...toolbarProps}>
        {children}
      </Toolbar>
    </Container>
  </AppBar>
);

ProductHeader.defaultProps = {
  maxWidth: false,
};

export default ProductHeader;
