import { StepClasskey, StepLabelClasskey, StepConnectorClasskey } from '@material-ui/core';
import { CSSProperties } from '@material-ui/core/styles/withStyles';
import { Theme } from '../Theme/theme';

export const stepTheme: Partial<Record<StepClasskey, CSSProperties>> = {
  root: {
    '&.clickable': {
      '&:hover': {
        cursor: 'pointer',
      },
    },
  },
};

export const stepLabelTheme: Partial<Record<StepLabelClasskey, CSSProperties>> = {
  label: {
    '&$alternativeLabel': {
      textAlign: 'center',
      marginTop: 4,
    },
  },
  labelContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
};

export const stepConnectorTheme = (theme: Theme): Partial<Record<StepConnectorClasskey, CSSProperties>> => ({
  lineHorizontal: {
    borderTopWidth: 2,
    borderRadius: 2,
  },
  line: {
    borderColor: theme.palette.divider,
  },
  alternativeLabel: {
    top: 18,
  },
});
