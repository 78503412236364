import React, { FC } from 'react';
import { Chip as MuiChip, ChipProps as MuiChipProps, Typography, IconButton } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/styles';
import Close from '@material-ui/icons/CloseRounded';
import clsx from 'clsx';

import { Theme } from '../Theme/theme';

export interface ChipProps extends Omit<MuiChipProps, 'label' | 'size' | 'clickable'> {
  selected?: boolean;
  size?: 'small' | 'large';
  label: string;
  dataTestId?: string;
  truncate?: boolean;
}

export const useStyles = makeStyles<Theme>((theme) =>
  createStyles({
    typography: {
      fontWeight: 600,
      padding: 0,
    },
    truncatedTypography: {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    selectedFont: {
      color: theme.palette.background.paper,
    },
    iconButton: {
      padding: 4,
    },
    fullWidth: { maxWidth: '100%' },
  }),
);

const Chip: FC<ChipProps> = ({
  onClick,
  selected = false,
  size = 'large',
  dataTestId,
  className,
  label,
  truncate,
  ...rest
}) => {
  const { typography, selectedFont, iconButton, truncatedTypography, fullWidth } = useStyles();
  return (
    <MuiChip
      className={clsx(className, selected && 'selected', size, onClick && 'clickable', truncate && fullWidth)}
      onClick={onClick}
      label={
        <Typography
          data-testid={dataTestId && `${dataTestId}-label`}
          className={clsx(typography, selected && selectedFont, truncate && truncatedTypography)}
          variant="body2"
        >
          {label}
        </Typography>
      }
      deleteIcon={
        <IconButton data-testid={dataTestId && `${dataTestId}-deleteButton`} className={iconButton}>
          <Close fontSize={'inherit'} />
        </IconButton>
      }
      {...rest}
      clickable={false}
      data-testid={dataTestId}
    />
  );
};

export default Chip;
