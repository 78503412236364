import React from 'react';

const ZipFile: React.FC<{ className?: string }> = ({ className }) => (
  <svg
    width="36px"
    height="36px"
    viewBox="0 0 36 36"
    version="1.1"
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.1716 3C20.702 3 21.2107 3.21071 21.5858 3.58579L29.4142 11.4142C29.7893 11.7893 30 12.298 30 12.8284V30C30 31.65 28.65 33 27 33H8.985C7.335 33 6 31.65 6 30V6C6 4.35 7.35 3 9 3H20.1716ZM21 4.75V10.5C21 11.325 21.675 12 22.5 12H28.25L21 4.75Z"
    />
    <path
      d="M10.807 24V22.932L14.263 17.664H11.131V16.176H16.435V17.244L12.979 22.512H16.471V24H10.807ZM17.2912 24V16.176H19.0552V24H17.2912ZM20.4006 24V16.176H23.0886C23.5206 16.176 23.9246 16.22 24.3006 16.308C24.6766 16.388 25.0046 16.528 25.2846 16.728C25.5646 16.92 25.7846 17.18 25.9446 17.508C26.1126 17.828 26.1966 18.224 26.1966 18.696C26.1966 19.152 26.1126 19.548 25.9446 19.884C25.7846 20.22 25.5646 20.496 25.2846 20.712C25.0046 20.928 24.6806 21.088 24.3126 21.192C23.9446 21.296 23.5526 21.348 23.1366 21.348H22.1646V24H20.4006ZM22.1646 19.944H23.0286C23.9886 19.944 24.4686 19.528 24.4686 18.696C24.4686 18.288 24.3406 18 24.0846 17.832C23.8286 17.664 23.4606 17.58 22.9806 17.58H22.1646V19.944Z"
      fill="white"
    />
    <rect x="12" y="4" width="4" height="6" rx="2" fill="white" />
    <rect x="13" y="7" width="2" height="2" rx="1" fill="#424242" />
    <rect x="13" y="11" width="2" height="1" rx="0.5" fill="white" />
    <rect x="13" y="13" width="2" height="1" rx="0.5" fill="white" />
    <rect x="13" y="26" width="2" height="1" rx="0.5" fill="white" />
    <rect x="13" y="28" width="2" height="1" rx="0.5" fill="white" />
    <rect x="13" y="30" width="2" height="1" rx="0.5" fill="white" />
  </svg>
);

export default ZipFile;
