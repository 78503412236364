import { CSSProperties } from '@material-ui/core/styles/withStyles';
import { Theme } from './theme';
import { sourceSansPro } from '../../abstracts/constants';
import {
  InputBaseClassKey,
  InputClassKey,
  FormHelperTextClassKey,
  InputLabelProps,
  TextFieldProps,
  InputLabelClassKey,
  FormLabelClassKey,
  InputAdornmentClassKey,
  ButtonBaseProps,
} from '@material-ui/core';

const inputBaseTheme = (theme: Theme): Partial<Record<InputBaseClassKey, CSSProperties>> => ({
  root: {
    marginBottom: '8px',
    fontFamily: sourceSansPro,
    marginTop: 0,
    border: `1px solid ${theme.palette.neutral.mid}`,
    borderRadius: '4px',
    backgroundColor: theme.palette.background.paper,
    '&.select-input': {
      borderColor: theme.palette.neutral.mid,
      '&:hover': {
        backgroundColor: theme.palette.action.hover,
        borderColor: theme.palette.neutral.mid,
      },
      '&$focused': {
        backgroundColor: theme.palette.action.active,
        borderColor: theme.palette.neutral.mid,
      },
      '&.error': {
        borderColor: theme.palette.error.main,
      },
      '&.warning': {
        borderColor: theme.palette.warning.main,
      },
    },
    '&$focused': {
      borderColor: theme.palette.text.primary,
      '& > .MuiInputAdornment-root': {
        borderColor: theme.palette.text.primary,
      },
    },
    '&:hover': {
      borderColor: theme.palette.text.primary,
      '& > .MuiInputAdornment-root': {
        borderColor: theme.palette.text.primary,
      },
    },
    '&$disabled': {
      opacity: 0.3,
      color: theme.palette.text.primary,
      '& > .MuiInputAdornment-root': {
        borderColor: theme.palette.text.primary,
        opacity: 0.3,
      },
    },
    '&$disabled:hover': {
      opacity: 0.3,
      borderColor: theme.palette.neutral.mid,
      '&.select-input': {
        opacity: 0.3,
        borderColor: theme.palette.neutral.mid,
        backgroundColor: theme.palette.background.paper,
      },
    },
    '&.helper-error': {
      borderColor: theme.palette.error.main,
      '& > .MuiInputAdornment-root': {
        borderColor: theme.palette.error.main,
      },
      '&:hover': {
        '& > .MuiInputAdornment-root': {
          borderColor: theme.palette.error.main,
        },
      },
      '&$focused': {
        '& > .MuiInputAdornment-root': {
          borderColor: theme.palette.error.main,
        },
      },
    },
    '&.helper-warning': {
      borderColor: theme.palette.warning.main,
      '& > .MuiInputAdornment-root': {
        borderColor: theme.palette.warning.main,
      },
      '&:hover': {
        '& > .MuiInputAdornment-root': {
          borderColor: theme.palette.warning.main,
        },
      },
      '&$focused': {
        '& > .MuiInputAdornment-root': {
          borderColor: theme.palette.warning.main,
        },
      },
    },
    '&.helper-success': {
      borderColor: theme.palette.success.main,
      '& > .MuiInputAdornment-root': {
        borderColor: theme.palette.success.main,
      },
      '&:hover': {
        '& > .MuiInputAdornment-root': {
          borderColor: theme.palette.success.main,
        },
      },
      '&$focused': {
        '& > .MuiInputAdornment-root': {
          borderColor: theme.palette.success.main,
        },
      },
    },
  },
  input: {
    padding: '12px 16px',
    height: 18,
    borderRadius: 4,
  },
  multiline: {
    height: 'auto',
    padding: 0,
    '& > .MuiSvgIcon-root': {
      position: 'absolute',
      top: '12px',
      right: 0,
    },
  },
  inputMultiline: {
    minHeight: '20px',
    padding: '12px 6px 16px 16px',
    resize: 'vertical',
    '&$inputAdornedEnd': {
      paddingRight: '40px',
    },
  },
  adornedEnd: {
    '& > .MuiSvgIcon-root': {
      lineHeight: '20px',
      fontSize: '20px',
      marginRight: 18,
      '&.helper-error': {
        color: theme.palette.error.main,
      },
      '&.helper-warning': {
        color: theme.palette.warning.main,
      },
      '&.helper-success': {
        color: theme.palette.success.main,
      },
      '&.password-visibility': {
        cursor: 'pointer',
      },
    },
  },
});

const inputTheme = (theme: Theme): Partial<Record<InputClassKey, CSSProperties>> => ({
  input: {
    backgroundColor: theme.palette.background.paper,
    fontFamily: sourceSansPro,
    fontSize: '16px',
    lineHeight: '20px',
    color: theme.palette.text.primary,
  },
  formControl: {
    'label + &': {
      marginTop: 0,
    },
  },
  underline: {
    '&:before': {
      borderBottom: 'none',
    },
  },
});

const formHelperText = (theme: Theme): Partial<Record<FormHelperTextClassKey, CSSProperties>> => ({
  root: {
    marginTop: 0,
    fontSize: 14,
    color: theme.palette.error.main,
    '&.helper-warning': {
      color: theme.palette.warning.main,
    },
  },
});

const inputLabelTheme = (theme: Theme): Partial<Record<InputLabelClassKey, CSSProperties>> => ({
  formControl: {
    position: 'static',
    transform: 'none',
    color: theme.palette.text.primary,
    fontFamily: sourceSansPro,
    fontWeight: 600,
    marginBottom: 8,
    lineHeight: '20px',
    '& > .textfield-label': {
      '& > .optional-flag': {
        fontFamily: sourceSansPro,
        fontWeight: 400,
        fontSize: 16,
        lineHeight: '20px',
      },
      '& > div': {
        fontWeight: 400,
        fontSize: 16,
        lineHeight: '20px',
        color: theme.palette.text.secondary,
      },
      '& > .asterisk': {
        color: theme.palette.error.main,
      },
    },
  },
});

const formLabelTheme = (theme: Theme): Partial<Record<FormLabelClassKey, CSSProperties>> => ({
  root: {
    '&$focused': {
      color: theme.palette.text.primary,
    },
    '&$disabled': {
      color: theme.palette.text.primary,
      opacity: 0.3,
    },
    '&$error': {
      color: theme.palette.text.primary,
    },
  },
});

const inputAdornmentTheme = (theme: Theme): Partial<Record<InputAdornmentClassKey, CSSProperties>> => ({
  root: {
    height: '100%',
    borderWidth: 0,
    borderLeftWidth: 1,
    maxHeight: '100%',
    border: `1px solid ${theme.palette.neutral.mid}`,
    borderTopRightRadius: 4,
    borderBottomRightRadius: 4,
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
    },
    '&:active': {
      backgroundColor: theme.palette.action.active,
    },
    '& > .MuiIconButton-root': {
      '&:hover': {
        backgroundColor: 'transparent',
      },
      opacity: 1,
      color: theme.palette.text.primary,
    },
  },
});

const textFieldDefaultProps: Partial<TextFieldProps> = {
  InputProps: {
    disableUnderline: true,
  },
};

const inputLabelDefaultProps: Partial<InputLabelProps> = {
  disableAnimation: true,
  shrink: false,
};

const buttonBaseDefaultProps: Partial<ButtonBaseProps> = {
  disableTouchRipple: true,
};

export {
  inputBaseTheme,
  inputTheme,
  textFieldDefaultProps,
  formHelperText,
  inputLabelDefaultProps,
  inputLabelTheme,
  formLabelTheme,
  inputAdornmentTheme,
  buttonBaseDefaultProps,
};
