import React, { FC } from 'react';
import { ListSubheader, ListSubheaderProps, Typography } from '@material-ui/core';

interface DropdownGroupProps extends ListSubheaderProps {
  isMobile?: boolean;
  label: string;
}

const DropdownGroup: FC<DropdownGroupProps> = React.forwardRef((props, ref) => {
  const { isMobile, label, children, ...rest } = props;
  return isMobile ? (
    <optgroup label={label}>{children}</optgroup>
  ) : (
    <>
      <ListSubheader ref={ref} {...rest}>
        <Typography variant="subtitle1">{label}</Typography>
      </ListSubheader>
      {children}
    </>
  );
});

export default DropdownGroup;
