import React from 'react';

const Reminder: React.FC<{ className?: string }> = ({ className }) => (
  <svg
    width="36px"
    height="36px"
    viewBox="0 0 36 36"
    version="1.1"
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.32047 4.57498L3.07547 8.81998C2.49047 9.40498 2.49047 10.35 3.07547 10.935C3.66047 11.52 4.60547 11.52 5.19047 10.935L9.43547 6.68998C10.0205 6.10498 10.0205 5.15998 9.43547 4.57498C8.85047 3.98998 7.90547 3.98998 7.32047 4.57498ZM4.50047 19.5C4.50047 12.045 10.5455 5.99998 18.0005 5.99998C25.4555 5.99998 31.5005 12.045 31.5005 19.5C31.5005 26.955 25.4555 33 18.0005 33C10.5455 33 4.50047 26.955 4.50047 19.5ZM21.1355 24.75C21.7205 25.335 22.6655 25.335 23.2505 24.75C23.8355 24.165 23.8355 23.22 23.2505 22.635L19.5005 18.885V13.5C19.5005 12.675 18.8255 12 18.0005 12C17.1755 12 16.5005 12.675 16.5005 13.5V19.5C16.5005 19.905 16.6655 20.28 16.9505 20.565L21.1355 24.75ZM26.5655 6.68998C25.9805 6.10498 25.9805 5.15998 26.5655 4.57498C27.1505 3.98998 28.0955 3.98998 28.6805 4.57498L32.9255 8.81998C33.5105 9.40498 33.5105 10.35 32.9255 10.935C32.3405 11.52 31.3955 11.52 30.8105 10.935L26.5655 6.68998Z"
    />
  </svg>
);

export default Reminder;
