import React, { FC } from 'react';
import {
  CircularProgress as MuiCircularProgress,
  CircularProgressProps as MuiCircularProgressProps,
} from '@material-ui/core/';

export interface CircularProgressProps extends MuiCircularProgressProps {
  dataTestId?: string;
}

const CircularProgress: FC<CircularProgressProps> = ({ dataTestId, ...props }) => (
  <MuiCircularProgress size={36} thickness={4} aria-label="progress-bar" data-testid={dataTestId} {...props} />
);

export default CircularProgress;
