import React from 'react';
import { Typography, useMediaQuery } from '@material-ui/core';
import { makeStyles, createStyles, useTheme } from '@material-ui/core/styles';
import clsx from 'clsx';

import { Theme } from '../Theme/theme';
import { Link, Button } from '../../';

export interface CookieBarProps {
  dataTestId?: string;
  onPrimaryClick?: () => void;
  onSecondaryClick?: () => void;
  link?: string;
}

export const useStyles = makeStyles<Theme>((theme) =>
  createStyles({
    root: {
      alignItems: 'center',
      textAlign: 'center',
      position: 'absolute',
      width: '100%',
      padding: 16,
      bottom: 0,
      backgroundColor: theme.palette.background.paper,
      boxShadow: `0px -8px 8px ${theme.palette.elevation}0A`,
    },
    gridColumn: {
      display: 'grid',
      gridAutoFlow: 'column',
      gridColumnGap: 16,
      justifyContent: 'center',
    },
    gridRow: {
      display: 'grid',
      gridAutoFlow: 'row',
      gridRowGap: 16,
      justifyContent: 'center',
    },
    button: {
      width: 152,
    },
  }),
);

const CookieBar: React.FC<CookieBarProps> = ({ dataTestId, link, onPrimaryClick, onSecondaryClick, ...rest }) => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <div
      className={clsx(classes.root, isMobile ? classes.gridRow : classes.gridColumn)}
      data-testid={dataTestId}
      {...rest}
    >
      <Typography>
        We use cookies. By continuing to browse this website, you&apos;re agreeing to our use of cookies.{' '}
        <Link href={link}>Read our cookie policy</Link>
      </Typography>
      <div className={classes.gridColumn}>
        <Button size="small" variant="outlined" className={classes.button} onClick={onSecondaryClick}>
          Change settings
        </Button>
        <Button size="small" className={classes.button} onClick={onPrimaryClick}>
          Accept
        </Button>
      </div>
    </div>
  );
};

export default CookieBar;
