import { RadioClassKey } from '@material-ui/core';
import { CSSProperties } from '@material-ui/core/styles/withStyles';
import { iconActionStyle } from '../Checkbox/checkboxTheme';
import { Theme } from '../../Theme/theme';

export const radioTheme = (theme: Theme): Partial<Record<RadioClassKey, CSSProperties>> => ({
  root: {
    '&.MuiIconButton-root': iconActionStyle(theme),
  },
});
