import { AppBarClassKey, ToolbarClassKey } from '@material-ui/core/';
import { CSSProperties } from '@material-ui/core/styles/withStyles';
import { Theme } from '../Theme/theme';

export const appBarTheme = (theme: Theme): Partial<Record<AppBarClassKey, CSSProperties>> => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    marginTop: 0,
    height: 80,
    justifyContent: 'center',
    boxShadow: `0 2px 4px 0 ${theme.palette.elevation}0A`,
    [theme.breakpoints.down('sm')]: {
      height: 64,
    },
    [theme.breakpoints.down('xs')]: {
      height: 56,
    },
  },
  colorPrimary: {
    backgroundColor: theme.palette.background.paper,
  },
});

export const toolbarTheme = (theme: Theme): Partial<Record<ToolbarClassKey, CSSProperties>> => ({
  root: {
    paddingLeft: 24,
    paddingRight: 24,
    backgroundColor: theme.palette.background.paper,
    '& .logo': {
      height: '32px',
      '& svg': {
        height: '100%',
        width: 'auto',
      },
    },
    '& .title-divider': { height: 80, margin: '0px 24px' },
    '&.table-header': {
      borderWidth: '1px 1px 0px 1px',
      borderStyle: 'solid',
      borderColor: theme.palette.divider,
      borderTopLeftRadius: 6,
      borderTopRightRadius: 6,
      padding: '0px 16px',
      height: 57,
      minHeight: 0,
      '& > .MuiTypography-root': {
        flex: '1 1 100%',
        fontWeight: 'normal',
        fontSize: 16,
      },
      '& > .MuiIconButton-root': {
        '&:not(:last-child)': {
          marginRight: 8,
        },
      },
    },
  },
});
