import React, { FC } from 'react';
import { StepLabel as MuiStepLabel, StepLabelProps as MuiStepLabelProps, Typography } from '@material-ui/core';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import clsx from 'clsx';

import StepIcon from './StepIcon';

const useStyles = makeStyles<Theme>((theme) =>
  createStyles({
    errorSelected: {
      color: theme.palette.error.main,
    },
    selected: {
      color: theme.palette.primary.main,
    },
    optionalTypography: {
      fontWeight: 400,
      textAlign: 'center',
    },
  }),
);

interface StepLabelProps extends MuiStepLabelProps {
  active?: boolean;
  clickable?: boolean;
}

const StepLabel: FC<StepLabelProps> = ({ optional, error, active, clickable, children, ...props }) => {
  const { errorSelected, selected, optionalTypography } = useStyles();
  const activeClass = error ? errorSelected : selected;

  return (
    <MuiStepLabel
      error={error}
      optional={
        <Typography className={clsx(active && activeClass, optionalTypography)} color="inherit" variant="subtitle2">
          {optional}
        </Typography>
      }
      StepIconComponent={(iconProps) => <StepIcon {...iconProps} clickable={clickable} active={active} />}
      {...props}
    >
      <Typography className={clsx(active && activeClass)} variant="subtitle2">
        {children}
      </Typography>
    </MuiStepLabel>
  );
};

export default StepLabel;
