import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Box, Typography } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';

import { InferPropTypes, NonNullFields } from '../types';

const titlePropTypes = {
  introduction: PropTypes.string,
  children: PropTypes.string.isRequired,
  className: PropTypes.string,
};

export type TitleProps = NonNullFields<InferPropTypes<typeof titlePropTypes>>;

const useStyles = makeStyles(() =>
  createStyles({
    title: {
      '& > p': {
        marginBottom: 16,
      },
      '& > h5': {
        marginBottom: (props: TitleProps): number => (props.introduction ? 8 : 0),
      },
    },
  }),
);

const Title: React.FC<TitleProps> = ({ className, introduction, children }) => {
  const classes = useStyles({ introduction } as TitleProps);
  return (
    <Box className={clsx(classes.title, className || '')}>
      <Typography className={className && `${className}-h5`} variant="h5" gutterBottom={!introduction}>
        {children}
      </Typography>
      {introduction && (
        <Typography className={className && `${className}-introduction`} variant="body1">
          {introduction}
        </Typography>
      )}
    </Box>
  );
};

Title.propTypes = titlePropTypes;

export default Title;
