import React, { FC } from 'react';
import MenuIcon from '@material-ui/icons/Menu';
import { IconButton, IconButtonProps as MuiIconButtonProps, SvgIconProps } from '@material-ui/core';
import clsx from 'clsx';

export interface PrimaryNavigationButtonProps extends MuiIconButtonProps {
  iconProps?: SvgIconProps;
}

const PrimaryNavigationButton: FC<PrimaryNavigationButtonProps> = ({ onClick, className, iconProps, ...rest }) => (
  <IconButton className={clsx('hamburger', className)} onClick={onClick} {...rest}>
    <MenuIcon {...iconProps} />
  </IconButton>
);
export default PrimaryNavigationButton;
