import React from 'react';
import { ErrorOutlineRounded, InfoOutlined, CheckCircleOutlineRounded } from '@material-ui/icons';

import Icon, { IconProps } from '../components/Iconography/IconButtons/Icon';
import { Warning } from '../icons';
import { NotificationType } from '../components/types';

export const SystemIcon: React.FC<{ variant: NotificationType; dataTestId?: string } & IconProps> = ({
  variant,
  dataTestId,
  ...rest
}) => {
  switch (variant) {
    case 'error':
      return (
        <Icon data-testid={`${dataTestId}-error`} {...rest}>
          <ErrorOutlineRounded />
        </Icon>
      );
    case 'warning':
      return (
        <Icon data-testid={`${dataTestId}-warning`} {...rest}>
          <Warning />
        </Icon>
      );
    case 'info':
      return (
        <Icon data-testid={`${dataTestId}-info`} {...rest}>
          <InfoOutlined />
        </Icon>
      );
    case 'success':
      return (
        <Icon data-testid={`${dataTestId}-success`} {...rest}>
          <CheckCircleOutlineRounded />
        </Icon>
      );
  }
};
