import React, { useRef } from 'react';
import { Menu, Hidden, Avatar, IconButton, Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { InferPropTypes, NonNullFields } from '../../types';
import FallbackAvatar from '../../../icons/FallbackAvatar';
import ConditionalWrap from '../../../utils/ConditionalWrap';

export const userPropTypes = {
  onClick: PropTypes.func,
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired,
  user: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    role: PropTypes.string,
    imageSrc: PropTypes.string,
  }).isRequired,
};

export const defaultProps = {
  open: false,
  user: {
    id: '',
    name: '',
    role: '',
    imageSrc: '',
  },
};

const useStyles = makeStyles(() => ({
  userMenu: () => ({
    '& .avatar-button': {
      padding: 0,
      '&:hover': {
        '& img': {
          filter: 'brightness(0.9)',
        },
        '& .fallback-avatar': {
          filter: 'brightness(0.9)',
        },
      },
    },
    '&.isOpen': {
      '& .avatar-button': {
        filter: 'brightness(0.8)',
      },
    },
  }),
  box: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
}));

export type UserMenuProps = NonNullFields<InferPropTypes<typeof userPropTypes, typeof defaultProps>>;

const UserMenu: React.FC<UserMenuProps> = ({
  user: { id, name, role, imageSrc },
  children,
  open,
  onClose,
  onClick,
}) => {
  const buttonEl = useRef<HTMLButtonElement | null>(null);
  const { userMenu, box } = useStyles();
  return (
    <Box data-userid={id} display="flex" alignItems="center" className={`${userMenu} ${open ? 'isOpen' : ''}`}>
      <ConditionalWrap
        condition={React.Children.count(children) > 0}
        wrap={(kids): JSX.Element => (
          <IconButton
            ref={buttonEl}
            className={'avatar-button'}
            data-testid="avatar-button"
            aria-controls="user-menu"
            aria-haspopup="true"
            onClick={onClick}
          >
            {kids}
          </IconButton>
        )}
      >
        <Avatar alt={name} src={imageSrc || undefined}>
          <Box className="av-wrap">
            <FallbackAvatar />
          </Box>
        </Avatar>
      </ConditionalWrap>
      {React.Children.count(children) > 0 && (
        <Menu
          data-testid="user-menu"
          getContentAnchorEl={null}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
          id="user-menu"
          anchorEl={buttonEl.current}
          keepMounted
          open={!!buttonEl.current && open}
          onClose={onClose}
        >
          {children}
        </Menu>
      )}
      <Hidden smDown>
        <Box className={box} onClick={onClick} ml={1}>
          <Typography variant="subtitle1">{name}</Typography>
          <Typography variant="body1" color="textSecondary">
            {role}
          </Typography>
        </Box>
      </Hidden>
    </Box>
  );
};

UserMenu.propTypes = userPropTypes;
UserMenu.defaultProps = defaultProps;

export default UserMenu;
