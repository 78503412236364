import React, { useState } from 'react';
import {
  InputAdornment,
  ClickAwayListener,
  StandardTextFieldProps as MuiStandardTextFieldProps,
  Popper,
  MenuList,
  MenuItem,
  Paper,
  FormControl,
  useMediaQuery,
} from '@material-ui/core';
import { createStyles, makeStyles, useTheme } from '@material-ui/core/styles';
import { QueryBuilderRounded } from '@material-ui/icons';
import moment from 'moment';
import clsx from 'clsx';

import { Theme, TextField, IconButton } from '../../../';
import { getDarkest } from '../../../abstracts/colours';

export interface TimePickerProps extends Omit<MuiStandardTextFieldProps, 'label' | 'onChange'> {
  dataTestId?: string;
  label?: string;
  step?: number;
  onChange?: (value: string) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    input: {
      width: 164,
      '& > .MuiInput-root': {
        '& > input': {
          padding: '11px 18px',
          lineHeight: '16px',
          backgroundColor: 'transparent',
          '&::-webkit-calendar-picker-indicator': {
            display: 'none',
          },
        },
      },
    },
    inputIcon: {
      width: 56,
    },
    paper: {
      height: 314,
      marginTop: 4,
      border: `1px solid ${theme.palette.neutral.mid}`,
      borderRadius: 4,
    },
    menuContainer: {
      display: 'flex',
    },
    menu: {
      height: 280,
      overflow: 'auto',
      boxShadow: 'none',
      borderRadius: 0,
      marginTop: 0,
      '& > ul': {
        paddingTop: 0,
        paddingBottom: 0,
        '&::-webkit-scrollbar': {
          '-webkit-appearance': 'none',
        },
      },
    },
    menuHours: {
      WebkitBorderBottomLeftRadius: '4px',
    },
    menuMinutes: {
      WebkitBorderBottomRightRadius: '4px',
    },
    menuHeaderContainer: {
      display: 'flex',
      justifyContent: 'center',
      textAlign: 'center',
      alignItems: 'center',
      height: 33,
      borderBottom: `1px solid ${theme.palette.divider}`,
    },
    menuHeader: {
      width: 80,
      fontWeight: 600,
    },
    menuItem: {
      padding: '8px 19px !important',
      margin: '12px 14px !important',
      textAlign: 'center',
      borderRadius: 4,
      '& > span': {
        width: 20,
      },
      '&.Mui-selected': {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.text.contrast,
        '&:hover, &:focus': {
          backgroundColor: theme.palette.primary.dark,
        },
        '&:active': {
          backgroundColor: getDarkest(theme.palette.primary),
        },
      },
    },
  }),
);

const TimePicker: React.FC<TimePickerProps> = ({
  className,
  label,
  value,
  step,
  defaultValue,
  onChange,
  dataTestId,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const minutes = Array.from(Array(60).keys());
  const minutesWithStep = step && minutes.filter((item, index) => index % step === 0);

  return (
    <ClickAwayListener onClickAway={(): void => setAnchorEl(null)}>
      <FormControl
        data-testid={dataTestId}
        onKeyDown={(e): void => {
          if (e.key === 'Escape') {
            setAnchorEl(null);
          }
        }}
      >
        <TextField
          id="time-picker"
          data-testid={dataTestId && `${dataTestId}-input`}
          type="time"
          label={label}
          value={value}
          defaultValue={defaultValue}
          className={clsx(className, classes.input)}
          onChange={(e): void => onChange?.(e.target.value)}
          inputProps={{
            step: step && step * 60,
            required: true,
          }}
          InputLabelProps={{
            shrink: true,
          }}
          InputProps={{
            disableUnderline: true,
            endAdornment: !isMobile && (
              <InputAdornment
                data-testid={dataTestId && `${dataTestId}-adornment`}
                className="input-adornment"
                position="end"
              >
                <IconButton
                  size="small"
                  aria-label="visibility-button"
                  className={classes.inputIcon}
                  data-testid={dataTestId && `${dataTestId}-adornment-button`}
                  onClick={(): void =>
                    setAnchorEl((prevState) => (prevState ? null : document.getElementById('time-picker')))
                  }
                >
                  <QueryBuilderRounded />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <Popper
          open={!!anchorEl}
          anchorEl={anchorEl}
          placement="bottom-start"
          data-testid={`${dataTestId}-popper`}
          modifiers={{
            flip: {
              enabled: false,
            },
          }}
        >
          <Paper className={classes.paper}>
            <div className={classes.menuHeaderContainer}>
              <span className={classes.menuHeader}>hr</span>
              <span>:</span>
              <span className={classes.menuHeader}>min</span>
            </div>
            <div className={classes.menuContainer}>
              <Paper className={clsx(classes.menu, classes.menuHours)}>
                <MenuList>
                  {Array.from(Array(24).keys()).map((item) => (
                    <MenuItem
                      className={classes.menuItem}
                      key={item}
                      selected={moment(value as string, 'HH:mm').hour() === item}
                      data-testid={`${dataTestId}-hour-menu-item-${item}`}
                      onClick={(): void =>
                        onChange &&
                        onChange(
                          moment()
                            .set({ hour: item, minute: moment(value as string, 'HH:mm').minute() })
                            .format('HH:mm'),
                        )
                      }
                    >
                      <span>{String('0' + item).slice(-2)}</span>
                    </MenuItem>
                  ))}
                </MenuList>
              </Paper>
              <Paper className={clsx(classes.menu, classes.menuMinutes)}>
                <MenuList>
                  {(minutesWithStep || minutes).map((item) => (
                    <MenuItem
                      className={classes.menuItem}
                      key={item}
                      selected={moment(value as string, 'HH:mm').minute() === item}
                      data-testid={`${dataTestId}-minute-menu-item-${item}`}
                      onClick={(): void =>
                        onChange &&
                        onChange(
                          moment()
                            .set({ hour: moment(value as string, 'HH:mm').hour(), minute: item })
                            .format('HH:mm'),
                        )
                      }
                    >
                      <span>{String('0' + item).slice(-2)}</span>
                    </MenuItem>
                  ))}
                </MenuList>
              </Paper>
            </div>
          </Paper>
        </Popper>
      </FormControl>
    </ClickAwayListener>
  );
};

export default TimePicker;
