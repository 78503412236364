import React, { FC, useCallback, useRef } from 'react';
import { Typography, Checkbox as MuiCheckbox, FormControlLabel } from '@material-ui/core';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { CheckboxCheckedIcon, CheckboxIcon, CheckboxIndeterminateIcon } from '../../../icons';
import { Color, InferPropTypes, NonNullFields } from '../../types';

export const checkboxPropTypes = {
  checked: PropTypes.bool.isRequired,
  indeterminate: PropTypes.bool,
  label: PropTypes.string,
  name: PropTypes.string,
  color: PropTypes.oneOf(['primary', 'secondary', 'default']),
  className: PropTypes.string,
  dataTestId: PropTypes.string,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  hintText: PropTypes.string,
  validationText: PropTypes.string,
  required: PropTypes.bool,
  bold: PropTypes.bool,
};

export const defaultProps = {
  color: 'primary',
};

export type CheckboxProps = NonNullFields<InferPropTypes<typeof checkboxPropTypes, typeof defaultProps>>;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    validationText: {
      color: theme.palette.error.main,
      fontSize: 14,
      marginLeft: -33,
      marginTop: 5,
    },
    validationTextWithHint: {
      marginTop: 3,
    },
  }),
);

const Checkbox: FC<CheckboxProps> = ({
  label,
  checked,
  indeterminate,
  name,
  color,
  className,
  dataTestId,
  onChange,
  disabled,
  hintText,
  required,
  validationText,
  bold,
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const classes = useStyles();

  const handleKeyDown = useCallback(
    (e: React.KeyboardEvent) => {
      if (e.key === 'Enter') {
        inputRef?.current?.click();
      }
    },
    [inputRef],
  );

  const renderCheckbox = (): React.ReactElement => (
    <MuiCheckbox
      inputRef={inputRef}
      name={name}
      checked={checked}
      data-testid={`${dataTestId}-box`}
      color={color as Color}
      disableRipple
      onChange={onChange}
      disabled={disabled}
      icon={<CheckboxIcon id={name} />}
      checkedIcon={<CheckboxCheckedIcon id={name} />}
      indeterminateIcon={<CheckboxIndeterminateIcon id={name} />}
      className={clsx(className + '-checkbox', required ? 'required' : '')}
      onKeyDown={handleKeyDown}
      required={required}
      indeterminate={indeterminate}
    />
  );

  if (!label) {
    return renderCheckbox();
  }

  return (
    <FormControlLabel
      control={renderCheckbox()}
      label={
        <>
          <span className={bold ? 'bold' : ''}>{label}</span>
          {hintText && <div className="form-control-hint-text">{hintText}</div>}
          {!checked && validationText && (
            <Typography className={clsx(classes.validationText, hintText && classes.validationTextWithHint)}>
              {validationText}
            </Typography>
          )}
        </>
      }
      className={clsx(className, hintText && 'radio-hint-text', validationText && !checked && 'validation-text')}
      data-testid={dataTestId}
      disabled={disabled}
    />
  );
};

Checkbox.propTypes = checkboxPropTypes;
Checkbox.defaultProps = defaultProps;

export default Checkbox;
