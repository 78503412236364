import { AvatarClassKey } from '@material-ui/core/';
import { CSSProperties } from '@material-ui/core/styles/withStyles';
import { Theme } from '../Theme/theme';
import { constants } from '../../abstracts';

export const avatarTheme = (theme: Theme): Partial<Record<AvatarClassKey, CSSProperties>> => ({
  root: {
    fontWeight: 700,
    fontFamily: constants.lato,
    '&.xs': {
      fontSize: 10,
      lineHeight: 13,
      height: 24,
      width: 24,
    },
    '&.sm': {
      fontSize: 15,
      lineHeight: 20,
      height: 36,
      width: 36,
    },
    '&.md': {
      fontSize: 18,
      lineHeight: 24,
      height: 44,
      width: 44,
    },
    '&.lg': {
      fontSize: 23,
      lineHeight: 31,
      height: 56,
      width: 56,
    },
  },
  colorDefault: {
    backgroundColor: theme.palette.neutral.mid,
  },
});
