import React from 'react';
import { Alert, AlertTitle, AlertProps } from '@material-ui/lab';
import { Typography, useMediaQuery } from '@material-ui/core';
import { useTheme, makeStyles, createStyles } from '@material-ui/core/styles';
import { CloseRounded } from '@material-ui/icons';
import clsx from 'clsx';

import { Theme } from '../Theme/theme';
import { IconButton, Link } from '../..';
import { SystemIcon } from '../../utils/SystemIcon';
import { NotificationType } from '../types';

export interface NotificationProps extends Omit<AlertProps, 'variant'> {
  variant?: NotificationType;
  size?: 'small' | 'medium';
  link?: {
    text?: string;
    url?: string;
  };
  linkText?: string;
  dataTestId?: string;
  sticky?: boolean;
}

const useStyles = makeStyles<Theme>((theme) =>
  createStyles({
    root: {
      '& > .MuiAlert-message': {
        '& > .MuiAlertTitle-root': {
          fontWeight: 700,
          color: 'inherit',
        },
        '& > p': {
          color: 'inherit',
          '& > a': {
            color: 'inherit',
          },
        },
      },
      '& > .MuiAlert-action': {
        alignItems: 'start',
      },
    },
    sticky: {
      position: 'sticky',
      borderRadius: 0,
      borderLeft: '0 !important',
      borderRight: '0 !important',
      borderTop: '0 !important',
      top: 0,
      marginTop: 0,
    },
    small: {
      padding: '8px 16px',
      '& > .MuiAlert-message': {
        display: 'flex',
        '& > .MuiAlertTitle-root': {
          marginTop: 0,
          marginBottom: 0,
          marginRight: 8,
        },
        '& > .MuiTypography-root': {
          '& > a': {
            marginLeft: 8,
          },
        },
      },
    },
    medium: {
      padding: '9px 16px',
      '& > .MuiAlert-message': {
        '& > .MuiTypography-root:not(:last-child)': {
          marginBottom: 8,
        },
      },
    },
    success: {
      border: `1px solid ${theme.palette.success.main}`,
      backgroundColor: '#F5FAF5',
      color: theme.palette.success.main,
      '& > .MuiAlert-icon': {
        '& > span': {
          '& > svg': {
            fill: theme.palette.success.main,
          },
        },
      },
      '& > .MuiAlert-action': {
        '& > button': {
          '& > span': {
            '& > svg': {
              fill: theme.palette.success.main,
            },
          },
        },
      },
    },
    error: {
      border: `1px solid ${theme.palette.error.main}`,
      backgroundColor: '#FDF7F6',
      color: theme.palette.error.main,
      '& > .MuiAlert-icon': {
        '& > span': {
          '& > svg': {
            fill: theme.palette.error.main,
          },
        },
      },
      '& > .MuiAlert-action': {
        '& > button': {
          '& > span': {
            '& > svg': {
              fill: theme.palette.error.main,
            },
          },
        },
      },
    },
    warning: {
      border: `1px solid ${theme.palette.warning.main}`,
      backgroundColor: '#FCF8F3',
      color: theme.palette.warning.main,
      '& > .MuiAlert-icon': {
        '& > span': {
          '& > svg': {
            fill: theme.palette.warning.main,
          },
        },
      },
      '& > .MuiAlert-action': {
        '& > button': {
          '& > span': {
            '& > svg': {
              fill: theme.palette.warning.main,
            },
          },
        },
      },
    },
    info: {
      border: `1px solid ${theme.palette.info.main}`,
      backgroundColor: '#F5F7FC',
      color: theme.palette.info.main,
      '& > .MuiAlert-icon': {
        '& > span': {
          '& > svg': {
            fill: theme.palette.info.main,
          },
        },
      },
      '& > .MuiAlert-action': {
        '& > button': {
          '& > span': {
            '& > svg': {
              fill: theme.palette.info.main,
            },
          },
        },
      },
    },
  }),
);

const Notification: React.FC<NotificationProps> = ({
  dataTestId,
  variant = 'info',
  size = 'medium',
  sticky,
  className,
  children,
  title,
  onClose,
  link,
  ...rest
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));

  return (
    <Alert
      icon={<SystemIcon variant={variant} size="small" />}
      className={clsx(
        classes[variant],
        classes[isMobile ? 'medium' : size],
        sticky && classes.sticky,
        classes.root,
        className,
      )}
      data-testid={dataTestId}
      action={
        !sticky && (
          <IconButton size="small" aria-label="close" color="inherit" onClick={onClose}>
            <CloseRounded fontSize="small" />
          </IconButton>
        )
      }
      {...rest}
    >
      {title && <AlertTitle>{title}</AlertTitle>}
      <Typography className={classes.content}>{children}</Typography>
      {link && (
        <Typography>
          <Link href={link.url}>{link.text}</Link>
        </Typography>
      )}
    </Alert>
  );
};

export default Notification;
