import {
  DividerClassKey,
  ListClassKey,
  ListItemClassKey,
  MenuClassKey,
  PaperClassKey,
  SelectClassKey,
} from '@material-ui/core';
import { CSSProperties } from '@material-ui/core/styles/withStyles';
import { Theme } from '../../Theme/theme';

const selectTheme = (theme: Theme): Partial<Record<SelectClassKey, CSSProperties>> => ({
  icon: {
    color: theme.palette.text.primary,
    marginRight: 12,
  },
  root: {
    backgroundColor: 'transparent',
  },
  select: {
    display: 'block',
    paddingRight: '42px !important',
    '&:focus': {
      backgroundColor: 'transparent',
    },
  },
});

const paperTheme: Partial<Record<PaperClassKey, CSSProperties>> = {
  root: {
    marginTop: 8,
  },
};

const menuTheme = (theme: Theme): Partial<Record<MenuClassKey, CSSProperties>> => ({
  paper: {
    boxShadow: `0px 4px 6px 0px ${theme.palette.elevation}1F`,
    border: `1px solid ${theme.palette.neutral.mid}`,
    maxHeight: '400px',
  },
});

const listTheme: Partial<Record<ListClassKey, CSSProperties>> = {
  padding: {
    paddingTop: 8,
    paddingBottom: 8,
  },
  root: {
    '& > .MuiMenuItem-root': {
      marginBottom: 0,
      height: 44,
      padding: '12px 36px 12px 16px',
    },
    '& > .phone-input-menu-item': {
      flexDirection: 'row-reverse',
      justifyContent: 'flex-end',
    },
  },
};

const dividerTheme: Partial<Record<DividerClassKey, CSSProperties>> = {
  root: {
    marginTop: 12,
    marginBottom: 12,
  },
};

const listItemTheme = (theme: Theme): Partial<Record<ListItemClassKey, CSSProperties>> => ({
  button: {
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
    },
    '&:focus': {
      outline: '5px auto -webkit-focus-ring-color',
      backgroundColor: theme.palette.action.hover,
    },
    '&:active': {
      backgroundColor: theme.palette.action.active,
    },
    '&$selected': {
      backgroundColor: theme.palette.action.hover,
    },
    '&.navigation-dropdown-item': {
      backgroundColor: theme.palette.background.paper,
      height: 56,
      minWidth: 256,
      borderLeftWidth: 1,
      borderRightWidth: 1,
      borderColor: theme.palette.divider,
      borderStyle: 'solid',
      boxShadow: `0px 2px 4px ${theme.palette.elevation}0A`,
      '&:nth-last-child(1)': {
        borderBottomWidth: 1,
        borderRadius: '0px 0px 6px 6px',
      },
      '&:hover': {
        backgroundColor: theme.palette.action.hover,
      },
      '&:active': {
        backgroundColor: theme.palette.action.active,
      },
      '&$selected': {
        color: theme.palette.primary.main,
      },
    },
  },
});

export { selectTheme, paperTheme, listTheme, menuTheme, dividerTheme, listItemTheme };
