import React, { useMemo } from 'react';
import { ListItem, ListItemText, ListItemProps as MuiListItemProps } from '@material-ui/core';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import { InferPropTypes, NonNullFields } from '../../types';
import { monochromeLight, monochromeLightest, white } from '../../../abstracts/colours';

const navigationDropdownItemPropTypes = {
  label: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  tabIndex: PropTypes.number.isRequired,
  currentNavItem: PropTypes.shape({
    currentTab: PropTypes.number.isRequired,
    currentDropdownItem: PropTypes.number,
  }).isRequired,
  handleChange: PropTypes.func,
  dataTestId: PropTypes.string,
};

const defaultProps = {
  label: '',
  index: 0,
  tabIndex: 0,
  currentNavItem: {
    currentTab: 0,
  },
};

export type NavigationSubItemVerticalProps = NonNullFields<InferPropTypes<typeof navigationDropdownItemPropTypes>> &
  MuiListItemProps;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    nested: {
      backgroundColor: white,
      height: 56,
      maxWidth: 256,
      paddingLeft: 56,
      '&:hover': {
        backgroundColor: monochromeLightest,
      },
      '&:active': {
        backgroundColor: monochromeLight,
      },
      '&.Mui-selected': {
        backgroundColor: white,
        '& > div': {
          '& > span': {
            color: theme.palette.primary.main,
          },
        },
      },
    },
  }),
);

const NavigationSubItemVertical: React.FC<NavigationSubItemVerticalProps> = ({
  label,
  index,
  currentNavItem,
  tabIndex,
  handleChange,
  dataTestId,
}) => {
  const classes = useStyles();
  const isSelected: boolean = useMemo(
    () => currentNavItem.currentTab === tabIndex && currentNavItem.currentDropdownItem === index,
    [currentNavItem, tabIndex, index],
  );
  return (
    <ListItem
      data-testid={`${dataTestId}-menu-subitem-${tabIndex}`}
      className={classes.nested}
      button
      key={index}
      selected={isSelected}
      onClick={(): void => handleChange?.({ currentTab: tabIndex, currentDropdownItem: index })}
    >
      <ListItemText primary={label} />
    </ListItem>
  );
};

NavigationSubItemVertical.propTypes = navigationDropdownItemPropTypes;
NavigationSubItemVertical.defaultProps = defaultProps;

export default React.memo(NavigationSubItemVertical);
