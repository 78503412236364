import React from 'react';
import { Hidden, Box } from '@material-ui/core';

const ProductHeaderLogo: React.FC = ({ children }) => (
  <Hidden smDown>
    <Box className="logo">{children}</Box>
  </Hidden>
);

export default ProductHeaderLogo;
