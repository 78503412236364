import React from 'react';

const Image: React.FC<{ className?: string }> = ({ className }) => (
  <svg
    width="36px"
    height="36px"
    viewBox="0 0 36 36"
    version="1.1"
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.5 4.5H28.5C30.15 4.5 31.5 5.85 31.5 7.5V28.5C31.5 30.15 30.15 31.5 28.5 31.5H7.5C5.85 31.5 4.5 30.15 4.5 28.5V7.5C4.5 5.85 5.85 4.5 7.5 4.5ZM12.9 20.295L9.9 24.3C9.525 24.795 9.885 25.5 10.5 25.5H25.5C26.115 25.5 26.475 24.795 26.1 24.3L21.975 18.795C21.675 18.39 21.075 18.39 20.775 18.795L16.875 24L14.1 20.295C13.8 19.89 13.2 19.89 12.9 20.295Z"
    />
    <circle cx="12" cy="12" r="3" fill="white" />
  </svg>
);

export default Image;
