import React from 'react';

const ConditionalWrap = ({
  condition,
  wrap,
  children,
}: {
  condition: boolean;
  wrap: (children: React.ReactNode) => JSX.Element;
  children: React.ReactNode;
}): JSX.Element => (condition ? wrap(children) : <>{children}</>);

export default ConditionalWrap;
