import {
  TableCellClassKey,
  TableClassKey,
  TableContainerClassKey,
  TableFooterClassKey,
  TableHeadClassKey,
  TablePaginationClassKey,
  TableRowClassKey,
} from '@material-ui/core';
import { CSSProperties } from '@material-ui/core/styles/withStyles';

import { Theme } from '../Theme/theme';

export const tableContainerTheme = (theme: Theme): Partial<Record<TableContainerClassKey, CSSProperties>> => ({
  root: {
    border: `1px solid ${theme.palette.divider}`,
    '&:first-child': {
      borderTopLeftRadius: 6,
      borderTopRightRadius: 6,
    },
    '&:last-child': {
      borderBottomLeftRadius: 6,
      borderBottomRightRadius: 6,
    },
  },
});

export const tableTheme = (theme: Theme): Partial<Record<TableClassKey, CSSProperties>> => ({
  root: {
    backgroundColor: theme.palette.background.paper,
  },
});

export const tableHeadTheme = (theme: Theme): Partial<Record<TableHeadClassKey, CSSProperties>> => ({
  root: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    '& > tr': {
      '& > th': {
        fontWeight: 700,
      },
    },
  },
});

export const tableRowTheme = (theme: Theme): Partial<Record<TableRowClassKey, CSSProperties>> => ({
  root: {
    height: 56,
    '&:not(:last-child)': {
      borderBottom: `1px solid ${theme.palette.divider}`,
    },
    '&.Mui-selected': {
      backgroundColor: '#F1F4FB',
      '&:hover, &.Mui-focusVisible': {
        backgroundColor: '#F1F4FB',
      },
    },
  },
  head: {
    '& > th': {
      lineHeight: '16px',
    },
  },
});

export const tableCellTheme: Partial<Record<TableCellClassKey, CSSProperties>> = {
  root: {
    height: 56,
    padding: '14px 16px',
    borderBottom: 'none',
    fontSize: 16,
    lineHeight: '20px',
    '& > div, & > span': {
      display: 'flex',
      alignItems: 'center',
      '& > span': {
        marginRight: 6,
      },
    },
    '&.hidden': {
      display: 'none',
    },
  },
};

export const tableSortLabelTheme: Partial<Record<TableCellClassKey, CSSProperties>> = {
  root: {
    '& > svg': {
      width: 20,
      height: 20,
      color: 'inherit !important',
    },
  },
};

export const tableFooterTheme = (theme: Theme): Partial<Record<TableFooterClassKey, CSSProperties>> => ({
  root: {
    borderTop: `1px solid ${theme.palette.divider}`,
    padding: 16,
  },
});

export const tablePaginationTheme = (theme: Theme): Partial<Record<TablePaginationClassKey, CSSProperties>> => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    borderWidth: '0px 1px 1px 1px',
    borderStyle: 'solid',
    borderColor: theme.palette.divider,
    borderBottomLeftRadius: 6,
    borderBottomRightRadius: 6,
    '& > .MuiToolbar-root': {
      padding: 16,
    },
  },
  toolbar: {
    '& > p': {
      fontSize: 16,
    },
  },
  input: {
    border: `1px solid ${theme.palette.divider}`,
  },
  selectRoot: {
    height: 36,
    width: 72,
    marginBottom: 0,
  },
});
