import React from 'react';

const Science: React.FC<{ className?: string }> = ({ className }) => (
  <svg
    width="36px"
    height="36px"
    viewBox="0 0 36 36"
    version="1.1"
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <path d="M30.81 26.595L22.5 16.5V7.5H24C24.825 7.5 25.5 6.825 25.5 6C25.5 5.175 24.825 4.5 24 4.5H12C11.175 4.5 10.5 5.175 10.5 6C10.5 6.825 11.175 7.5 12 7.5H13.5V16.5L5.19 26.595C4.71 27.18 4.5 27.84 4.5 28.5C4.515 30.045 5.73 31.5 7.5 31.5H28.5C30.285 31.5 31.5 30.045 31.5 28.5C31.5 27.84 31.29 27.18 30.81 26.595Z" />
  </svg>
);

export default Science;
