import React, { FC } from 'react';
import { makeStyles, createStyles, Theme, alpha } from '@material-ui/core/styles';
import { StepIconProps as MuiStepIconProps, Typography } from '@material-ui/core';
import clsx from 'clsx';

import { ErrorOutline, Error, CheckCircleOutline, CheckCircle } from '../../icons/';
import { getDarkest, getMedium, monochromeLight, monochromeLightest } from '../../abstracts/colours';

const styles = makeStyles<Theme>((theme) =>
  createStyles({
    labelContainer: {
      border: `2px solid ${monochromeLight}`,
      '&.active': {
        backgroundColor: `${theme.palette.primary.main} !important`,
        borderColor: `${theme.palette.primary.main} !important`,
        color: 'white',
      },
    },
    iconContainer: {
      height: 36,
      width: 36,
      overflow: 'hidden',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: '50%',
    },
    labelIcon: {
      '&:hover': {
        cursor: 'pointer',
        backgroundColor: monochromeLightest,
      },
      '&:active': {
        backgroundColor: monochromeLight,
      },
    },
    errorIcon: {
      '&:hover': {
        cursor: 'pointer',
        backgroundColor: alpha(getMedium(theme.palette.error), 0.08),
      },
      '&:active': {
        backgroundColor: alpha(getDarkest(theme.palette.error), 0.24),
      },
    },
    completedIcon: {
      '&:hover': {
        cursor: 'pointer',
        backgroundColor: alpha(getMedium(theme.palette.primary), 0.08),
      },
      '&:active': {
        backgroundColor: alpha(getDarkest(theme.palette.primary), 0.24),
      },
    },
  }),
);

interface StepIconProps extends MuiStepIconProps {
  clickable?: boolean;
}

const StepIcon: FC<StepIconProps> = ({ error, active, clickable, completed, icon }) => {
  const { labelContainer, errorIcon, iconContainer, labelIcon, completedIcon } = styles();

  if (error) {
    return (
      <div className={clsx(iconContainer, clickable && !active && errorIcon)}>
        {active ? <Error /> : <ErrorOutline />}
      </div>
    );
  }

  if (completed) {
    return (
      <div className={clsx(iconContainer, clickable && !active && completedIcon)}>
        {active ? <CheckCircle /> : <CheckCircleOutline />}
      </div>
    );
  }

  return (
    <Typography
      className={clsx(iconContainer, clickable && labelIcon, labelContainer, active && 'active')}
      color="inherit"
      variant="subtitle2"
    >
      {icon}
    </Typography>
  );
};
export default StepIcon;
