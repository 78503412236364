import React, { FC } from 'react';
import {
  DialogTitle as MuiDialogTitle,
  DialogTitleProps as MuiDialogTitleProps,
  Typography,
  IconButton,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { CloseRounded } from '@material-ui/icons';
import clsx from 'clsx';

export interface ModalTitleProps extends MuiDialogTitleProps {
  onClose?: () => void;
}

const useStyles = makeStyles({
  title: {
    margin: 0,
    padding: '16px 16px 16px 16px',
  },
  closeButton: {
    position: 'absolute',
    right: 12,
    top: 12,
  },
});

const ModalTitle: FC<ModalTitleProps> = ({ children, onClose, className, ...rest }) => {
  const { title, closeButton } = useStyles();
  return (
    <MuiDialogTitle disableTypography className={clsx(title, className)} {...rest}>
      <Typography variant="h5">{children}</Typography>
      <IconButton aria-label="close" className={closeButton} onClick={onClose} size="small">
        <CloseRounded />
      </IconButton>
    </MuiDialogTitle>
  );
};

export default ModalTitle;
