import React from 'react';
import {
  TablePagination as MuiTablePagination,
  TablePaginationProps as MuiTablePaginationProps,
  useMediaQuery,
} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { ExpandMoreRounded } from '@material-ui/icons';
import PropTypes from 'prop-types';

import { InferPropTypes, NonNullFields } from '../types';
import TablePaginationActions from './TablePaginationActions';

const tablePaginationPropTypes = {
  dataTestId: PropTypes.string,
  component: PropTypes.string,
};

export type TablePaginationProps = NonNullFields<InferPropTypes<typeof tablePaginationPropTypes>> &
  MuiTablePaginationProps;

const TablePagination: React.FC<TablePaginationProps> = ({ children, dataTestId, rowsPerPageOptions, ...rest }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  return (
    <MuiTablePagination
      data-testid={dataTestId}
      SelectProps={{ id: 'select-id', labelId: 'select-label-id', IconComponent: ExpandMoreRounded }}
      ActionsComponent={TablePaginationActions}
      rowsPerPageOptions={isMobile ? [] : rowsPerPageOptions}
      {...rest}
    >
      {children}
    </MuiTablePagination>
  );
};

TablePagination.propTypes = tablePaginationPropTypes;

export default TablePagination;
