import React, { ReactNode } from 'react';
import { List, DrawerProps, IconButton, Divider, Typography } from '@material-ui/core';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { Close } from '@material-ui/icons';
import PropTypes from 'prop-types';

import { InferPropTypes, NonNullFields } from '../../types';

const navigationVerticalPropTypes = {
  value: PropTypes.shape({
    currentTab: PropTypes.number.isRequired,
    currentDropdownItem: PropTypes.number,
  }).isRequired,
  onChange: PropTypes.func,
  children: PropTypes.node,
  drawer: PropTypes.bool,
  drawerText: PropTypes.string,
  onClose: PropTypes.func,
  dataTestId: PropTypes.string,
};

const defaultProps = {
  value: {
    currentTab: 0,
  },
};

export type NavigationVerticalProps = NonNullFields<InferPropTypes<typeof navigationVerticalPropTypes>> & DrawerProps;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    drawerHeader: {
      display: 'flex',
      alignItems: 'center',
      padding: theme.spacing(0, 1),
      ...theme.mixins.toolbar,
      '& > p': {
        fontWeight: 600,
        fontSize: 18,
      },
    },
    divider: {
      marginTop: 0,
      marginBottom: 0,
    },
    list: {
      paddingTop: 0,
      paddingBottom: 0,
    },
  }),
);

const NavigationVertical: React.FC<NavigationVerticalProps> = ({
  children,
  value: currentNavItem,
  onChange,
  drawer,
  drawerText,
  onClose,
  dataTestId,
}) => {
  const classes = useStyles();
  const handleChange = (newValue: { currentTab: number; currentDropdownItem?: number }): void => onChange?.(newValue);
  return (
    <>
      {drawer && (
        <>
          <div className={classes.drawerHeader}>
            <IconButton data-testid={`${dataTestId}-close-button`} onClick={(): void => onClose?.()}>
              <Close />
            </IconButton>
            <Typography>{drawerText}</Typography>
          </div>
          <Divider className={classes.divider} />
        </>
      )}
      <List data-testid={dataTestId} className={classes.list}>
        {React.Children.map<ReactNode, ReactNode>(children, (child, index) => {
          if (React.isValidElement(child)) {
            return React.cloneElement(child, {
              tabIndex: index,
              currentNavItem,
              handleChange,
              vertical: true,
              dataTestId,
            });
          }
        })}
      </List>
    </>
  );
};

NavigationVertical.propTypes = navigationVerticalPropTypes;
NavigationVertical.defaultProps = defaultProps;

export default React.memo(NavigationVertical);
