import React, { FC } from 'react';
import { MenuItem, MenuItemProps } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CheckboxChecked from '../../../icons/CheckboxChecked';
import CheckboxIcon from '../../../icons/CheckboxIcon';

interface DropdownItemProps extends MenuItemProps {
  value: string | number | readonly string[] | undefined;
  isMobile?: boolean;
  checked?: boolean;
  multiple?: boolean;
}

const useStyles = makeStyles({
  checkboxIcon: {
    marginRight: 15,
    marginTop: 3,
  },
});

const DropdownItem: FC<DropdownItemProps> = React.forwardRef((props, ref) => {
  const { value, children, isMobile, checked, multiple, ...rest } = props;
  const classes = useStyles();

  const checkbox = checked ? <CheckboxChecked /> : <CheckboxIcon />;

  return isMobile ? (
    <option value={value}>{children}</option>
  ) : (
    <MenuItem ref={ref as any} value={value} {...(rest as any)}>
      {multiple && <div className={classes.checkboxIcon}>{checkbox}</div>}
      {children}
    </MenuItem>
  );
});

export default DropdownItem;
