import React from 'react';

const PdfFile: React.FC<{ className?: string }> = ({ className }) => (
  <svg
    width="36px"
    height="36px"
    viewBox="0 0 36 36"
    version="1.1"
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.1716 3C20.702 3 21.2107 3.21071 21.5858 3.58579L29.4142 11.4142C29.7893 11.7893 30 12.298 30 12.8284V30C30 31.65 28.65 33 27 33H8.985C7.335 33 6 31.65 6 30V6C6 4.35 7.35 3 9 3H20.1716ZM21 4.75V10.5C21 11.325 21.675 12 22.5 12H28.25L21 4.75Z"
    />
    <path
      d="M9.3947 24V16.176H12.0827C12.5147 16.176 12.9187 16.22 13.2947 16.308C13.6707 16.388 13.9987 16.528 14.2787 16.728C14.5587 16.92 14.7787 17.18 14.9387 17.508C15.1067 17.828 15.1907 18.224 15.1907 18.696C15.1907 19.152 15.1067 19.548 14.9387 19.884C14.7787 20.22 14.5587 20.496 14.2787 20.712C13.9987 20.928 13.6747 21.088 13.3067 21.192C12.9387 21.296 12.5467 21.348 12.1307 21.348H11.1587V24H9.3947ZM11.1587 19.944H12.0227C12.9827 19.944 13.4627 19.528 13.4627 18.696C13.4627 18.288 13.3347 18 13.0787 17.832C12.8227 17.664 12.4547 17.58 11.9747 17.58H11.1587V19.944ZM16.0431 24V16.176H18.2511C18.8511 16.176 19.3911 16.252 19.8711 16.404C20.3511 16.556 20.7631 16.792 21.1071 17.112C21.4511 17.424 21.7151 17.824 21.8991 18.312C22.0831 18.8 22.1751 19.38 22.1751 20.052C22.1751 20.724 22.0831 21.308 21.8991 21.804C21.7151 22.3 21.4551 22.712 21.1191 23.04C20.7831 23.36 20.3791 23.6 19.9071 23.76C19.4431 23.92 18.9231 24 18.3471 24H16.0431ZM17.8071 22.572H18.1431C18.4791 22.572 18.7831 22.528 19.0551 22.44C19.3271 22.352 19.5591 22.212 19.7511 22.02C19.9431 21.82 20.0911 21.56 20.1951 21.24C20.3071 20.92 20.3631 20.524 20.3631 20.052C20.3631 19.588 20.3071 19.2 20.1951 18.888C20.0911 18.568 19.9431 18.316 19.7511 18.132C19.5591 17.94 19.3271 17.804 19.0551 17.724C18.7831 17.644 18.4791 17.604 18.1431 17.604H17.8071V22.572ZM23.1603 24V16.176H28.0923V17.664H24.9243V19.476H27.6363V20.964H24.9243V24H23.1603Z"
      fill="white"
    />
  </svg>
);

export default PdfFile;
