import { LinkClassKey } from '@material-ui/core';
import { CSSProperties } from '@material-ui/core/styles/withStyles';
import { getDarkest } from '../../abstracts/colours';
import { Theme } from '../Theme/theme';

export const linkTheme = (theme: Theme): Partial<Record<LinkClassKey, CSSProperties>> => ({
  root: {
    fontWeight: 600,
    cursor: 'pointer',
    '&:hover': {
      color: theme.palette.primary.dark,
    },
    '&:active': {
      color: getDarkest(theme.palette.primary),
    },
    '&:visited': {
      color: getDarkest(theme.palette.primary),
    },
  },
});
