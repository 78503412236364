import React from 'react';
import PropTypes from 'prop-types';
import { Hidden, Box, Divider, Typography } from '@material-ui/core';
import { InferPropTypes, NonNullFields } from '../../types';

export const productTitleTextPropTypes = {
  productName: PropTypes.string.isRequired,
};

export type ProductTitleTextProps = NonNullFields<InferPropTypes<typeof productTitleTextPropTypes>>;

const ProductTitle: React.FC = ({ children }) => (
  <>
    <Hidden smDown>
      <Divider className="title-divider" orientation="vertical" flexItem />
    </Hidden>
    <Box>{children}</Box>
  </>
);

const ProductTitleText: React.FC<ProductTitleTextProps> = ({ productName }) => (
  <ProductTitle>
    <Typography variant="h5">{productName}</Typography>
  </ProductTitle>
);

ProductTitleText.propTypes = productTitleTextPropTypes;

export default ProductTitleText;
