import React, { FC } from 'react';
import MuiGrid, { GridProps, GridSpacing } from '@material-ui/core/Grid';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

const Grid: FC<GridProps> = ({ item, xs, sm, md, lg, xl, container, className, ...rest }) => {
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('xs'));

  const spacingProp: { spacing?: GridSpacing } = {};
  if (container) spacingProp.spacing = isSmall ? 2 : 4;

  return (
    <MuiGrid
      item={item}
      container={container}
      className={className}
      xs={item && xs}
      sm={item && sm}
      md={item && md}
      lg={item && lg}
      xl={item && xl}
      {...spacingProp}
      {...rest}
    />
  );
};

Grid.defaultProps = {
  xs: 6,
  sm: 4,
  md: 3,
  lg: 2,
  xl: 2,
};

export default Grid;
