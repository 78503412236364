import { TabsClassKey, TabClassKey } from '@material-ui/core';
import { CSSProperties } from '@material-ui/core/styles/withStyles';
import { Theme } from '../Theme/theme';

export const navigationTheme = (theme: Theme): Partial<Record<TabsClassKey, CSSProperties>> => ({
  root: {
    color: theme.palette.text.primary,
    borderWidth: 0,
    borderBottomWidth: 1,
    borderStyle: 'solid',
    borderColor: theme.palette.divider,
    minHeight: 44,
    '&.card-tabs': {
      borderBottomWidth: 0,
      borderTopLeftRadius: 6,
      borderTopRightRadius: 6,
      overflow: 'hidden',
      '& > .MuiTabs-scroller': {
        borderTopLeftRadius: 6,
        borderTopRightRadius: 6,
        overflow: 'hidden',
      },
    },
    '&.navigation-tabs': {
      backgroundColor: theme.palette.background.paper,
      borderWidth: 0,
      borderBottomWidth: 1,
      borderStyle: 'solid',
      borderColor: theme.palette.divider,
    },
  },
  indicator: {
    height: 3,
  },
  scroller: {
    [theme.breakpoints.up('md')]: {
      flex: 'none',
    },
  },
  scrollButtons: {
    '& > svg': {
      borderRadius: '50%',
      border: `1px solid ${theme.palette.divider}`,
      height: 36,
      width: 36,
      padding: 6,
      '&:hover': {
        backgroundColor: theme.palette.action.hover,
      },
      '&:active': {
        backgroundColor: theme.palette.action.active,
      },
    },
  },
});

export const navigationTabTheme = (theme: Theme): Partial<Record<TabClassKey, CSSProperties>> => ({
  root: {
    [theme.breakpoints.up('sm')]: {
      minWidth: 0,
    },
    maxWidth: 310,
    padding: '12px 16px',
    minHeight: 44,
    '&.card-tab': {
      padding: '11px 16px',
      borderTop: `1px solid ${theme.palette.divider}`,
      borderBottom: `1px solid ${theme.palette.divider}`,
      borderLeft: `1px solid ${theme.palette.divider}`,
      '&:last-child': { borderRight: `1px solid ${theme.palette.divider}`, borderTopRightRadius: 6 },
      '&:first-child': { borderTopLeftRadius: 6 },
      '&$selected': {
        backgroundColor: theme.palette.background.paper,
        borderBottom: 'none',
        borderTop: 'none',
      },
    },
    '&:focus-visible': {
      outline: '5px auto -webkit-focus-ring-color',
    },
    '&:not($selected)': {
      '&:hover': {
        backgroundColor: theme.palette.action.hover,
      },
      '&:active': {
        backgroundColor: theme.palette.action.active,
      },
    },

    '&.MuiTab-textColorInherit': {
      opacity: 1,
    },
    '&$selected': {
      color: theme.palette.primary.main,
    },
    '&.navigation-tab': {
      padding: '18px 24px',
    },
    '&.navigation-tab-with-dropdown': {
      minHeight: 0,
      '&:active': {
        backgroundColor: theme.palette.action.active,
      },
      '& > .MuiTab-wrapper': {
        flexDirection: 'row-reverse',
        '& > svg': {
          marginBottom: 0,
        },
      },
    },
    '&.navigation-tab-dropdown-open': {
      backgroundColor: theme.palette.background.paper,
      borderLeftWidth: 1,
      borderRightWidth: 1,
      borderColor: theme.palette.divider,
      borderStyle: 'solid',
    },
  },
});
