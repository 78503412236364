import React from 'react';
import { Tooltip as MuiTooltip, TooltipProps as MuiTooltipProps } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';

import { InferPropTypes, NonNullFields } from '../types';
import { white, monochromeLight, monochromeDark, monochromeDarkest } from '../../abstracts/colours';

const tooltipPropTypes = {
  dataTestId: PropTypes.string,
  detailed: PropTypes.bool,
  border: PropTypes.oneOf(['dark', 'light']),
};

const defaultProps = {
  border: 'light',
};

export type TooltipProps = NonNullFields<InferPropTypes<typeof tooltipPropTypes>> & MuiTooltipProps;

const createDetailedComponent = (border?: string) =>
  withStyles(() => ({
    tooltip: {
      backgroundColor: white,
      color: monochromeDark,
      border: `1px solid ${border === 'light' ? monochromeLight : monochromeDarkest}`,
      boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.06)',
      padding: 16,
      fontSize: 16,
      maxWidth: 400,
    },
    arrow: {
      color: white,
      '&::before': {
        border: `1px solid ${border === 'light' ? monochromeLight : monochromeDarkest}`,
      },
      width: 22,
      height: 16,
      marginTop: '-16px !important',
    },
  }))(MuiTooltip);

const Tooltip: React.FC<TooltipProps> = ({ children, detailed, border, ...rest }) => {
  const DetailedTooltip = createDetailedComponent(border);
  return detailed ? (
    <DetailedTooltip arrow {...rest}>
      {children}
    </DetailedTooltip>
  ) : (
    <MuiTooltip arrow {...rest}>
      {children}
    </MuiTooltip>
  );
};

Tooltip.propTypes = tooltipPropTypes;
Tooltip.defaultProps = defaultProps;

export default Tooltip;
