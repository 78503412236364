import { TooltipClassKey } from '@material-ui/core';
import { CSSProperties } from '@material-ui/core/styles/withStyles';
import { Theme } from '../Theme/theme';

export const tooltipTheme = (theme: Theme): Partial<Record<TooltipClassKey, CSSProperties>> => ({
  tooltip: {
    backgroundColor: theme.palette.text.primary,
    fontSize: 14,
    padding: '2.5px 8px',
    maxWidth: 200,
  },
  arrow: {
    color: theme.palette.text.primary,
  },
});
