import React from 'react';

const Upload: React.FC<{ className?: string }> = ({ className }) => (
  <svg
    width="36px"
    height="36px"
    viewBox="0 0 36 36"
    version="1.1"
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21 23.9998H15C14.1716 23.9998 13.5 23.3282 13.5 22.4998V14.9998H11.121C10.5144 14.9996 9.9676 14.6342 9.73548 14.0738C9.50337 13.5133 9.63164 12.8683 10.0605 12.4393L16.9395 5.56027C17.5252 4.9747 18.4747 4.9747 19.0605 5.56027L25.9395 12.4393C26.3684 12.8683 26.4966 13.5133 26.2645 14.0738C26.0324 14.6342 25.4856 14.9996 24.879 14.9998H22.5V22.4998C22.5 23.3282 21.8284 23.9998 21 23.9998ZM27 26.9998H9C8.17157 26.9998 7.5 27.6713 7.5 28.4998C7.5 29.3282 8.17157 29.9998 9 29.9998H27C27.8284 29.9998 28.5 29.3282 28.5 28.4998C28.5 27.6713 27.8284 26.9998 27 26.9998Z"
    />
  </svg>
);

export default Upload;
