import { ChipClassKey } from '@material-ui/core/Chip';
import { CSSProperties } from '@material-ui/core/styles/withStyles';
import { Theme } from '../Theme/theme';

export const chipTheme = (theme: Theme): Partial<Record<ChipClassKey, CSSProperties>> => ({
  root: {
    backgroundColor: 'white',
    border: `1px solid ${theme.palette.divider}`,
    height: 36,
    borderRadius: 18,
    padding: '9px 12px',
    transition: 'none',
    '&.clickable': {
      '&:hover': {
        cursor: 'pointer',
        backgroundColor: theme.palette.action.hover,
      },
      '&:active': {
        backgroundColor: theme.palette.action.active,
        boxShadow: 'none',
      },
    },
    '&.selected': {
      backgroundColor: `${theme.palette.primary.main} !important`,
      borderColor: theme.palette.primary.main,
      boxShadow: 'none',
    },
    '&.small': {
      height: 24,
      padding: '3px 8px',
      '& > button': {
        marginRight: -5,
      },
      '& $avatar': { marginLeft: -5 },
    },
    '& $avatar': {
      height: 18,
      width: 18,
      margin: 0,
      marginLeft: -3,
      marginRight: 4,
      color: theme.palette.text.contrast,
    },
  },
  deletable: {
    backgroundColor: `${theme.palette.background.paper} !important`,
  },
  label: {
    paddingLeft: 0,
    paddingRight: 0,
  },
  deleteIcon: {
    color: theme.palette.text.primary,
    height: 18,
    width: 18,
    margin: 0,
    marginLeft: 4,
    marginRight: -3,
    '&:hover': {
      color: theme.palette.text.primary,
    },
    '& > span': {
      fontSize: 18,
    },
  },
});
