import { SnackbarClassKey } from '@material-ui/core';
import { CSSProperties } from '@material-ui/core/styles/withStyles';

import { Theme } from '../Theme/theme';

export const snackbarTheme = (theme: Theme): Partial<Record<SnackbarClassKey, CSSProperties>> => ({
  root: {
    '& > div': {
      fontSize: 16,
      width: 400,
      '& > .MuiSnackbarContent-message': {
        alignItems: 'center',
        display: 'flex',
        '& > .MuiIcon-root': {
          marginRight: 14,
          '& > svg': {
            fill: 'inherit',
          },
        },
        '& > .snackbar-message': {
          width: 228,
          [theme.breakpoints.up('xs')]: {
            width: 228,
          },
          [theme.breakpoints.down('xs')]: {
            width: 190,
          },
        },
      },
      '& > .MuiSnackbarContent-action': {
        paddingLeft: 0,
        '& > .MuiButton-text': {
          backgroundColor: 'transparent',
          textDecoration: 'underline',
        },
        '& > button': {
          padding: 0,
          color: 'inherit',
          fill: 'inherit',
        },
      },
    },
  },
});

export default { snackbarTheme };
