import React from 'react';
import { TableRow as MuiTableRow, TableRowProps as MuiTableRowProps } from '@material-ui/core';
import PropTypes from 'prop-types';

import { InferPropTypes, NonNullFields } from '../types';

const tableRowPropTypes = {
  dataTestId: PropTypes.string,
};

export type TableRowProps = NonNullFields<InferPropTypes<typeof tableRowPropTypes>> & MuiTableRowProps;

const TableRow: React.FC<TableRowProps> = ({ children, dataTestId, ...rest }) => (
  <MuiTableRow data-testid={dataTestId} {...rest}>
    {children}
  </MuiTableRow>
);

TableRow.propTypes = tableRowPropTypes;

export default TableRow;
