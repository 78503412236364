import React from 'react';
import { Snackbar as MuiSnackbar, SnackbarProps as MuiSnackbarProps } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/styles';
import { CloseRounded } from '@material-ui/icons';
import clsx from 'clsx';

import { Theme } from '../Theme/theme';
import { Button, IconButton } from '../../';
import { SystemIcon } from '../../utils/SystemIcon';
import { NotificationType } from '../types';

export interface SnackbarProps extends MuiSnackbarProps {
  size?: string;
  variant?: NotificationType;
  onClose?: (event: React.SyntheticEvent | React.MouseEvent, reason?: string) => void;
  onActionClick?: (event: React.SyntheticEvent | React.MouseEvent, reason?: string) => void;
  actionText?: string;
  dataTestId?: string;
}

const useStyles = makeStyles<Theme>((theme) =>
  createStyles({
    large: {
      '& > .MuiPaper-root': {
        width: 'calc(100vw - 32px)',
        flexWrap: 'initial',
        '& > .MuiSnackbarContent-message': {
          '& > .MuiIcon-root': {
            alignSelf: 'start',
          },
          '& > .snackbar-content': {
            display: 'flex',
            flexDirection: 'column',
            '& > .snackbar-title': {
              fontWeight: 700,
              marginBottom: 8,
            },
            '& > button': {
              alignSelf: 'start',
              padding: 0,
              backgroundColor: 'transparent',
              textDecoration: 'underline',
            },
          },
        },
        '& > .MuiSnackbarContent-action': {
          alignSelf: 'start',
        },
      },
    },
    success: {
      '& > div': {
        border: `1px solid ${theme.palette.success.main}`,
        backgroundColor: '#F5FAF5',
        color: theme.palette.success.main,
        fill: theme.palette.success.main,
      },
    },
    error: {
      '& > div': {
        border: `1px solid ${theme.palette.error.main}`,
        backgroundColor: '#FDF7F6',
        color: theme.palette.error.main,
        fill: theme.palette.error.main,
      },
    },
    warning: {
      '& > div': {
        border: `1px solid ${theme.palette.warning.main}`,
        backgroundColor: '#FCF8F3',
        color: theme.palette.warning.main,
        fill: theme.palette.warning.main,
      },
    },
    info: {
      '& > div': {
        border: `1px solid ${theme.palette.info.main}`,
        backgroundColor: '#F5F7FC',
        color: theme.palette.info.main,
        fill: theme.palette.info.main,
      },
    },
  }),
);

const SnackbarMedium: React.FC<SnackbarProps> = ({
  dataTestId,
  variant = 'info',
  onClose,
  onActionClick,
  actionText,
  message,
  className,
  ...rest
}) => {
  const classes = useStyles();
  return (
    <MuiSnackbar
      className={clsx(classes[variant], className)}
      data-testid={dataTestId}
      message={
        <>
          <SystemIcon variant={variant} size="small" />
          <span className="snackbar-message">{message}</span>
        </>
      }
      action={
        <React.Fragment>
          <Button size="small" variant="text" onClick={onActionClick}>
            {actionText}
          </Button>
          <IconButton size="small" aria-label="close" color="inherit" onClick={onClose}>
            <CloseRounded fontSize="small" />
          </IconButton>
        </React.Fragment>
      }
      {...rest}
    />
  );
};

const SnackbarLarge: React.FC<SnackbarProps> = ({
  dataTestId,
  variant = 'info',
  onClose,
  onActionClick,
  actionText,
  title,
  message,
  className,
  ...rest
}) => {
  const classes = useStyles();
  return (
    <MuiSnackbar
      className={clsx(classes[variant], classes.large, className)}
      data-testid={dataTestId}
      message={
        <>
          <SystemIcon variant={variant} size="small" />
          <div className="snackbar-content">
            <span className="snackbar-title">{title}</span>
            <span className="snackbar-message">{message}</span>
            <Button size="small" variant="text" onClick={onActionClick}>
              {actionText}
            </Button>
          </div>
        </>
      }
      action={
        <React.Fragment>
          <IconButton size="small" aria-label="close" color="inherit" onClick={onClose}>
            <CloseRounded fontSize="small" />
          </IconButton>
        </React.Fragment>
      }
      {...rest}
    />
  );
};

const Snackbar: React.FC<SnackbarProps> = ({ size = 'medium', ...rest }) =>
  size === 'medium' ? <SnackbarMedium {...rest} /> : <SnackbarLarge {...rest} />;

export default Snackbar;
