import React, { FC, isValidElement, cloneElement } from 'react';
import { Tabs as MuiTabs, TabsProps as MuiTabsProps } from '@material-ui/core';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';

export interface TabsProps extends MuiTabsProps {
  card?: boolean;
}

const styles = makeStyles({ indicator: { position: 'absolute', top: 0 } });

const Tabs: FC<TabsProps> = ({ children, card, className, ...rest }) => {
  const classes = styles();

  const childrenWithProps = React.Children.map(children, (child) => {
    if (isValidElement(child)) {
      return cloneElement(child, { card });
    }
    return child;
  });

  return (
    <MuiTabs
      classes={{ indicator: clsx(card && classes.indicator) }}
      className={clsx(card && 'card-tabs', className)}
      indicatorColor="primary"
      variant="scrollable"
      {...rest}
    >
      {childrenWithProps}
    </MuiTabs>
  );
};

export default Tabs;
