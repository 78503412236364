import React, { FC } from 'react';

const CheckboxIndeterminate: FC<{ id?: string }> = ({ id }) => (
  <svg
    width="24px"
    height="24px"
    viewBox="0 0 24 24"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <path
      d="M0.5 4C0.5 2.067 2.067 0.5 4 0.5H20C21.933 0.5 23.5 2.067 23.5 4V20C23.5 21.933 21.933 23.5 20 23.5H4C2.067 23.5 0.5 21.933 0.5 20V4Z"
      fill="#0076BD"
      stroke="#0076BD"
      id={`Rectangle-${id}`}
    />
    <path
      d="M5 12.5H19"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      id={`Path-${id}`}
    />
  </svg>
);

export default CheckboxIndeterminate;
