import React from 'react';
import { Table as MuiTable, TableProps as MuiTableProps } from '@material-ui/core';
import PropTypes from 'prop-types';

import { InferPropTypes, NonNullFields } from '../types';

const tablePropTypes = {
  dataTestId: PropTypes.string,
};

export type TableProps = NonNullFields<InferPropTypes<typeof tablePropTypes>> & MuiTableProps;

const Table: React.FC<TableProps> = ({ children, dataTestId, ...rest }) => (
  <MuiTable data-testid={dataTestId} {...rest}>
    {children}
  </MuiTable>
);

Table.propTypes = tablePropTypes;

export default Table;
