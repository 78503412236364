import React from 'react';
import { MenuItemProps as MuiMenuItemProps } from '@material-ui/core';
import PropTypes from 'prop-types';

import { InferPropTypes, NonNullFields } from '../types';
import NavigationSubItemHorizontal from './Horizontal/NavigationSubItemHorizontal';
import NavigationSubItemVertical from './Vertical/NavigationSubItemVertical';

const navigationDropdownItemPropTypes = {
  vertical: PropTypes.bool,
  label: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  tabIndex: PropTypes.number.isRequired,
  currentNavItem: PropTypes.shape({
    currentTab: PropTypes.number.isRequired,
    currentDropdownItem: PropTypes.number,
  }).isRequired,
  handleChange: PropTypes.func,
  handleMouseLeave: PropTypes.func,
  dataTestId: PropTypes.string,
};

const defaultProps = {
  label: '',
  index: 0,
  tabIndex: 0,
  currentNavItem: {
    currentTab: 0,
  },
};

export type NavigationSubItemProps = NonNullFields<InferPropTypes<typeof navigationDropdownItemPropTypes>> &
  MuiMenuItemProps;

const NavigationSubItem: React.FC<NavigationSubItemProps> = ({ vertical, ...rest }) =>
  vertical ? <NavigationSubItemVertical {...rest} /> : <NavigationSubItemHorizontal {...rest} />;

NavigationSubItem.propTypes = navigationDropdownItemPropTypes;
NavigationSubItem.defaultProps = defaultProps;

export default NavigationSubItem;
