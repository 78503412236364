import React from 'react';

const Word: React.FC<{ className?: string }> = ({ className }) => (
  <svg
    width="36px"
    height="36px"
    viewBox="0 0 36 36"
    version="1.1"
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.1716 3C20.702 3 21.2107 3.21071 21.5858 3.58579L29.4142 11.4142C29.7893 11.7893 30 12.298 30 12.8284V30C30 31.65 28.65 33 27 33H8.985C7.335 33 6 31.65 6 30V6C6 4.35 7.35 3 9 3H20.1716ZM21 4.75V10.5C21 11.325 21.675 12 22.5 12H28.25L21 4.75Z"
    />
    <path
      d="M13.6522 26L11.7162 15.568H14.1322L14.8522 20.448C14.9162 20.992 14.9855 21.5413 15.0602 22.096C15.1349 22.64 15.2042 23.1893 15.2682 23.744H15.3322C15.4389 23.1893 15.5402 22.64 15.6362 22.096C15.7429 21.5413 15.8495 20.992 15.9562 20.448L17.0762 15.568H19.0762L20.1962 20.448C20.3029 20.9813 20.4042 21.5253 20.5002 22.08C20.6069 22.624 20.7135 23.1787 20.8202 23.744H20.8842C20.9589 23.1787 21.0282 22.624 21.0922 22.08C21.1669 21.536 21.2415 20.992 21.3162 20.448L22.0362 15.568H24.2922L22.4362 26H19.4762L18.4522 21.264C18.3669 20.8587 18.2869 20.4533 18.2122 20.048C18.1482 19.632 18.0895 19.232 18.0362 18.848H17.9722C17.9082 19.232 17.8389 19.632 17.7642 20.048C17.7002 20.4533 17.6255 20.8587 17.5402 21.264L16.5642 26H13.6522Z"
      fill="white"
    />
  </svg>
);

export default Word;
