import React from 'react';
import { Typography } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';

import { Modal, ModalTitle, ModalContent, ModalActions, Button, Link } from '../..';
import { Theme } from '../Theme/theme';

export interface CookieModalProps {
  dataTestId?: string;
  open: boolean;
  onClose?: () => void;
  link?: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modalTitle: {
      borderBottom: `1px solid ${theme.palette.divider}`,
    },
    modalContent: {
      backgroundColor: theme.palette.background.default,
      '& > .MuiTypography-root': {
        marginTop: 24,
        '&:not(:last-child)': {
          marginBottom: 24,
        },
      },
    },
    modalActions: {
      borderTop: `1px solid ${theme.palette.divider}`,
    },
    gridRow: {
      display: 'grid',
      gridAutoFlow: 'row',
      gridRowGap: 16,
    },
  }),
);

const CookieModal: React.FC<CookieModalProps> = ({ dataTestId, children, open, link, onClose }) => {
  const classes = useStyles();
  return (
    <Modal size="medium" open={open} onClose={onClose} data-testid={dataTestId}>
      <ModalTitle className={classes.modalTitle} onClose={onClose}>
        Change cookie settings
      </ModalTitle>
      <ModalContent className={classes.modalContent}>
        <Typography variant="subtitle2">Our site uses the following cookies.</Typography>
        <div className={classes.gridRow}>{children}</div>
        <Typography>
          For more information, <Link href={link}>read our cookie policy</Link>
        </Typography>
      </ModalContent>
      <ModalActions className={classes.modalActions}>
        <Button autoFocus onClick={onClose} color="primary" variant="outlined">
          Allow all
        </Button>
        <Button autoFocus onClick={onClose} color="primary">
          Save settings
        </Button>
      </ModalActions>
    </Modal>
  );
};

export default CookieModal;
