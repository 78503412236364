import React, { FC } from 'react';
import PropTypes from 'prop-types';
import { InferPropTypes, NonNullFields } from '../../types';
import clsx from 'clsx';

const inputLabelPropTypes = {
  htmlFor: PropTypes.string,
  label: PropTypes.node,
  optional: PropTypes.bool,
  hintText: PropTypes.string,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  className: PropTypes.string,
};

export type InputLabelProps = NonNullFields<InferPropTypes<typeof inputLabelPropTypes>>;

const InputLabel: FC<InputLabelProps> = ({ htmlFor, label, optional, hintText, disabled, required, className }) => (
  <label htmlFor={htmlFor} className={clsx('textfield-label', className, disabled && 'disabled')}>
    {label}
    {optional && <span className="optional-flag"> (optional)</span>}
    {required && <span className="asterisk">*</span>}
    {hintText && <div>{hintText}</div>}
  </label>
);

InputLabel.propTypes = inputLabelPropTypes;

export default InputLabel;
