import React from 'react';
import { Drawer as MuiDrawer, DrawerProps as MuiDrawerProps } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { InferPropTypes, NonNullFields } from '../types';

const drawerPropTypes = {
  dataTestId: PropTypes.string,
};

export type DrawerProps = NonNullFields<InferPropTypes<typeof drawerPropTypes>> & MuiDrawerProps;

const useStyles = makeStyles({
  hasDrawerActions: {
    '& > .MuiPaper-root': {
      paddingBottom: 76,
    },
  },
});

const Drawer: React.FC<DrawerProps> = ({ children, dataTestId, className, ...rest }) => {
  const hasDrawerActions = document.getElementById('drawer')?.querySelector('#drawer-actions') !== null;
  const classes = useStyles();
  return (
    <MuiDrawer
      id="drawer"
      data-testid={dataTestId}
      className={clsx(className, hasDrawerActions && classes.hasDrawerActions)}
      {...rest}
    >
      {children}
    </MuiDrawer>
  );
};

Drawer.propTypes = drawerPropTypes;

export default Drawer;
