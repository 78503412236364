import {
  CardContentClassKey,
  CardActionAreaClassKey,
  CardClassKey,
  CardActionsClassKey,
  CardHeaderClassKey,
} from '@material-ui/core/';
import { CSSProperties } from '@material-ui/core/styles/withStyles';
import { Theme } from '../Theme/theme';

export const cardContentTheme: Partial<Record<CardContentClassKey, CSSProperties>> = {
  root: {
    padding: '24px 16px',
  },
};
export const cardActionAreaTheme = (theme: Theme): Partial<Record<CardActionAreaClassKey, CSSProperties>> => ({
  root: {
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
    },
    '&:active': {
      backgroundColor: theme.palette.action.active,
    },
  },
});
export const cardTheme = (theme: Theme): Partial<Record<CardClassKey, CSSProperties>> => ({
  root: {
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: 6,
    boxShadow: `0 4px 6px 0 ${theme.palette.elevation}0A`,
    flexDirection: 'row',
  },
});
export const cardActionsTheme = (theme: Theme): Partial<Record<CardActionsClassKey, CSSProperties>> => ({
  root: {
    justifyContent: 'flex-end',
    borderTop: `1px solid ${theme.palette.divider}`,
    padding: 16,
  },
});

export const cardHeaderTheme: Partial<Record<CardHeaderClassKey, CSSProperties>> = {
  root: {
    padding: 0,
  },
  avatar: {
    alignSelf: 'flex-start',
  },
};
