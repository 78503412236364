import React, { FC } from 'react';
import { Avatar as MuiAvatar, AvatarProps as MuiAvatarProps, IconButton } from '@material-ui/core';
import clsx from 'clsx';

export interface AvatarProps extends MuiAvatarProps {
  size?: 'xs' | 'sm' | 'md' | 'lg';
  disabled?: boolean;
  onClick?: (event: React.MouseEvent) => void;
  ariaLabel?: string;
}

const Avatar: FC<AvatarProps> = ({ className, disabled, onClick, size = 'md', ariaLabel, ...rest }) =>
  onClick || disabled ? (
    <IconButton
      aria-label={`${ariaLabel}-icon-button`}
      onClick={onClick}
      disabled={disabled}
      disableRipple
      className={'avatar-button'}
    >
      <MuiAvatar className={clsx(size, className)} {...rest} imgProps={{ 'aria-label': `${ariaLabel}-image` }} />
    </IconButton>
  ) : (
    <MuiAvatar className={clsx(size, className)} {...rest} imgProps={{ 'aria-label': `${ariaLabel}-image` }} />
  );

export default Avatar;
