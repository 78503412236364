import React from 'react';
import { TableContainer as MuiTableContainer, TableContainerProps as MuiTableContainerProps } from '@material-ui/core';
import PropTypes from 'prop-types';

import { InferPropTypes, NonNullFields } from '../types';

const TableContainerPropTypes = {
  dataTestId: PropTypes.string,
};

export type TableContainerProps = NonNullFields<InferPropTypes<typeof TableContainerPropTypes>> &
  MuiTableContainerProps;

const TableContainer: React.FC<TableContainerProps> = ({ children, dataTestId, ...rest }) => (
  <MuiTableContainer data-testid={dataTestId} {...rest}>
    {children}
  </MuiTableContainer>
);

TableContainer.propTypes = TableContainerPropTypes;

export default TableContainer;
