import React, { useEffect, useState } from 'react';
import { DialogContent, DialogContentProps, Theme } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/styles';
import clsx from 'clsx';

import { monochromeLight } from '../../abstracts/colours';

export const useStyles = makeStyles<Theme>(() =>
  createStyles({
    scrollable: {
      borderTop: `1px solid ${monochromeLight}`,
      paddingTop: 16,
    },
    content: {
      borderBottom: `1px solid ${monochromeLight}`,
    },
  }),
);

const ModalContent: React.FC<DialogContentProps> = ({ children, ...rest }) => {
  const classes = useStyles();
  const [scrollable, setScrollable] = useState<boolean>(false);

  useEffect(() => {
    if (
      (global as any).document.getElementById('modal-content')?.offsetHeight <
      (global as any).document.getElementById('modal-content')?.scrollHeight
    ) {
      setScrollable(true);
    } else {
      setScrollable(false);
    }
  }, [scrollable]);

  return (
    <DialogContent id="modal-content" className={clsx(classes.content, scrollable && classes.scrollable)} {...rest}>
      {children}
    </DialogContent>
  );
};

export default ModalContent;
