import React from 'react';

const Excel: React.FC<{ className?: string }> = ({ className }) => (
  <svg
    width="36px"
    height="36px"
    viewBox="0 0 36 36"
    version="1.1"
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.1716 3C20.702 3 21.2107 3.21071 21.5858 3.58579L29.4142 11.4142C29.7893 11.7893 30 12.298 30 12.8284V30C30 31.65 28.65 33 27 33H8.985C7.335 33 6 31.65 6 30V6C6 4.35 7.35 3 9 3H20.1716ZM21 4.75V10.5C21 11.325 21.675 12 22.5 12H28.25L21 4.75Z"
    />
    <path
      d="M13.6369 26L16.5329 20.64L13.8129 15.568H16.4369L17.3489 17.536C17.4663 17.7813 17.5836 18.0427 17.7009 18.32C17.8183 18.5867 17.9516 18.8853 18.1009 19.216H18.1649C18.2823 18.8853 18.3943 18.5867 18.5009 18.32C18.6183 18.0427 18.7303 17.7813 18.8369 17.536L19.6689 15.568H22.1809L19.4769 20.752L22.3569 26H19.7329L18.6929 23.872C18.5649 23.5947 18.4369 23.3227 18.3089 23.056C18.1916 22.7787 18.0583 22.48 17.9089 22.16H17.8449C17.7276 22.48 17.6103 22.7787 17.4929 23.056C17.3756 23.3227 17.2583 23.5947 17.1409 23.872L16.1649 26H13.6369Z"
      fill="white"
    />
  </svg>
);

export default Excel;
