import { IconButtonClassKey, ButtonClassKey, ButtonTypeMap, IconButtonTypeMap } from '@material-ui/core';
import { OverrideProps } from '@material-ui/core/OverridableComponent';
import { CSSProperties } from '@material-ui/core/styles/withStyles';
import { getDarkest, getSuperLightest } from '../../abstracts/colours';

import { Theme } from '../Theme/theme';

export const buttonTheme = (theme: Theme): Partial<Record<ButtonClassKey, CSSProperties>> => ({
  root: {
    borderRadius: '4px',
    fontSize: '16px',
    minWidth: '64px',
    boxShadow: 'none',
  },
  sizeLarge: {
    height: '44px',
    padding: '12px 16px',
    fontSize: '16px',
  },
  sizeSmall: {
    height: '36px',
    padding: '8px 16px',
    fontSize: '16px',
  },
  iconSizeSmall: {
    '& > *:first-child': {
      fontSize: 20,
    },
  },
  iconSizeLarge: {
    '& > *:first-child': {
      fontSize: 20,
    },
  },
  contained: {
    boxShadow: 'none',
    '&:hover': {
      boxShadow: 'none',
    },
    '&:active': {
      boxShadow: 'none',
    },
    '&:focus-visible': {
      boxShadow: 'none',
      outline: '5px auto -webkit-focus-ring-color',
    },
    '&:disabled': {
      opacity: 0.3,
    },
    '&.destructive': {
      backgroundColor: theme.palette.error.main,
      color: theme.palette.error.contrastText,
      '&:hover, &:focus-visible': {
        backgroundColor: theme.palette.error.dark,
      },
      '&:active': {
        backgroundColor: getDarkest(theme.palette.error),
      },
      '&:disabled': {
        backgroundColor: theme.palette.error.main,
      },
    },
  },
  outlined: {
    backgroundColor: 'transparent',
    '&:hover': {
      boxShadow: 'none',
    },
    '&:active': {
      boxShadow: 'none',
    },
    '&:focus-visible': {
      boxShadow: 'none',
      outline: '5px auto -webkit-focus-ring-color',
    },
    '&:disabled': {
      opacity: 0.3,
    },
    '&.destructive': {
      borderColor: theme.palette.error.main,
      color: theme.palette.error.main,
      '&:hover, &:focus-visible': {
        backgroundColor: getSuperLightest(theme.palette.error),
        color: theme.palette.error.dark,
        borderColor: theme.palette.error.dark,
      },
      '&:disabled': {
        color: theme.palette.error.main,
        borderColor: theme.palette.error.main,
      },
      '&:active': {
        borderColor: getDarkest(theme.palette.error),
        color: getDarkest(theme.palette.error),
      },
    },
  },
  text: {
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
    },
    '&:active': {
      backgroundColor: theme.palette.action.active,
    },
    '&:focus-visible': {
      boxShadow: 'none',
      outline: '5px auto -webkit-focus-ring-color',
    },
    '&:disabled': {
      opacity: 0.3,
    },
    '&.destructive': {
      color: theme.palette.error.main,
      '&:hover, &:focus-visible': {
        color: theme.palette.error.dark,
      },
      '&:active': {
        color: getDarkest(theme.palette.error),
      },
    },
  },

  textPrimary: {
    color: theme.palette.primary.main,
    '&:hover, &:focus-visible': {
      color: theme.palette.primary.dark,
    },
    '&:active': {
      color: getDarkest(theme.palette.primary),
      backgroundColor: theme.palette.action.active,
    },
  },
  textSecondary: {
    color: theme.palette.secondary.main,
    '&:hover, &:focus-visible': {
      color: theme.palette.secondary.dark,
    },
    '&:active': {
      color: getDarkest(theme.palette.secondary),
      backgroundColor: theme.palette.action.active,
    },
  },
  containedPrimary: {
    backgroundColor: theme.palette.primary.main,
    '&:hover, &:focus-visible': {
      backgroundColor: theme.palette.primary.dark,
    },
    '&:active': {
      backgroundColor: getDarkest(theme.palette.primary),
    },
    '&:disabled': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
  },
  containedSecondary: {
    backgroundColor: theme.palette.secondary.main,
    '&:hover, &:focus-visible': {
      backgroundColor: theme.palette.secondary.dark,
    },
    '&:active': {
      backgroundColor: getDarkest(theme.palette.secondary),
    },
    '&:disabled': {
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.secondary.contrastText,
    },
  },
  outlinedPrimary: {
    borderColor: theme.palette.primary.main,
    color: theme.palette.primary.main,
    '&:hover, &:focus-visible': {
      backgroundColor: getSuperLightest(theme.palette.primary),
      color: theme.palette.primary.dark,
      borderColor: theme.palette.primary.dark,
    },
    '&:disabled': {
      color: theme.palette.primary.main,
      borderColor: theme.palette.primary.main,
    },
    '&:active': {
      borderColor: getDarkest(theme.palette.primary),
      color: getDarkest(theme.palette.primary),
    },
  },
  outlinedSecondary: {
    borderColor: theme.palette.secondary.main,
    color: theme.palette.secondary.main,
    '&:hover, &:focus-visible': {
      backgroundColor: getSuperLightest(theme.palette.secondary),
      color: theme.palette.secondary.dark,
      borderColor: theme.palette.secondary.dark,
    },
    '&:disabled': {
      color: theme.palette.secondary.main,
      borderColor: theme.palette.secondary.main,
    },
    '&:active': {
      borderColor: getDarkest(theme.palette.secondary),
      color: getDarkest(theme.palette.secondary),
    },
  },
});

export const buttonDefaultProps: Partial<OverrideProps<ButtonTypeMap<{}, 'button'>, 'button'>> = {
  variant: 'contained',
  color: 'primary',
  type: 'button',
  size: 'large',
  disableTouchRipple: true,
  disableRipple: true,
};

export const iconButtonTheme = (theme: Theme): Partial<Record<IconButtonClassKey, CSSProperties>> => ({
  label: {
    fontSize: 20,
  },
  root: {
    color: theme.palette.text.primary,
    '&.hamburger': {
      marginLeft: -20,
      marginRight: 4,
    },
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
    },
    '&:active': {
      backgroundColor: theme.palette.action.active,
    },
    '&:disabled': {
      color: theme.palette.text.disabled,
      opacity: 0.3,
    },
    '&.Mui-focusVisible': {
      outline: '5px auto -webkit-focus-ring-color',
      backgroundColor: 'transparent',
      boxShadow: 'none',
    },
    '&.avatar-button': {
      padding: 0,
      '&:active': {
        backgroundColor: 'transparent',
        '&:before': {
          content: '""',
          borderRadius: '50%',
          backgroundColor: `${theme.palette.elevation}33`,
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          zIndex: 1,
        },
      },
      '&:hover, &:focus-visible': {
        backgroundColor: 'transparent',
        '&:before': {
          content: '""',
          borderRadius: '50%',
          backgroundColor: `${theme.palette.elevation}1A`,
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          zIndex: 1,
        },
      },

      '&:disabled': {
        filter: 'opacity(0.3)',
      },
    },
  },
});

export const iconButtonDefaultProps: Partial<OverrideProps<IconButtonTypeMap<{}, 'button'>, 'button'>> = {
  disableTouchRipple: true,
  disableRipple: true,
};
