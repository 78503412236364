import { SwitchClassKey } from '@material-ui/core/Switch';
import { CSSProperties } from '@material-ui/core/styles/withStyles';
import { Theme } from '../../Theme/theme';
import { getDarkest } from '../../../abstracts/colours';

export const switchTheme = (theme: Theme): Partial<Record<SwitchClassKey, CSSProperties>> => ({
  root: {
    width: 44,
    height: 24,
    padding: 0,
    display: 'flex',
  },
  switchBase: {
    margin: 2,
    padding: 0,
    '&:active': {
      '& + $track': {
        backgroundColor: theme.palette.text.primary,
        borderColor: theme.palette.text.primary,
      },
    },
    '&:hover': {
      '& + $track': {
        backgroundColor: theme.palette.text.secondary,
        borderColor: theme.palette.text.secondary,
      },
    },
    '&$checked': {
      transform: 'translateX(20px)',
      '& + $track': {
        backgroundColor: theme.palette.primary.main,
        borderColor: theme.palette.primary.main,
        opacity: 1,
      },
      '&:active': {
        '& + $track': {
          backgroundColor: getDarkest(theme.palette.primary),
          borderColor: getDarkest(theme.palette.primary),
        },
      },
      '&:hover': {
        '& + $track': {
          backgroundColor: theme.palette.primary.dark,
          borderColor: theme.palette.primary.dark,
        },
      },

      '&$disabled + $track': {
        opacity: 1,
        backgroundColor: theme.palette.primary.main,
        borderColor: theme.palette.primary.main,
      },
    },
    '&$disabled + $track': {
      opacity: 1,
      backgroundColor: theme.palette.neutral.mid,
      borderColor: theme.palette.neutral.mid,
    },
  },
  colorPrimary: {
    '&$checked': {
      '& + $track': {
        backgroundColor: theme.palette.primary.main,
        borderColor: theme.palette.primary.main,
        opacity: 1,
      },
    },
  },
  thumb: {
    color: theme.palette.background.paper,
  },
  track: {
    border: `1px solid ${theme.palette.neutral.mid}`,
    borderRadius: 13,
    opacity: 1,
    backgroundColor: theme.palette.neutral.mid,
  },
});
