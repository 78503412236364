import React from 'react';
import { TableBody as MuiTableBody, TableBodyProps as MuiTableBodyProps } from '@material-ui/core';
import PropTypes from 'prop-types';

import { InferPropTypes, NonNullFields } from '../types';

const tableBodyPropTypes = {
  dataTestId: PropTypes.string,
};

export type TableBodyProps = NonNullFields<InferPropTypes<typeof tableBodyPropTypes>> & MuiTableBodyProps;

const TableBody: React.FC<TableBodyProps> = ({ children, dataTestId, ...rest }) => (
  <MuiTableBody data-testid={dataTestId} {...rest}>
    {children}
  </MuiTableBody>
);

TableBody.propTypes = tableBodyPropTypes;

export default TableBody;
