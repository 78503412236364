import React from 'react';

const TxtFile: React.FC<{ className?: string }> = ({ className }) => (
  <svg
    width="36px"
    height="36px"
    viewBox="0 0 36 36"
    version="1.1"
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.1716 3C20.702 3 21.2107 3.21071 21.5858 3.58579L29.4142 11.4142C29.7893 11.7893 30 12.298 30 12.8284V30C30 31.65 28.65 33 27 33H8.985C7.335 33 6 31.65 6 30V6C6 4.35 7.35 3 9 3H20.1716ZM21 4.75V10.5C21 11.325 21.675 12 22.5 12H28.25L21 4.75Z"
    />
    <path
      d="M11.1101 24V17.664H8.96211V16.176H15.0341V17.664H12.8861V24H11.1101ZM14.7277 24L16.8997 19.98L14.8597 16.176H16.8277L17.5117 17.652C17.5997 17.836 17.6877 18.032 17.7757 18.24C17.8637 18.44 17.9637 18.664 18.0757 18.912H18.1237C18.2117 18.664 18.2957 18.44 18.3757 18.24C18.4637 18.032 18.5477 17.836 18.6277 17.652L19.2517 16.176H21.1357L19.1077 20.064L21.2677 24H19.2997L18.5197 22.404C18.4237 22.196 18.3277 21.992 18.2317 21.792C18.1437 21.584 18.0437 21.36 17.9317 21.12H17.8837C17.7957 21.36 17.7077 21.584 17.6197 21.792C17.5317 21.992 17.4437 22.196 17.3557 22.404L16.6237 24H14.7277ZM23.1179 24V17.664H20.9699V16.176H27.0419V17.664H24.8939V24H23.1179Z"
      fill="white"
    />
  </svg>
);

export default TxtFile;
