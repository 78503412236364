import React from 'react';

const Spreadsheet: React.FC<{ className?: string }> = ({ className }) => (
  <svg
    width="36px"
    height="36px"
    viewBox="0 0 36 36"
    version="1.1"
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.5858 3.58579C21.2107 3.21071 20.702 3 20.1716 3H9C7.35 3 6 4.35 6 6V30C6 31.65 7.335 33 8.985 33H27C28.65 33 30 31.65 30 30V12.8284C30 12.298 29.7893 11.7893 29.4142 11.4142L21.5858 3.58579ZM21 10.5V4.75L28.25 12H22.5C21.675 12 21 11.325 21 10.5ZM10.0052 15.8978C10.0017 15.9314 10 15.9655 10 16V20V24V28C10 28.5523 10.4477 29 11 29H16H25C25.5523 29 26 28.5523 26 28V24V20V16C26 15.4477 25.5523 15 25 15H16H11C10.4822 15 10.0564 15.3935 10.0052 15.8978ZM15 27H12V25H15V27ZM24 27H17V25H24V27ZM24 23V21H17V23H24ZM15 23V21H12V23H15ZM15 19H12V17H15V19ZM24 19H17V17H24V19Z"
    />
  </svg>
);

export default Spreadsheet;
