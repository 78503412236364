import { Theme, SliderClassKey } from '@material-ui/core';
import { CSSProperties } from '@material-ui/core/styles/withStyles';

import { getDarkest, getLightest, monochromeLight } from '../../abstracts/colours';

export const sliderTheme = (theme: Theme): Partial<Record<SliderClassKey, CSSProperties>> => ({
  root: {
    width: 304,
    '&.Mui-disabled': {
      color: theme.palette.primary.main,
      opacity: 0.3,
    },
  },
  rail: {
    height: 4,
    borderRadius: 2,
    color: monochromeLight,
    opacity: 1,
  },
  track: {
    height: 4,
    borderRadius: 2,
  },
  mark: {
    height: 4,
    width: 4,
    backgroundColor: theme.palette.text.secondary,
    borderRadius: '50%',
    '&:nth-child(14)': {
      left: '99% !important',
    },
  },
  markActive: {
    backgroundColor: getLightest(theme.palette.primary),
    opacity: 1,
  },
  thumb: {
    height: 18,
    width: 18,
    marginTop: -7.5,
    marginLeft: -7.5,
    '&:hover': {
      boxShadow: 'none',
      color: theme.palette.primary.dark,
    },
    '&:active': {
      boxShadow: 'none',
      color: getDarkest(theme.palette.primary),
    },
    '&:focus-visible': {
      boxShadow: 'none',
    },
    '&.Mui-disabled': {
      height: 18,
      width: 18,
      marginTop: -7.5,
      marginLeft: -7.5,
    },
  },
  valueLabel: {
    left: -7,
    color: theme.palette.primary.main,
    top: -21,
    '& > span': {
      transform: 'none',
      borderRadius: 2,
      height: 24,
      width: 32,
      '& > span': {
        transform: 'none',
      },
    },
    '&::after': {
      content: "''",
      position: 'absolute',
      left: 12,
      top: 24,
      width: 0,
      height: 0,
      borderLeft: '4px solid transparent',
      borderRight: '4px solid transparent',
      borderTop: `4px solid ${theme.palette.primary.main}`,
    },
  },
});
