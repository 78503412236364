import React, { FC, useCallback, isValidElement, cloneElement } from 'react';
import { Step as MuiStep, StepProps as MuiStepProps } from '@material-ui/core';
import clsx from 'clsx';

const Step: FC<MuiStepProps> = ({ onClick, className, children, disabled, ...rest }) => {
  const handleClick = useCallback(
    (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      onClick && !disabled && onClick(e);
    },
    [onClick, disabled],
  );

  const childrenWithProps = React.Children.map(children, (child) => {
    if (isValidElement(child)) {
      return cloneElement(child, { clickable: onClick && !disabled });
    }
    return child;
  });

  return (
    <MuiStep className={clsx(className, onClick && !disabled && 'clickable')} onClick={handleClick} {...rest}>
      {childrenWithProps}
    </MuiStep>
  );
};

export default Step;
