import React from 'react';

const Comment: React.FC<{ className?: string }> = ({ className }) => (
  <svg
    width="36px"
    height="36px"
    viewBox="0 0 36 36"
    version="1.1"
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3 24V6C3 4.35 4.35 3 6 3H29.985C31.635 3 32.985 4.35 32.985 6L33 29.37C33 30.705 31.38 31.38 30.435 30.435L27 27H6C4.35 27 3 25.65 3 24ZM25.5 16.5C26.325 16.5 27 15.825 27 15C27 14.175 26.325 13.5 25.5 13.5H10.5C9.675 13.5 9 14.175 9 15C9 15.825 9.675 16.5 10.5 16.5H25.5ZM27 19.5C27 20.325 26.325 21 25.5 21H10.5C9.675 21 9 20.325 9 19.5C9 18.675 9.675 18 10.5 18H25.5C26.325 18 27 18.675 27 19.5ZM10.5 9C9.675 9 9 9.675 9 10.5C9 11.325 9.675 12 10.5 12H25.5C26.325 12 27 11.325 27 10.5C27 9.675 26.325 9 25.5 9H10.5Z"
    />
  </svg>
);

export default Comment;
