import React from 'react';
import { Typography } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/styles';
import { SentimentDissatisfiedRounded } from '@material-ui/icons';

import { Theme } from '../Theme/theme';
import { Button, Link } from '../../';

export interface Error404Props {
  supportUrl?: string;
  homepageUrl?: string;
}

const useStyles = makeStyles<Theme>((theme) =>
  createStyles({
    errorPage: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
    },
    icon: {
      width: 68,
      height: 68,
      fill: theme.palette.neutral.mid,
      display: 'block',
      margin: 'auto',
      marginBottom: 9,
    },
    heading: {
      marginBottom: 21,
      textAlign: 'center',
    },
    paragraph: {
      marginBottom: 24,
      maxWidth: 460,
      textAlign: 'center',
      margin: 'auto',
      paddingLeft: 31,
      paddingRight: 31,
    },
    button: {
      display: 'block',
      margin: 'auto',
      textDecoration: 'none',
      textTransform: 'none',
    },
  }),
);

const Error404: React.FC<Error404Props> = ({ supportUrl, homepageUrl }) => {
  const { errorPage, heading, paragraph, icon, button } = useStyles();
  return (
    <div className={errorPage}>
      <SentimentDissatisfiedRounded className={icon} />
      <Typography variant="h2" className={heading}>
        Sorry, page not found
      </Typography>
      <Typography className={paragraph}>Please check that the web address is correct.</Typography>
      <Typography className={paragraph}>
        If you’ve checked that the web address is correct, or a link brought you to this page,{' '}
        <Link href={supportUrl}>contact customer services</Link>.
      </Typography>
      <Link href={homepageUrl} underline="none">
        <Button className={button}>Go to homepage</Button>
      </Link>
    </div>
  );
};

export default Error404;
