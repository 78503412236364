import React, { ReactNode, useMemo } from 'react';
import { Tab, TabProps as MuiTabProps, Popper } from '@material-ui/core';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { InferPropTypes, NonNullFields } from '../../types';

const navigationTabPropTypes = {
  label: PropTypes.string.isRequired,
  children: PropTypes.node,
  tabIndex: PropTypes.number,
  currentNavItem: PropTypes.shape({
    currentTab: PropTypes.number.isRequired,
    currentDropdownItem: PropTypes.number,
  }),
  anchorEl: PropTypes.instanceOf(HTMLElement),
  handleChange: PropTypes.func,
  handleMouseEnter: PropTypes.func,
  handleMouseLeave: PropTypes.func,
  dataTestId: PropTypes.string,
};

const defaultProps = {
  label: '',
  currentNavItem: {
    currentTab: 0,
  },
};

export type NavigationTabProps = NonNullFields<InferPropTypes<typeof navigationTabPropTypes>> & MuiTabProps;

const NavigationTabDefault: React.FC<NavigationTabProps> = ({ label, tabIndex, dataTestId, className, ...rest }) => (
  <Tab
    className={clsx('navigation-tab', className)}
    data-testid={`${dataTestId}-tab-${tabIndex}`}
    label={label}
    {...rest}
  />
);

const NavigationTabWithDropdown: React.FC<NavigationTabProps> = ({
  label,
  children,
  tabIndex,
  currentNavItem,
  anchorEl,
  handleChange,
  handleMouseEnter,
  handleMouseLeave,
  dataTestId,
  ...rest
}) => {
  const isHovered: boolean = useMemo(() => anchorEl?.tabIndex === tabIndex, [anchorEl, tabIndex]);
  return (
    <>
      <Tab
        data-testid={`${dataTestId}-tab-${tabIndex}`}
        className={clsx('navigation-tab', 'navigation-tab-with-dropdown', isHovered && 'navigation-tab-dropdown-open')}
        label={label}
        tabIndex={tabIndex}
        icon={isHovered ? <ExpandLess /> : <ExpandMore />}
        onMouseEnter={handleMouseEnter}
        {...rest}
      />
      <Popper open={isHovered} anchorEl={anchorEl} placement="bottom-start">
        {React.Children.map<ReactNode, ReactNode>(children, (child, index) => {
          if (React.isValidElement(child)) {
            return React.cloneElement(child, {
              index,
              currentNavItem,
              tabIndex,
              handleChange,
              handleMouseLeave,
              dataTestId,
            });
          }
        })}
      </Popper>
    </>
  );
};

const NavigationItemHorizontal: React.FC<NavigationTabProps> = ({
  children,
  currentNavItem,
  anchorEl,
  handleChange,
  handleMouseEnter,
  handleMouseLeave,
  ...rest
}) =>
  children ? (
    <NavigationTabWithDropdown
      currentNavItem={currentNavItem}
      anchorEl={anchorEl}
      handleChange={handleChange}
      handleMouseEnter={handleMouseEnter}
      handleMouseLeave={handleMouseLeave}
      {...rest}
    >
      {children}
    </NavigationTabWithDropdown>
  ) : (
    <NavigationTabDefault {...rest} />
  );

NavigationItemHorizontal.propTypes = navigationTabPropTypes;
NavigationItemHorizontal.defaultProps = defaultProps;

export default React.memo(NavigationItemHorizontal);
